define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["ca-es", {
    "addressInput": {
      "loading": "Carregant…",
      "noResults": "No hi ha cap resultat."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Bellesa i higiene personal",
      "charitiesEducationAndMembership": "Organitzacions sense ànim de lucre, educació i afiliacions",
      "foodAndDrink": "Menjar i begudes",
      "healthCareAndFitness": "Salut i fitnes",
      "homeAndRepair": "Llar i reparació",
      "leisureAndEntertainment": "Oci i entreteniment",
      "other": "Altres",
      "professionalServices": "Serveis professionals",
      "retail": "Comerços\n",
      "transportation": "Transport"
    },
    "businessCategoryFilters": {
      "hideFilters": "Amaga els filtres",
      "showFilters": "Mostra els filtres"
    },
    "checkBalance": {
      "checkboxLabel": "La meva targeta regal no té PIN",
      "description": "Introdueix el número de la teva targeta regal per comprovar el saldo.",
      "errors": {
        "notFound": "No s’ha pogut trobar la targeta regal.",
        "rateLimit": "S’han realitzat massa intents. Torna-ho a provar més tard."
      },
      "label": "Número de la targeta regal",
      "pinLabel": "PIN",
      "submit": "Comprova el saldo",
      "tooltip": "Trobaràs el PIN al revers de la targeta regal, al costat del número de la targeta.",
      "tooltipPinRequired": "Trobaràs el PIN al revers de la targeta regal, al costat del número de la targeta. Si la targeta regal no té PIN, posa’t en contacte amb el comerç per comprovar el saldo."
    },
    "clipboard": {
      "copyLink": "Copia l’enllaç",
      "linkCopied": "Enllaç copiat!"
    },
    "common": {
      "edit": "Edita",
      "next": "Següent",
      "unknownError": "S’ha produït un error desconegut. Torna-ho a provar."
    },
    "confirmLink": {
      "heroSecondary": "I digues al caixer el número de telèfon.",
      "heroText": "Passa per la caixa.",
      "seeBalanceButton": "Consulta el saldo de la teva targeta regal"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "La teva comanda de {quantity} x {amount} targetes regal s’enviarà el {formattedDate}.",
          "instantDescription": "La teva comanda de {quantity} x {amount} targetes regal electròniques s’ha enviat."
        },
        "singleRecipient": {
          "futureDescription": "La teva comanda de {quantity} x {amount} targetes regal electròniques s’enviaran a {recipientEmail} el {formattedDate}.",
          "instantDescription": "La teva comanda de {quantity} x {amount} targetes regal electròniques s’ha enviat a {recipientEmail}."
        }
      },
      "continueShopping": "Continua comprant",
      "giftFutureDescription": "La targeta regal electrònica ({amount}) s’enviarà a {recipientName} ({recipientContact}) el dia {formattedDate}.",
      "giftInstantDescription": "S’ha enviat la targeta regal electrònica ({amount}) a {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Torna al regal en grup"
      },
      "groupOrder": {
        "confirmationMessageEnd": "a la targeta regal. S’enviarà una confirmació per correu electrònic a {emailAddress}",
        "confirmationMessageStart": "Has afegit",
        "groupGiftStarted": "El regal en grup ha començat!",
        "viewGroupGift": "Mostra el regal en grup"
      },
      "orderComplete": "Comanda completada",
      "orderFutureDescription": "El val ({amount}) s’enviarà a {recipientName} ({recipientContact}) el dia {formattedDate}.",
      "orderInstantDescription": "S’ha enviat el val ({amount}) a {recipientName} ({recipientContact}).",
      "orderUpdated": "Comanda actualitzada",
      "reloadComplete": "Recàrrega completa",
      "sendAnother": "Envia una altra targeta regal electrònica",
      "sendAnotherGift": "Envia un altre regal"
    },
    "date": {
      "formatPlaceholder": "DD/MM/AAAA",
      "nextMonth": "Mes següent",
      "previousMonth": "Mes anterior"
    },
    "discount": {
      "codeInvalid": "El codi de descompte no és vàlid.",
      "discountCode": "Codi de descompte",
      "discountPricing": "Preus amb descompte: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filtra per"
      },
      "footer": {
        "aProjectBy": "Un projecte de"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Dona suport als negocis locals. Ajuda a dinamitzar els teus comerços Square preferits comprant targetes regal digitals.",
          "voucher": "Dona suport als negocis locals. Ajuda a dinamitzar els teus comerços Square preferits comprant cupons per a regalar o de consum propi."
        },
        "giftCardAsteriskAltText": "Targeta regal amb un disseny d’asterisc colorit "
      },
      "hero": {
        "subtitle": "Compra una targeta regal electrònica ara i utilitza-la més endavant per ajudar el comerç local que tant t’agrada.",
        "title": "Ajuda els teus negocis preferits"
      },
      "locationSelector": {
        "currentLocation": "Punt de venda actual",
        "label": "Troba venedors Square a prop teu",
        "placeholder": "Introdueix una adreça o ciutat",
        "separator": "o",
        "useCurrentLocation": "Utilitza la ubicació actual"
      },
      "merchantCard": {
        "buyButton": "Compra una targeta regal"
      },
      "results": {
        "noResults": "No hi ha cap resultat"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Gràcies pel teu suport. Ajuda’ns a fer córrer la veu."
        },
        "notFromDirectory": {
          "description": "A Give & Get Local trobaràs negocis que ofereixen targetes regal electròniques i serveis de recollida a l’establiment i entrega a domicili, entre altres. ",
          "title": "Ajuda els teus negocis preferits"
        },
        "shareOnFacebook": "Comparteix a Facebook",
        "shareOnTwitter": "Comparteix a Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Eina de selecció del tema de la targeta regal",
      "carouselRole": "carrusel",
      "customImageFallback": "Imatge personalitzada del venedor",
      "nextButtonDescription": "Tema següent",
      "previousButtonDescription": "Tema anterior",
      "slideRole": "diapositiva"
    },
    "errors": {
      "invalidEmail": "Aquesta adreça electrònica no sembla vàlida.",
      "invalidPhone": "Aquest número de telèfon no sembla vàlid.",
      "requiredField": "Aquest camp és obligatori."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Condicions de Give & Get Local",
      "privacyPolicy": "Política de privacitat",
      "termsOfService": "Condicions del servei",
      "text": "Amb la tecnologia de les<a href=\"{localizedGiftcardsLink}\" target=\"_blank\">\nTargetes regal Square</a>"
    },
    "form": {
      "continue": "Continua",
      "failedLabel": "Error",
      "optionalLabel": "Opcional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Afegir un missatge personal",
      "amount": "Import",
      "contributionAmount": "Import de l’aportació",
      "contributorEmailAddresses": "Adreces electròniques dels col·laboradors ({max} persones com a màxim, separades per comes)",
      "custom": "Personalitzat",
      "customAmount": "Import personalitzat",
      "customAmountBoundsValidation": "L’import de la targeta de regal electrònica ha de ser d’entre {min} i {max}.",
      "customAmountPlaceholder": "{min} a {max}",
      "customAmountRequiredValidation": "Introdueix un import personalitzat per a la targeta regal electrònica o selecciona una de les opcions.",
      "delivery": "Entrega a domicili",
      "deliveryDate": "Data d’entrega",
      "deliveryDateValidation": "Introdueix una data en els 60 dies següents.",
      "deliveryDetailsHeader": "Detalls de l’entrega a domicili",
      "deliveryTime": "Hora d’entrega",
      "eGiftCardAmount": "Import de la targeta regal electrònica",
      "emailAddress": "Adreça electrònica",
      "emailAddresses": "Adreces electròniques (separades per comes)",
      "emailAddressesRequiredValidation": "Cal posar adreces electròniques",
      "giftTypeHeader": "Quin tipus de regal és?",
      "groupDesc": "Agrupa els diners de diversos col·laboradors per a un destinatari",
      "groupHeader": "Targeta regal de grup",
      "individualDesc": "Envia una targeta regal a un destinatari",
      "individualDescWithBulk": "Envia una targeta regal a un destinatari o fes una compra en bloc",
      "individualHeader": "Per a una persona",
      "invalidEmailAddressValidation": "L’adreça electrònica no és vàlida {email}",
      "invitationMessage": "Missatge d’invitació",
      "invitationMessageTooLong": "La llargada màxima per al missatge d’invitació és de 400 caràcters. ",
      "inviteContributorsHeader": "Convida col·laboradors (opcional)",
      "inviteContributorsSubtitle": "Envia un correu electrònic per convidar altres persones a col·laborar en aquest regal. També rebràs un enllaç per compartir-lo després del procés de pagament.",
      "maxEmailAddressesValidation": "No pots posar més de {max} adreces en una sola comanda",
      "maxQuantityValidation": "La quantitat no pot ser superior a {max}.",
      "minEmailAddressesValidation": "Has d’introduir un mínim de {min} adreces electròniques",
      "multipleDesc": "Envia targetes regal a diverses persones alhora",
      "multipleHeader": "Per a diverses persones",
      "name": "Nom",
      "personalMessage": "Missatge personal",
      "personalMessageOptional": "Missatge personal (opcional)",
      "personalMessageTooLong": "La llargada màxima per al missatge personal és de 200 caràcters. ",
      "quantity": "Quantitat",
      "quantityValidation": "La quantitat ha de ser entre {min} i {max}",
      "recipientEmail": "Adreça electrònica del destinatari",
      "recipientName": "Nom del destinatari",
      "recipientNamePlaceholder": "Nom del destinatari",
      "recipientQuantityPlaceholder": "1 per destinatari",
      "sendAsAGift": "Envia aquest val de regal",
      "sendInstantly": "Envia a l’instant",
      "sendOnAFutureDate": "Envia en una data futura",
      "sendToMyself": "Envia’m aquesta targeta a mi",
      "sendToSelf": "M’ho vull enviar a mi",
      "suggestedContributionAmount": "Import suggerit a aportar",
      "timeZone": "Zona horària",
      "yourDetailsHeader": "Les teves dades",
      "yourEmail": "La teva adreça electrònica",
      "yourEmailAddress": "La teva adreça electrònica",
      "yourName": "El teu nom",
      "yourNamePlaceholder": "El teu nom"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Envia un regal instantàniament pel import que escullis",
        "voucher": "Obtén un val per a tu o regala’n un"
      },
      "heroText": "Fes el regal perfecte"
    },
    "giftMessage": {
      "custom": "&ldquo;{customMessage}&rdquo;",
      "from": "De",
      "messageFrom": "Missatge de {senderEmail}",
      "nameNotAvailable": "Nom no disponible",
      "to": "Per a"
    },
    "giftSummarySidebar": {
      "customMessage": "&ldquo;{customMessage}&rdquo;",
      "deliveryFailed": "Hi ha hagut un error en l’enviament a {recipient}",
      "discountsApplied": "S’ha aplicat el descompte",
      "emailAddressUnavailable": "Adreça electrònica no disponible",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} més",
      "newBalance": "El teu nou saldo serà de {newBalance}",
      "orderDetails": "Informació de la comanda",
      "payAmount": "L’import que pagues",
      "promotionApplied": "S’ha aplicat la promoció",
      "valueDoesNotExpire": "El valor no caduca.",
      "viewAllRecipients": "Mostrar tots els destinataris"
    },
    "group": {
      "giftDetailsHeader": "Informació del regal",
      "loadFunds": "Carrega fons",
      "organizedBy": "Organitzat per",
      "suggestedContributionDesc": "La persona organitzadora ha suggerit aportar {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Es compartiran el nom, l’adreça electrònica i el missatge amb el destinatari.",
      "header": "Col·laboradors",
      "viewAll": "Mostra-ho tot"
    },
    "header": {
      "checkBalanceLink": "Necessites comprovar el saldo de la teva targeta regal?",
      "logoAltText": "Logotip de {merchantName}",
      "orderPageLink": "Demana una targeta regal electrònica"
    },
    "headerV2": {
      "checkBalanceLink": "Comprova el saldo",
      "logoAltText": "Logotip de {merchantName}",
      "orderPageLink": "Compra una targeta regal",
      "reloadPageLink": "Torna a carregar la targeta"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Esborra",
        "deduct": "S’han descomptat els fons",
        "load": "S’han carregat els fons",
        "redeem": "Compra",
        "refund": "Reembossament",
        "transferBalanceFrom": "Transfereix a {giftCardDisplay}",
        "transferBalanceTo": "Transfereix des de {giftCardDisplay}",
        "unknown": "Desconegut"
      }
    },
    "link": {
      "heroSecondary": "Configura la targeta amb un número de telèfon.",
      "heroText": "Deixa la targeta regal a casa.",
      "noThanks": "No, només faré servir el codi de 16 dígits.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "S’ha produït un error en actualitzar el número de telèfon."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Compra en línia"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Desa",
        "header": "Afegeix l’adreça electrònica",
        "subheader": "Deixa la targeta regal a casa i indica la teva adreça electrònica al personal de caixa."
      },
      "addEditPhoneNumber": {
        "buttonText": "Desa",
        "header": "Afegeix un número de telèfon",
        "subheader": "Deixa la targeta regal a casa i digues al caixer el número de telèfon."
      },
      "recipientEmails": {
        "eGiftRecipients": "Destinataris de targetes regal electròniques"
      }
    },
    "pageNotFound": "La pàgina d’aquesta targeta regal electrònica no existeix o s’ha desactivat.",
    "pageTitle": {
      "checkBalance": "Comprova el saldo | Targetes regal electròniques de {merchantName}",
      "default": "Targetes regal electròniques de {merchantName}",
      "discover": "Ajuda els comerços locals amb les targetes regal electròniques de Square!",
      "group": "Grup | Targetes regal electròniques de {merchantName}",
      "linkPhone": "Vincula el número de telèfon mòbil | Targetes regal electròniques de {merchantName}",
      "order": "Comanda | Targetes regal electròniques de {merchantName}",
      "print": "Imprimeix | Targetes regal electròniques de {merchantName}",
      "reload": "Torna a carregar | Targetes regal electròniques de {merchantName}",
      "resend": "Reenvia | Targetes regal electròniques de {merchantName}"
    },
    "payment": {
      "confirmDetails": "Confirma els detalls de la teva comanda",
      "confirmDetailsWithPayment": "Confirma els detalls de la teva comanda i introdueix la informació de facturació",
      "error": {
        "compliance": {
          "dailyLimit": "La comanda excedeix el límit diari de compliment de la targeta regal."
        },
        "paymentDeclined": "El pagament s’ha denegat. Prova amb una targeta de crèdit diferent."
      },
      "heroText": "Procés de pagament"
    },
    "paymentForm": {
      "buyerBeware": "Amb aquesta compra, confirmo que em faig responsable de qualsevol pèrdua associada a la compra de la targeta regal en el cas que el venedor no pugui complir amb l’entrega dels béns o serveis.",
      "disclaimer": {
        "default": "En comprar una targeta regal electrònica, acceptes <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">la política de privacitat</a> i <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">el consentiment electrònic de Square</a>.",
        "giveAndGetLocal": "En comprar una targeta electrònica, acceptes <a href=\"{localizedTermsUrl}\" target=\"_blank\">les condicions de Give & Get Local</a>, <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">la política de privacitat</a>, i<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">el consentiment electrònic de Square</a>."
      },
      "errors": {
        "unauthorized": "No s’ha pogut autoritzar teva targeta de pagament"
      },
      "payWithCard": "Paga amb targeta"
    },
    "paymentInfoForm": {
      "placeOrder": "Fes la teva comanda"
    },
    "printEgift": {
      "themeAltText": "Tema de la targeta regal electrònica"
    },
    "promotion": {
      "applyPromotionCode": "Aplica",
      "codeInvalid": "El codi promocional no és vàlid.",
      "customHelpText": "* El descompte del {percentage} % s’aplica a valors de {minimumLoad} o més.",
      "discountCodeApplied": "S’ha aplicat el codi de descompte \"{code}\".",
      "discountCodeIgnored": "El descompte actual és millor que “{ignoredCode}”.",
      "fixedDiscount": "{discount} de descompte",
      "fixedDiscountExpires": "{discount} de descompte fins al dia {expirationDate}",
      "fixedDiscountMinLoad": "{discount} de descompte en articles de {minimumLoad} o més",
      "fixedDiscountMinLoadExpires": "{discount} de descompte en articles de {minimumLoad} o més fins al {expirationDate}",
      "payDiscountedPrice": "Paga {price}",
      "percentageDiscount": "{percentage}% de descompte",
      "percentageDiscountExpires": "{percentage}% de descompte fins al {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage}% de descompte (fins a un total de {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage}% de descompte fins al {expirationDate} (fins a un total de {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage}% de descompte als articles {minimumLoad} i menors",
      "percentageDiscountMinLoadExpires": "{percentage}% de descompte als articles {minimumLoad} i abans del {expirationDate}",
      "promotionCode": "Codi de promoció",
      "promotionCodePlaceholder": "ELMEUCODIDEDESCOMPTE",
      "promotionalPricing": "Preus promocionals: {description}",
      "youPayPrice": "Pagues {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Afegeix l’adreça electrònica",
      "addPhoneNumber": "Afegir un altre número de telèfon",
      "editPhoneNumber": "Editar"
    },
    "reload": {
      "balance": "Saldo de la targeta: {balance}",
      "checkBalance": "Comprova el saldo",
      "description": "Afegeix diners a la teva targeta regal.",
      "discountInput": "Afegeix un codi de descompte",
      "promoInput": "Afegeix un codi promocional",
      "subheader": "Import que s’ha d’afegir a la targeta",
      "submit": "Continua"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Torna a carregar els detalls"
    },
    "resend": {
      "heroSecondary": "No s‘ha pogut enviar el regal. Actualitza l‘adreça electrònica del destinatari.",
      "heroText": "Actualitza l’adreça electrònica"
    },
    "routeError": {
      "giftCardNotFound": "Aquesta targeta regal no és vàlida o encara no està activada."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " a la targeta regal. S’enviarà un missatge de confirmació al número {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{L’adreça electrònica indicada no és vàlida} other{Una o diverses de les adreces electròniques indicades no són vàlides}}. Introdueix-ne una altra.",
        "inTotal": {
          "perDay": "No pots enviar més de 4 targetes regal al dia per missatge de text. Pots fer-ho per correu electrònic.",
          "perMonth": "No pots enviar més de 20 targetes regal al mes per missatge de text. Pots fer-ho per correu electrònic."
        },
        "minAmount": "Les targetes regal electròniques han de tenir un valor mínim de 5 $",
        "samePhoneNumber": {
          "perDay": "No pots enviar més de 2 targetes regal al dia al mateix número de telèfon. Pots fer-ho per correu electrònic.",
          "perMonth": "No pots enviar més de 4 targetes regal al mes al mateix número de telèfon. Pots fer-ho per correu electrònic."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Número de telèfon dels col·laboradors ({max} persones com a màxim, separades per comes)",
        "invalidPhoneNumberValidation": "Número de telèfon no vàlid: {phoneNumber}",
        "maxPhoneNumbersValidation": "Pot haver-hi {max} números de telèfon com a màxim en una comanda",
        "minPhoneNumbersValidation": "Has d’introduir {min} números de telèfon com a mínim",
        "phoneNumber": "Número de telèfon",
        "phoneNumbers": "Números de telèfon (separats per comes)",
        "phoneNumbersRequiredValidation": "Cal indicar els números de telèfon",
        "recipientPhoneNumber": "Número de telèfon del destinatari",
        "yourPhoneNumber": "El teu número de telèfon"
      },
      "giftMessage": {
        "messageFrom": "Missatge del número {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "El número de telèfon no està disponible"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Es compartiran el nom, el número de telèfon i el missatge amb el destinatari."
      },
      "legalDisclaimer": "En seleccionar Paga, acceptes que podem enviar un missatge de text únic al número que has indicat amunt per lliurar la targeta regal electrònica. Contacta directament amb {sellerName} per consultar més informació sobre les seves pràctiques en matèria de privacitat. S’hi apliquen les tarifes corresponents als missatges i les dades.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Afegeix un número de telèfon",
          "subheader": "Deixa la targeta regal a casa i digues al caixer el número de telèfon."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "Destinataris de targetes regal electròniques"
        }
      },
      "resend": {
        "buttonText": "Torna a enviar la targeta regal electrònica",
        "heroSecondary": "No s’ha pogut enviar el regal. Actualitza la informació de contacte del destinatari.",
        "heroText": "Modifica la informació de l’enviament"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Envia per número de telèfon"
        }
      },
      "todayAt": "Avui a les {time}",
      "todayInstantly": "Avui (a l’instant)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Número de telèfon del destinatari",
        "confirmationValidation": "Els números de telèfon no coincideixen.",
        "originalContactInfo": "Informació de contacte original",
        "originalPhoneNumber": "Número de telèfon original",
        "phoneNumberUnavailable": "El número de telèfon no està disponible",
        "recipientPhoneNumber": "Número de telèfon del destinatari"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Facilita el teu número de telèfon durant el procés de pagament.",
        "phone": "Número de telèfon"
      }
    },
    "shareGroupGift": {
      "shareLink": "Comparteix l’enllaç",
      "shareWithOthers": "Comparteix l’enllaç amb altres persones perquè puguin afegir-se al regal en grup."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Envia per correu electrònic",
        "sendByText": "Envia per missatge de text"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Enviar-ho a múltiples destinataris",
      "sendToOne": "Enviar-ho a un únic destinatari"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Adreça electrònica del destinatari",
      "confirmationValidation": "Les adreces electròniques no coincideixen.",
      "emailAddressUnavailable": "Adreça electrònica no disponible",
      "originalEmail": "Correu electrònic original",
      "recipientEmailAddress": "Adreça electrònica del destinatari",
      "updateOrder": "Actualitza la comanda"
    },
    "view": {
      "addMoreFunds": "Afegir més diners",
      "transactionHistory": "Historial de transaccions",
      "waysToRedeem": "Maneres de bescanviar"
    },
    "viewPageHeader": {
      "currentBalance": "Saldo actual",
      "greeting": "Hola {recipientName}, el/la {senderName} t’ha enviat una targeta regal electrònica!",
      "groupGreeting": "Un regal per a {recipientName}!",
      "groupViewGreeting": "Hola, {recipientName}, els teus amics t’han enviat una targeta regal electrònica!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Afegeix al perfil de Square",
      "barCode": "Codi de barres",
      "code": "Codi",
      "codeInstructions": "Mostra el codi a la caixa.",
      "email": "Correu electrònic",
      "giftCardCode": "Codi de la targeta regal",
      "instructions": "Escaneja o introdueix el codi per bescanviar la targeta regal.",
      "methodChoiceSeparator": "o",
      "otherHeader": "Altres maneres per bescanviar-la a la botiga.",
      "otherInstructions": "Comunica el teu número de telèfon o adreça electrònica quan facis el pagament.",
      "otherInstructionsOnlyEmail": "Indica la teva adreça electrònica durant el procés de pagament.",
      "phone": "Número de telèfon",
      "phoneActionText": "Canvia el número de telèfon de la targeta",
      "qrCode": "Codi QR",
      "redeemOnline": "Bescanvia en línia",
      "redeemOnlineInstructions": "Introdueix el codi de la targeta regal durant el procés de pagament.",
      "squareProfileDescription": "El perfil de Square t’ajuda a consultar i gestionar totes les targetes regal de diferents negocis de Square"
    }
  }], ["en", {
    "addressInput.loading": "Loading...",
    "addressInput.noResults": "No results.",
    "businessCategory.beautyAndPersonalcare": "Beauty & personal care",
    "businessCategory.charitiesEducationAndMembership": "Charities, education & membership",
    "businessCategory.foodAndDrink": "Food & drink",
    "businessCategory.healthCareAndFitness": "Health care & fitness",
    "businessCategory.homeAndRepair": "Home & repair",
    "businessCategory.leisureAndEntertainment": "Leisure & entertainment",
    "businessCategory.other": "Other",
    "businessCategory.professionalServices": "Professional services",
    "businessCategory.retail": "Retail",
    "businessCategory.transportation": "Transportation",
    "businessCategoryFilters.hideFilters": "Hide filters",
    "businessCategoryFilters.showFilters": "Show filters",
    "checkBalance.checkboxLabel": "My gift card does not have a PIN",
    "checkBalance.description": "Enter your gift card number to check your balance.",
    "checkBalance.errors.notFound": "That gift card could not be found.",
    "checkBalance.errors.rateLimit": "Too many attempts. Please try again in a moment.",
    "checkBalance.label": "Gift Card Number",
    "checkBalance.pinLabel": "PIN",
    "checkBalance.submit": "Check Balance",
    "checkBalance.tooltip": "The PIN is located on the back of the gift card, next to the gift card number.",
    "checkBalance.tooltipPinRequired": "The PIN is located on the back of the gift card, next to the gift card number. If your gift card does not have a PIN, please contact the merchant to check your balance.",
    "clipboard.copyLink": "Copy link",
    "clipboard.linkCopied": "Link copied!",
    "common.edit": "Edit",
    "common.next": "Next",
    "common.unknownError": "An unknown error occurred. Please try again later.",
    "confirmLink.heroSecondary": "And tell the cashier your phone number.",
    "confirmLink.heroText": "Just show up at checkout.",
    "confirmLink.seeBalanceButton": "See Your Gift Card Balance",
    "confirmation.bulkOrder.multipleRecipients.futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent on {formattedDate}.",
    "confirmation.bulkOrder.multipleRecipients.instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way.",
    "confirmation.bulkOrder.singleRecipient.futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent to {recipientEmail} on {formattedDate}.",
    "confirmation.bulkOrder.singleRecipient.instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way to {recipientEmail}.",
    "confirmation.continueShopping": "Continue shopping",
    "confirmation.giftFutureDescription": "Your {amount} eGift Card will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
    "confirmation.giftInstantDescription": "Your {amount} eGift is on its way to {recipientName} ({recipientContact}).",
    "confirmation.groupContribution.back": "Back to group gift",
    "confirmation.groupOrder.confirmationMessageEnd": " to the gift card. An email confirmation will be sent to {emailAddress}",
    "confirmation.groupOrder.confirmationMessageStart": "You've added ",
    "confirmation.groupOrder.groupGiftStarted": "Group gift started!",
    "confirmation.groupOrder.viewGroupGift": "View group gift",
    "confirmation.orderComplete": "Order Complete",
    "confirmation.orderFutureDescription": "Your {amount} voucher will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
    "confirmation.orderInstantDescription": "Your {amount} voucher is on its way to {recipientName} ({recipientContact}).",
    "confirmation.orderUpdated": "Order Updated",
    "confirmation.reloadComplete": "Reload Complete",
    "confirmation.sendAnother": "Send Another eGift Card",
    "confirmation.sendAnotherGift": "Send another gift",
    "date.formatPlaceholder": "MM/DD/YYYY",
    "date.nextMonth": "Next Month",
    "date.previousMonth": "Previous Month",
    "discount.codeInvalid": "This discount code is invalid.",
    "discount.discountCode": "Discount Code",
    "discount.discountPricing": "Discount Pricing: {description}",
    "discover.filters.filterBy": "Filter by",
    "discover.footer.aProjectBy": "A project by",
    "discover.giveAndGetLocal.banner.title": "Give & Get Local",
    "discover.giveAndGetLocal.description.giftCards": "Local businesses need your support. You can give your favorite Square merchants a boost by buying digital gift cards.",
    "discover.giveAndGetLocal.description.voucher": "Local businesses need your support. Give your favorite Square merchants a boost by buying vouchers to send as gifts or use for yourself.",
    "discover.giveAndGetLocal.giftCardAsteriskAltText": "Gift card with colorful asterisk design",
    "discover.hero.subtitle": "Buy an eGift Card now, use it later, and help a local business you love.",
    "discover.hero.title": "Support your favorite businesses",
    "discover.locationSelector.currentLocation": "Current location",
    "discover.locationSelector.label": "Find Square sellers near you",
    "discover.locationSelector.placeholder": "Enter address or city",
    "discover.locationSelector.separator": "or",
    "discover.locationSelector.useCurrentLocation": "Use my current location",
    "discover.merchantCard.buyButton": "Buy gift card",
    "discover.results.noResults": "No results",
    "discover.shareWidget.fromDirectory.title": "Thanks for your support! Help us spread the word.",
    "discover.shareWidget.notFromDirectory.description": "Check out Give & Get Local to find businesses offering eGift Cards, pickup, delivery, and more.",
    "discover.shareWidget.notFromDirectory.title": "Support your favorite businesses",
    "discover.shareWidget.shareOnFacebook": "Share on Facebook",
    "discover.shareWidget.shareOnTwitter": "Share on Twitter",
    "egiftThemeSelector.carouselDescription": "Gift Card Theme Selector",
    "egiftThemeSelector.carouselRole": "carousel",
    "egiftThemeSelector.customImageFallback": "Seller's custom image",
    "egiftThemeSelector.nextButtonDescription": "Next Theme",
    "egiftThemeSelector.previousButtonDescription": "Previous Theme",
    "egiftThemeSelector.slideRole": "slide",
    "errors": {
      "invalidEmail": "This doesn't look like a valid email address.",
      "invalidPhone": "This doesn't look like a valid phone number.",
      "requiredField": "This is a required field."
    },
    "footer.copyright": "&copy; {year} Square, Inc.",
    "footer.giveGetLocalTerms": "Give & Get Local Terms",
    "footer.privacyPolicy": "Privacy Policy",
    "footer.termsOfService": "Terms of Service",
    "footer.text": "Powered by <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Square Gift Cards</a>",
    "form.continue": "Continue",
    "form.failedLabel": "Failed",
    "form.optionalLabel": "Optional",
    "giftInfoForm.addPersonalMessage": "Add Personal Message",
    "giftInfoForm.amount": "Amount",
    "giftInfoForm.contributionAmount": "Contribution amount",
    "giftInfoForm.contributorEmailAddresses": "Contributors email addresses ({max} people max, separated by commas)",
    "giftInfoForm.custom": "Custom",
    "giftInfoForm.customAmount": "Custom amount",
    "giftInfoForm.customAmountBoundsValidation": "eGift amount must be between {min} and {max}.",
    "giftInfoForm.customAmountPlaceholder": "{min} to {max}",
    "giftInfoForm.customAmountRequiredValidation": "Please enter a custom eGift amount or select an option.",
    "giftInfoForm.delivery": "Delivery",
    "giftInfoForm.deliveryDate": "Delivery date",
    "giftInfoForm.deliveryDateValidation": "Please enter a date within the next 60 days.",
    "giftInfoForm.deliveryDetailsHeader": "Delivery details",
    "giftInfoForm.deliveryTime": "Delivery time",
    "giftInfoForm.eGiftCardAmount": "eGift card amount",
    "giftInfoForm.emailAddress": "Email address",
    "giftInfoForm.emailAddresses": "Email addresses (separated by commas)",
    "giftInfoForm.emailAddressesRequiredValidation": "Email addresses are required",
    "giftInfoForm.giftTypeHeader": "What kind of gift is it?",
    "giftInfoForm.groupDesc": "Pool money from multiple contributors for one recipient",
    "giftInfoForm.groupHeader": "Group gift card",
    "giftInfoForm.individualDesc": "Send a gift card to one recipient",
    "giftInfoForm.individualDescWithBulk": "Send a gift card to one recipient or make a bulk purchase",
    "giftInfoForm.individualHeader": "For one individual",
    "giftInfoForm.invalidEmailAddressValidation": "Invalid email {email}",
    "giftInfoForm.invitationMessage": "Invitation message",
    "giftInfoForm.invitationMessageTooLong": "The maximum length for invitation message is 400 characters.",
    "giftInfoForm.inviteContributorsHeader": "Invite contributors (optional)",
    "giftInfoForm.inviteContributorsSubtitle": "Send an email inviting others to contribute to this gift. You'll also get a share link after checkout.",
    "giftInfoForm.maxEmailAddressesValidation": "Cannot have more than {max} emails in a single order",
    "giftInfoForm.maxQuantityValidation": "Quantity cannot be greater than {max}.",
    "giftInfoForm.minEmailAddressesValidation": "Must enter at least {min} emails",
    "giftInfoForm.multipleDesc": "Send gift cards to multiple people all at once",
    "giftInfoForm.multipleHeader": "For multiple people",
    "giftInfoForm.name": "Name",
    "giftInfoForm.personalMessage": "Personal Message",
    "giftInfoForm.personalMessageOptional": "Personal message (optional)",
    "giftInfoForm.personalMessageTooLong": "The maximum length for personal message is 200 characters.",
    "giftInfoForm.quantity": "Quantity",
    "giftInfoForm.quantityValidation": "Quantity must be between {min} and {max}",
    "giftInfoForm.recipientEmail": "Recipient email",
    "giftInfoForm.recipientName": "Recipient name",
    "giftInfoForm.recipientNamePlaceholder": "Recipient name",
    "giftInfoForm.recipientQuantityPlaceholder": "1 per recipient",
    "giftInfoForm.sendAsAGift": "Send this voucher as a gift",
    "giftInfoForm.sendInstantly": "Send Instantly",
    "giftInfoForm.sendOnAFutureDate": "Send On A Future Date",
    "giftInfoForm.sendToMyself": "Send this card to myself",
    "giftInfoForm.sendToSelf": "I want to send this to myself",
    "giftInfoForm.suggestedContributionAmount": "Suggested amount to contribute",
    "giftInfoForm.timeZone": "Time zone",
    "giftInfoForm.yourDetailsHeader": "Your details",
    "giftInfoForm.yourEmail": "Your Email",
    "giftInfoForm.yourEmailAddress": "Your email address",
    "giftInfoForm.yourName": "Your name",
    "giftInfoForm.yourNamePlaceholder": "Your name",
    "giftInfoHeader.heroSecondary.gift": "Send a gift instantly in any amount you choose",
    "giftInfoHeader.heroSecondary.voucher": "Get a voucher for yourself or gift one to a friend",
    "giftInfoHeader.heroText": "Give the Perfect Gift",
    "giftMessage.custom": "&ldquo;{customMessage}&rdquo;",
    "giftMessage.from": "From",
    "giftMessage.messageFrom": "Message from {senderEmail}",
    "giftMessage.nameNotAvailable": "name not available",
    "giftMessage.to": "To",
    "giftSummarySidebar.customMessage": "&ldquo;{customMessage}&rdquo;",
    "giftSummarySidebar.deliveryFailed": "Delivery failed: {recipient}",
    "giftSummarySidebar.discountsApplied": "Discount applied",
    "giftSummarySidebar.emailAddressUnavailable": "Email address unavailable",
    "giftSummarySidebar.multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} more",
    "giftSummarySidebar.newBalance": "Your new balance will be {newBalance}",
    "giftSummarySidebar.orderDetails": "Order Details",
    "giftSummarySidebar.payAmount": "Amount you pay",
    "giftSummarySidebar.promotionApplied": "Promotion applied",
    "giftSummarySidebar.valueDoesNotExpire": "Value does not expire.",
    "giftSummarySidebar.viewAllRecipients": "View all recipients",
    "group.giftDetailsHeader": "Gift details",
    "group.loadFunds": "Load funds",
    "group.organizedBy": "Organized by",
    "group.suggestedContributionDesc": "The organizer suggested to contribute {suggestedContributionAmount}",
    "groupGiftContributors.disclaimerToContributor": "Your name, email, and message will be shared with the recipient.",
    "groupGiftContributors.header": "Contributors",
    "groupGiftContributors.viewAll": "View all",
    "header.checkBalanceLink": "Need to check your gift card balance?",
    "header.logoAltText": "{merchantName} Logo",
    "header.orderPageLink": "Order an eGift Card",
    "headerV2.checkBalanceLink": "Check balance",
    "headerV2.logoAltText": "{merchantName} Logo",
    "headerV2.orderPageLink": "Buy gift card",
    "headerV2.reloadPageLink": "Reload card",
    "historyEvent.eventType.clear": "Clear",
    "historyEvent.eventType.deduct": "Funds deducted",
    "historyEvent.eventType.load": "Funds loaded",
    "historyEvent.eventType.redeem": "Purchase",
    "historyEvent.eventType.refund": "Refund",
    "historyEvent.eventType.transferBalanceFrom": "Transfer to {giftCardDisplay}",
    "historyEvent.eventType.transferBalanceTo": "Transfer from {giftCardDisplay}",
    "historyEvent.eventType.unknown": "Unknown",
    "link.heroSecondary": "Set up your card with a phone number.",
    "link.heroText": "Leave your gift card at home.",
    "link.noThanks": "No thanks, I'll just use the 16 digit code.",
    "link.pageOne": "1",
    "link.pageTwo": "2",
    "link.updateEGiftError": "An error occurred while updating your phone number.",
    "merchantWebsiteLinkButton.shopOnline": "Shop online",
    "modal.addEditEmailAddress.buttonText": "Save",
    "modal.addEditEmailAddress.header": "Add email address",
    "modal.addEditEmailAddress.subheader": "Leave your gift card at home and tell the cashier your email address.",
    "modal.addEditPhoneNumber.buttonText": "Save",
    "modal.addEditPhoneNumber.header": "Add phone number",
    "modal.addEditPhoneNumber.subheader": "Leave your gift card at home and tell the cashier your phone number.",
    "modal.recipientEmails.eGiftRecipients": "eGift Card Recipients",
    "pageNotFound": "Sorry, this eGift Card page does not exist or has been disabled.",
    "pageTitle.checkBalance": "Check Balance | {merchantName} Gift Cards",
    "pageTitle.default": "{merchantName} eGift Cards",
    "pageTitle.discover": "Support Your Local Businesses With Square eGift Cards Today!",
    "pageTitle.group": "Group | {merchantName} eGift Cards",
    "pageTitle.linkPhone": "Link Phone Number | {merchantName} eGift Cards",
    "pageTitle.order": "Order | {merchantName} eGift Cards",
    "pageTitle.print": "Print | {merchantName} eGift Cards",
    "pageTitle.reload": "Reload | {merchantName} eGift Cards",
    "pageTitle.resend": "Resend | {merchantName} eGift Cards",
    "payment.confirmDetails": "Confirm your order details",
    "payment.confirmDetailsWithPayment": "Confirm your order details and enter your billing information",
    "payment.error.compliance.dailyLimit": "Order exceeds daily gift card compliance limit.",
    "payment.error.paymentDeclined": "The payment was declined. Please try a different credit card.",
    "payment.heroText": "Checkout",
    "paymentForm.buyerBeware": "By completing this purchase, I acknowledge that I am liable for any loss associated with the gift card purchase in the event that the seller is unable to fulfill the goods or services.",
    "paymentForm.disclaimer.default": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>.",
    "paymentForm.disclaimer.giveAndGetLocal": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedTermsUrl}\" target=\"_blank\">Give & Get Local Terms</a>, <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>.",
    "paymentForm.errors.unauthorized": "Your payment card could not be authorized",
    "paymentForm.payWithCard": "Pay with Card",
    "paymentInfoForm.placeOrder": "Place Order",
    "printEgift.themeAltText": "eGift Card Theme",
    "promotion.applyPromotionCode": "Apply",
    "promotion.codeInvalid": "This promotion code is invalid.",
    "promotion.customHelpText": "* The {percentage}% discount applies to values {minimumLoad} and above.",
    "promotion.discountCodeApplied": "The discount code \"{code}\" has been applied.",
    "promotion.discountCodeIgnored": "Your current discount is better than \"{ignoredCode}\".",
    "promotion.fixedDiscount": "{discount} off",
    "promotion.fixedDiscountExpires": "{discount} off through {expirationDate}",
    "promotion.fixedDiscountMinLoad": "{discount} off items {minimumLoad} and above",
    "promotion.fixedDiscountMinLoadExpires": "{discount} off items {minimumLoad} and above through {expirationDate}",
    "promotion.payDiscountedPrice": "Pay {price}",
    "promotion.percentageDiscount": "{percentage}% off",
    "promotion.percentageDiscountExpires": "{percentage}% off through {expirationDate}",
    "promotion.percentageDiscountMaxDiscount": "{percentage}% off (maximum discount {maximumDiscount})",
    "promotion.percentageDiscountMaxDiscountExpires": "{percentage}% off through {expirationDate} (maximum discount {maximumDiscount})",
    "promotion.percentageDiscountMinLoad": "{percentage}% off items {minimumLoad} and above",
    "promotion.percentageDiscountMinLoadExpires": "{percentage}% off items {minimumLoad} and above through {expirationDate}",
    "promotion.promotionCode": "Promotion Code",
    "promotion.promotionCodePlaceholder": "MYDISCOUNTCODE",
    "promotion.promotionalPricing": "Promotional Pricing: {description}",
    "promotion.youPayPrice": "You pay {price}.",
    "redemptionMethod.addEmailAddress": "Add email address",
    "redemptionMethod.addPhoneNumber": "Add phone number",
    "redemptionMethod.editPhoneNumber": "Edit",
    "reload.balance": "Card Balance: {balance}",
    "reload.checkBalance": "Check Balance",
    "reload.description": "Add money to your gift card.",
    "reload.discountInput": "Add Discount Code",
    "reload.promoInput": "Add Promo Code",
    "reload.subheader": "Amount to add to card",
    "reload.submit": "Continue",
    "reloadSummarySidebar.orderDetails": "Reload Details",
    "resend.heroSecondary": "Your gift failed to be delivered. Please update the recipient's email address.",
    "resend.heroText": "Update Email Address",
    "routeError.giftCardNotFound": "Gift card is invalid or has not yet been activated.",
    "sendEGcByText.confirmation.groupOrder.confirmationMessageEnd": " to the gift card. A confirmation text will be sent to {phoneNumber}",
    "sendEGcByText.errorMessage.blacklistedEmailSuffix": "{numEmails, plural, =1 {The email address you entered is invalid} other {One or more email addresses entered is invalid}}. Please enter a different email address.",
    "sendEGcByText.errorMessage.inTotal.perDay": "You cannot send more than 4 gift cards per day by text. Please send by email instead.",
    "sendEGcByText.errorMessage.inTotal.perMonth": "You cannot send more than 20 gift cards per month by text. Please send by email instead.",
    "sendEGcByText.errorMessage.minAmount": "eGift cards sent by text must be $5 or more",
    "sendEGcByText.errorMessage.samePhoneNumber.perDay": "You cannot send more than 2 gift cards per day to the same phone number. Please send by email instead.",
    "sendEGcByText.errorMessage.samePhoneNumber.perMonth": "You cannot send more than 4 gift cards per month to the same phone number. Please send by email instead.",
    "sendEGcByText.giftInfoForm.contributorPhoneNumbers": "Contributors phone number ({max} people max, separated by commas)",
    "sendEGcByText.giftInfoForm.invalidPhoneNumberValidation": "Invalid phone number {phoneNumber}",
    "sendEGcByText.giftInfoForm.maxPhoneNumbersValidation": "Cannot have more than {max} phone numbers in a single order",
    "sendEGcByText.giftInfoForm.minPhoneNumbersValidation": "Must enter at least {min} phone numbers",
    "sendEGcByText.giftInfoForm.phoneNumber": "Phone number",
    "sendEGcByText.giftInfoForm.phoneNumbers": "Phone numbers (separated by commas)",
    "sendEGcByText.giftInfoForm.phoneNumbersRequiredValidation": "Phone numbers are required",
    "sendEGcByText.giftInfoForm.recipientPhoneNumber": "Recipient phone number",
    "sendEGcByText.giftInfoForm.yourPhoneNumber": "Your phone number",
    "sendEGcByText.giftMessage.messageFrom": "Message from {senderPhoneNumber}",
    "sendEGcByText.giftSummarySidebar.phoneNumberUnavailable": "Phone number unavailable",
    "sendEGcByText.groupGiftContributors.disclaimerToContributor": "Your name, phone number, and message will be shared with the recipient.",
    "sendEGcByText.legalDisclaimer": "By selecting Checkout, you confirm that we may send a one-time text message to the mobile phone number entered above in order to deliver your eGift card. Please contact {sellerName} directly for details about their privacy practices. Messages and data rates apply.",
    "sendEGcByText.modal.addEditPhoneNumber.header": "Add phone number",
    "sendEGcByText.modal.addEditPhoneNumber.subheader": "Leave your gift card at home and tell the cashier your phone number.",
    "sendEGcByText.modal.recipientPhoneNumbers.eGiftRecipients": "eGift Card Recipients",
    "sendEGcByText.resend.buttonText": "Resend eGift card",
    "sendEGcByText.resend.heroSecondary": "Your gift failed to be delivered. Please update the recipient's contact information.",
    "sendEGcByText.resend.heroText": "Update delivery details",
    "sendEGcByText.singleRecipientInfoForm.tabItem.sendByPhoneNumber": "Send by phone number",
    "sendEGcByText.todayAt": "Today at {time}",
    "sendEGcByText.todayInstantly": "Today (instantly)",
    "sendEGcByText.updateRecipientForm.confirmRecipientPhoneNumber": "Recipient's phone number",
    "sendEGcByText.updateRecipientForm.confirmationValidation": "Phone numbers do not match.",
    "sendEGcByText.updateRecipientForm.originalContactInfo": "Original contact info",
    "sendEGcByText.updateRecipientForm.originalPhoneNumber": "Original phone number",
    "sendEGcByText.updateRecipientForm.phoneNumberUnavailable": "Phone number unavailable",
    "sendEGcByText.updateRecipientForm.recipientPhoneNumber": "Recipient's phone number",
    "sendEGcByText.waysToRedeem.otherInstructionsOnlyPhoneNumber": "Provide your phone number at the time of checkout.",
    "sendEGcByText.waysToRedeem.phone": "Phone number",
    "shareGroupGift.shareLink": "Share link",
    "shareGroupGift.shareWithOthers": "Share the link with others so they can join in on the group gift.",
    "singleRecipientInfoForm.tabItem.sendByEmail": "Send by email",
    "singleRecipientInfoForm.tabItem.sendByText": "Send by text",
    "tabPanel.sendToMultiple": "Send to Multiple Recipients",
    "tabPanel.sendToOne": "Send to One Recipient",
    "updateRecipientForm.confirmRecipientEmailAddress": "Recipient's email address",
    "updateRecipientForm.confirmationValidation": "Emails do not match.",
    "updateRecipientForm.emailAddressUnavailable": "email address unavailable",
    "updateRecipientForm.originalEmail": "Original Email",
    "updateRecipientForm.recipientEmailAddress": "Recipient's email address",
    "updateRecipientForm.updateOrder": "Update Order",
    "view.addMoreFunds": "Add more funds",
    "view.transactionHistory": "Transaction History",
    "view.waysToRedeem": "Ways To Redeem",
    "viewPageHeader.currentBalance": "Current balance",
    "viewPageHeader.greeting": "Hi {recipientName}, {senderName} sent you an eGift card!",
    "viewPageHeader.groupGreeting": "A gift for {recipientName}!",
    "viewPageHeader.groupViewGreeting": "Hi {recipientName}, your friends sent you an eGift card!",
    "waysToRedeem.addToSquareProfile": "Add to your Square profile",
    "waysToRedeem.barCode": "Barcode",
    "waysToRedeem.code": "Code",
    "waysToRedeem.codeInstructions": "Show this code to the cashier.",
    "waysToRedeem.email": "Email",
    "waysToRedeem.giftCardCode": "Gift card code",
    "waysToRedeem.instructions": "Scan or enter the code to redeem the gift card.",
    "waysToRedeem.methodChoiceSeparator": "or",
    "waysToRedeem.otherHeader": "Other ways to redeem in-store",
    "waysToRedeem.otherInstructions": "Provide your phone number or email address at the time of checkout.",
    "waysToRedeem.otherInstructionsOnlyEmail": "Provide your email address at the time of checkout.",
    "waysToRedeem.phone": "Phone Number",
    "waysToRedeem.phoneActionText": "Change Card's Phone Number",
    "waysToRedeem.qrCode": "QR code",
    "waysToRedeem.redeemOnline": "Redeem online",
    "waysToRedeem.redeemOnlineInstructions": "Enter the gift card code during checkout.",
    "waysToRedeem.squareProfileDescription": "Square profile helps you view and manage all your gift cards from different Square businesses"
  }], ["en-au", {
    "addressInput": {
      "loading": "Loading...",
      "noResults": "No results."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Beauty & personal care",
      "charitiesEducationAndMembership": "Charities, education & membership",
      "foodAndDrink": "Food & drink",
      "healthCareAndFitness": "Health care & fitness",
      "homeAndRepair": "Home & repair",
      "leisureAndEntertainment": "Leisure & entertainment",
      "other": "Other",
      "professionalServices": "Professional services",
      "retail": "Retail",
      "transportation": "Transportation"
    },
    "businessCategoryFilters": {
      "hideFilters": "Hide filters",
      "showFilters": "Show filters"
    },
    "checkBalance": {
      "checkboxLabel": "My gift card does not have a PIN",
      "description": "Enter your gift card number to check your balance.",
      "errors": {
        "notFound": "That gift card could not be found.",
        "rateLimit": "Too many attempts. Please try again in a moment."
      },
      "label": "Gift Card Number",
      "pinLabel": "PIN",
      "submit": "Check Balance",
      "tooltip": "The PIN is located on the back of the gift card, next to the gift card number.",
      "tooltipPinRequired": "The PIN is located on the back of the gift card, next to the gift card number. If your gift card does not have a PIN, please contact the merchant to check your balance."
    },
    "clipboard": {
      "copyLink": "Copy link",
      "linkCopied": "Link copied!"
    },
    "common": {
      "edit": "Edit",
      "next": "Next",
      "unknownError": "An unknown error occurred. Please try again later."
    },
    "confirmLink": {
      "heroSecondary": "And tell the cashier your phone number.",
      "heroText": "Just show up at checkout.",
      "seeBalanceButton": "See Your Gift Card Balance"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way."
        },
        "singleRecipient": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent to {recipientEmail} on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way to {recipientEmail}."
        }
      },
      "continueShopping": "Continue shopping",
      "giftFutureDescription": "Your {amount} eGift Card will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "giftInstantDescription": "Your {amount} eGift is on its way to {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Back to group gift"
      },
      "groupOrder": {
        "confirmationMessageEnd": " to the gift card. A confirmation email will be sent to {emailAddress}",
        "confirmationMessageStart": "You’ve added ",
        "groupGiftStarted": "Group gift started!",
        "viewGroupGift": "View group gift"
      },
      "orderComplete": "Order Complete",
      "orderFutureDescription": "Your {amount} voucher will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "orderInstantDescription": "Your {amount} voucher is on its way to {recipientName} ({recipientContact}).",
      "orderUpdated": "Order Updated",
      "reloadComplete": "Reload Complete",
      "sendAnother": "Send Another eGift Card",
      "sendAnotherGift": "Send another gift"
    },
    "date": {
      "formatPlaceholder": "DD/MM/YYYY",
      "nextMonth": "Next Month",
      "previousMonth": "Previous Month"
    },
    "discount": {
      "codeInvalid": "This discount code is invalid.",
      "discountCode": "Discount code",
      "discountPricing": "Discount pricing: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filter by"
      },
      "footer": {
        "aProjectBy": "A project by"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Local businesses need your support. You can give your favourite Square merchants a boost by buying digital gift cards.",
          "voucher": "Local businesses need your support. Give your favourite Square merchants a boost by buying vouchers to send as gifts or use for yourself."
        },
        "giftCardAsteriskAltText": "Gift card with colourful asterisk design"
      },
      "hero": {
        "subtitle": "Buy an eGift Card now, use it later and help a local business you love.",
        "title": "Support your favourite businesses"
      },
      "locationSelector": {
        "currentLocation": "Current location",
        "label": "Find Square sellers near you",
        "placeholder": "Enter address or city",
        "separator": "or",
        "useCurrentLocation": "Use my current location"
      },
      "merchantCard": {
        "buyButton": "Buy gift card"
      },
      "results": {
        "noResults": "No results"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Thanks for your support! Help us spread the word."
        },
        "notFromDirectory": {
          "description": "Check out Give & Get Local to find businesses offering eGift Cards, pickup, delivery and more.",
          "title": "Support your favourite businesses"
        },
        "shareOnFacebook": "Share on Facebook",
        "shareOnTwitter": "Share on Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Gift Card Theme Selector",
      "carouselRole": "carousel",
      "customImageFallback": "Seller’s custom image",
      "nextButtonDescription": "Next Theme",
      "previousButtonDescription": "Previous Theme",
      "slideRole": "slide"
    },
    "errors": {
      "invalidEmail": "This doesn't look like a valid email address.",
      "invalidPhone": "This doesn't look like a valid phone number.",
      "requiredField": "This is a required field."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Give & Get Local Terms",
      "privacyPolicy": "Privacy Policy",
      "termsOfService": "Terms of Service",
      "text": "Powered by <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Square Gift Cards</a>"
    },
    "form": {
      "continue": "Continue",
      "failedLabel": "Failed",
      "optionalLabel": "Optional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Add Personal Message",
      "amount": "Amount",
      "contributionAmount": "Contribution amount",
      "contributorEmailAddresses": "Contributors email addresses ({max} people max, separated by commas)",
      "custom": "Custom",
      "customAmount": "Custom amount",
      "customAmountBoundsValidation": "eGift amount must be between {min} and {max}.",
      "customAmountPlaceholder": "{min} to {max}",
      "customAmountRequiredValidation": "Please enter a custom eGift amount or select an option.",
      "delivery": "Delivery",
      "deliveryDate": "Delivery date",
      "deliveryDateValidation": "Please enter a date within the next 60 days.",
      "deliveryDetailsHeader": "Delivery details",
      "deliveryTime": "Delivery time",
      "eGiftCardAmount": "eGift card amount",
      "emailAddress": "Email address",
      "emailAddresses": "Email addresses (separated by commas)",
      "emailAddressesRequiredValidation": "Email addresses are required",
      "giftTypeHeader": "What kind of gift is it?",
      "groupDesc": "Pool money from multiple contributors for one recipient",
      "groupHeader": "Group gift card",
      "individualDesc": "Send a gift card to one recipient",
      "individualDescWithBulk": "Send a gift card to one recipient or make a bulk purchase",
      "individualHeader": "For one individual",
      "invalidEmailAddressValidation": "Invalid email {email}",
      "invitationMessage": "Invitation message",
      "invitationMessageTooLong": "The maximum length for invitation message is 400 characters.",
      "inviteContributorsHeader": "Invite contributors (optional)",
      "inviteContributorsSubtitle": "Send an email inviting others to contribute to this gift. You’ll also get a share link after checkout.",
      "maxEmailAddressesValidation": "Cannot have more than {max} emails in a single order",
      "maxQuantityValidation": "Quantity cannot be greater than {max}.",
      "minEmailAddressesValidation": "Must enter at least {min} emails",
      "multipleDesc": "Send gift cards to multiple people all at once",
      "multipleHeader": "For multiple people",
      "name": "Name",
      "personalMessage": "Personal Message",
      "personalMessageOptional": "Personal message (optional)",
      "personalMessageTooLong": "The maximum length for personal message is 200 characters.",
      "quantity": "Quantity",
      "quantityValidation": "Quantity must be between {min} and {max}",
      "recipientEmail": "Recipient email address",
      "recipientName": "Recipient name",
      "recipientNamePlaceholder": "Recipient name",
      "recipientQuantityPlaceholder": "1 per recipient",
      "sendAsAGift": "Send this voucher as a gift",
      "sendInstantly": "Send Instantly",
      "sendOnAFutureDate": "Send On A Future Date",
      "sendToMyself": "Send this card to myself",
      "sendToSelf": "I want to send this to myself",
      "suggestedContributionAmount": "Suggested amount to contribute",
      "timeZone": "Time zone",
      "yourDetailsHeader": "Your details",
      "yourEmail": "Your Email",
      "yourEmailAddress": "Your email address",
      "yourName": "Your name",
      "yourNamePlaceholder": "Your name"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Send a gift instantly in any amount you choose",
        "voucher": "Get a voucher for yourself or gift one to a friend"
      },
      "heroText": "Give the Perfect Gift"
    },
    "giftMessage": {
      "custom": "‘{customMessage}’",
      "from": "From",
      "messageFrom": "Message from {senderEmail}",
      "nameNotAvailable": "name not available",
      "to": "To"
    },
    "giftSummarySidebar": {
      "customMessage": "‘{customMessage}’",
      "deliveryFailed": "Delivery failed: {recipient}",
      "discountsApplied": "Discount applied",
      "emailAddressUnavailable": "Email address unavailable",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} more",
      "newBalance": "Your new balance will be {newBalance}",
      "orderDetails": "Order Details",
      "payAmount": "Amount you pay",
      "promotionApplied": "Promotion applied",
      "valueDoesNotExpire": "Value does not expire.",
      "viewAllRecipients": "View all recipients"
    },
    "group": {
      "giftDetailsHeader": "Gift details",
      "loadFunds": "Load funds",
      "organizedBy": "Organised by",
      "suggestedContributionDesc": "The organiser suggested to contribute {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Your name, email and message will be shared with the recipient.",
      "header": "Contributors",
      "viewAll": "View all"
    },
    "header": {
      "checkBalanceLink": "Need to check your gift card balance?",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Order an eGift Card"
    },
    "headerV2": {
      "checkBalanceLink": "Check balance",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Buy gift card",
      "reloadPageLink": "Reload card"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Clear",
        "deduct": "Funds deducted",
        "load": "Funds loaded",
        "redeem": "Purchase",
        "refund": "Refund",
        "transferBalanceFrom": "Transfer to {giftCardDisplay}",
        "transferBalanceTo": "Transfer from {giftCardDisplay}",
        "unknown": "Unknown"
      }
    },
    "link": {
      "heroSecondary": "Set up your card with a phone number.",
      "heroText": "Leave your gift card at home.",
      "noThanks": "No thanks, I'll just use the 16 digit code.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "An error occurred while updating your phone number."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Shop online"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Save",
        "header": "Add email address",
        "subheader": "Leave your gift card at home and tell the cashier your email address."
      },
      "addEditPhoneNumber": {
        "buttonText": "Save",
        "header": "Add phone number",
        "subheader": "Leave your gift card at home and tell the cashier your phone number."
      },
      "recipientEmails": {
        "eGiftRecipients": "eGift Card Recipients"
      }
    },
    "pageNotFound": "Sorry, this eGift Card page does not exist or has been disabled.",
    "pageTitle": {
      "checkBalance": "Check Balance | {merchantName} Gift Cards",
      "default": "{merchantName} eGift Cards",
      "discover": "Support Your Local Businesses With Square eGift Cards Today!",
      "group": "Group | {merchantName} eGift Cards",
      "linkPhone": "Link Phone Number | {merchantName} eGift Cards",
      "order": "Order | {merchantName} eGift Cards",
      "print": "Print | {merchantName} eGift Cards",
      "reload": "Reload | {merchantName} eGift Cards",
      "resend": "Resend | {merchantName} eGift Cards"
    },
    "payment": {
      "confirmDetails": "Confirm your order details",
      "confirmDetailsWithPayment": "Confirm your order details and enter your billing information",
      "error": {
        "compliance": {
          "dailyLimit": "Order exceeds daily gift card compliance limit."
        },
        "paymentDeclined": "The payment was declined. Please try a different credit card."
      },
      "heroText": "Checkout"
    },
    "paymentForm": {
      "buyerBeware": "By completing this purchase, I acknowledge that I am liable for any loss associated with the gift card purchase in the event that the seller is unable to fulfil the goods or services.",
      "disclaimer": {
        "default": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>.",
        "giveAndGetLocal": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedTermsUrl}\" target=\"_blank\">Give & Get Local Terms</a>, <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>."
      },
      "errors": {
        "unauthorized": "Your payment card could not be authorised"
      },
      "payWithCard": "Pay with Card"
    },
    "paymentInfoForm": {
      "placeOrder": "Place Order"
    },
    "printEgift": {
      "themeAltText": "eGift Card Theme"
    },
    "promotion": {
      "applyPromotionCode": "Apply",
      "codeInvalid": "This promotion code is invalid.",
      "customHelpText": "* The {percentage}% discount applies to values {minimumLoad} and above.",
      "discountCodeApplied": "The discount code ‘{code}’ has been applied.",
      "discountCodeIgnored": "Your current discount is better than ‘{ignoredCode}’.",
      "fixedDiscount": "{discount} off",
      "fixedDiscountExpires": "{discount} off until {expirationDate}",
      "fixedDiscountMinLoad": "{discount} off items {minimumLoad} and above",
      "fixedDiscountMinLoadExpires": "{discount} off items {minimumLoad} and above until {expirationDate}",
      "payDiscountedPrice": "Pay {price}",
      "percentageDiscount": "{percentage}% off",
      "percentageDiscountExpires": "{percentage}% off until {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage}% off (maximum discount {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage}% off through {expirationDate} (maximum discount {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage}% off items {minimumLoad} and above",
      "percentageDiscountMinLoadExpires": "{percentage}% off items {minimumLoad} and above until {expirationDate}",
      "promotionCode": "Promotion Code",
      "promotionCodePlaceholder": "MYDISCOUNTCODE",
      "promotionalPricing": "Promotional Pricing: {description}",
      "youPayPrice": "You pay {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Add email address",
      "addPhoneNumber": "Add phone number",
      "editPhoneNumber": "Edit"
    },
    "reload": {
      "balance": "Card Balance: {balance}",
      "checkBalance": "Check Balance",
      "description": "Add money to your gift card.",
      "discountInput": "Add Discount code",
      "promoInput": "Add Promo Code",
      "subheader": "Amount to add to card",
      "submit": "Continue"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Reload Details"
    },
    "resend": {
      "heroSecondary": "Your gift failed to be delivered. Please update the recipient's email address.",
      "heroText": "Update Email Address"
    },
    "routeError": {
      "giftCardNotFound": "Gift card is invalid or has not yet been activated."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " to the gift card. A confirmation text will be sent to {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{The email address you entered is invalid} other{One or more email addresses entered is invalid}}. Please enter a different email address.",
        "inTotal": {
          "perDay": "You cannot send more than 4 gift cards per day by text. Please send by email instead.",
          "perMonth": "You cannot send more than 20 gift cards per month by text. Please send by email instead."
        },
        "minAmount": "eGift cards sent by text must be $5 or more",
        "samePhoneNumber": {
          "perDay": "You cannot send more than 2 gift cards per day to the same phone number. Please send by email instead.",
          "perMonth": "You cannot send more than 4 gift cards per month to the same phone number. Please send by email instead."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Contributors phone number ({max} people max, separated by commas)",
        "invalidPhoneNumberValidation": "Invalid phone number {phoneNumber}",
        "maxPhoneNumbersValidation": "Cannot have more than {max} phone numbers in a single order",
        "minPhoneNumbersValidation": "Must enter at least {min} phone numbers",
        "phoneNumber": "Phone number",
        "phoneNumbers": "Phone numbers (separated by commas)",
        "phoneNumbersRequiredValidation": "Phone numbers are required",
        "recipientPhoneNumber": "Recipient phone number",
        "yourPhoneNumber": "Your phone number"
      },
      "giftMessage": {
        "messageFrom": "Message from {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Phone number unavailable"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Your name, phone number and message will be shared with the recipient."
      },
      "legalDisclaimer": "By selecting Checkout, you confirm that we may send a one-time text message to the mobile phone number entered above in order to deliver your eGift card. Please contact {sellerName} directly for details about their privacy practices. Messages and data rates apply.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Add phone number",
          "subheader": "Leave your gift card at home and tell the cashier your phone number."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "eGift Card Recipients"
        }
      },
      "resend": {
        "buttonText": "Resend eGift card",
        "heroSecondary": "Your gift failed to be delivered. Please update the recipient’s contact information.",
        "heroText": "Update delivery details"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Send by phone number"
        }
      },
      "todayAt": "Today at {time}",
      "todayInstantly": "Today (instantly)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Recipient’s phone number",
        "confirmationValidation": "Phone numbers do not match.",
        "originalContactInfo": "Original contact info",
        "originalPhoneNumber": "Original phone number",
        "phoneNumberUnavailable": "Phone number unavailable",
        "recipientPhoneNumber": "Recipient’s phone number"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Provide your phone number at the time of checkout.",
        "phone": "Phone number"
      }
    },
    "shareGroupGift": {
      "shareLink": "Share link",
      "shareWithOthers": "Share the link with others so they can join in on the group gift."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Send by email",
        "sendByText": "Send by text"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Send to Multiple Recipients",
      "sendToOne": "Send to One Recipient"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Recipient’s email address",
      "confirmationValidation": "Emails do not match.",
      "emailAddressUnavailable": "email address unavailable",
      "originalEmail": "Original Email",
      "recipientEmailAddress": "Recipient’s email address",
      "updateOrder": "Update Order"
    },
    "view": {
      "addMoreFunds": "Add more funds",
      "transactionHistory": "Transaction History",
      "waysToRedeem": "Ways To Redeem"
    },
    "viewPageHeader": {
      "currentBalance": "Current balance",
      "greeting": "Hi {recipientName}, {senderName} sent you an eGift card!",
      "groupGreeting": "A gift for {recipientName}!",
      "groupViewGreeting": "Hi {recipientName}, your friends sent you an eGift card!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Add to your Square profile",
      "barCode": "Barcode",
      "code": "Code",
      "codeInstructions": "Show this code to the cashier.",
      "email": "Email",
      "giftCardCode": "Gift card code",
      "instructions": "Scan or enter the code to redeem the gift card.",
      "methodChoiceSeparator": "or",
      "otherHeader": "Other ways to redeem in-store",
      "otherInstructions": "Provide your phone number or email address at the time of checkout.",
      "otherInstructionsOnlyEmail": "Provide your email address at the time of checkout.",
      "phone": "Phone Number",
      "phoneActionText": "Change Card's Phone Number",
      "qrCode": "QR code",
      "redeemOnline": "Redeem online",
      "redeemOnlineInstructions": "Enter the gift card code during checkout.",
      "squareProfileDescription": "Square profile helps you view and manage all your gift cards from different Square businesses"
    }
  }], ["en-ca", {
    "addressInput": {
      "loading": "Loading...",
      "noResults": "No results."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Beauty & personal care",
      "charitiesEducationAndMembership": "Charities, education & membership",
      "foodAndDrink": "Food & drink",
      "healthCareAndFitness": "Health care & fitness",
      "homeAndRepair": "Home & repair",
      "leisureAndEntertainment": "Leisure & entertainment",
      "other": "Other",
      "professionalServices": "Professional services",
      "retail": "Retail",
      "transportation": "Transportation"
    },
    "businessCategoryFilters": {
      "hideFilters": "Hide filters",
      "showFilters": "Show filters"
    },
    "checkBalance": {
      "checkboxLabel": "My gift card does not have a PIN",
      "description": "Enter your gift card number to check your balance.",
      "errors": {
        "notFound": "That gift card could not be found.",
        "rateLimit": "Too many attempts. Please try again in a moment."
      },
      "label": "Gift Card Number",
      "pinLabel": "PIN",
      "submit": "Check Balance",
      "tooltip": "The PIN is located on the back of the gift card, next to the gift card number.",
      "tooltipPinRequired": "The PIN is located on the back of the gift card, next to the gift card number. If your gift card does not have a PIN, please contact the merchant to check your balance."
    },
    "clipboard": {
      "copyLink": "Copy link",
      "linkCopied": "Link copied!"
    },
    "common": {
      "edit": "Edit",
      "next": "Next",
      "unknownError": "An unknown error occurred. Please try again later."
    },
    "confirmLink": {
      "heroSecondary": "And tell the cashier your phone number.",
      "heroText": "Just show up at checkout.",
      "seeBalanceButton": "See Your Gift Card Balance"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way."
        },
        "singleRecipient": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent to {recipientEmail} on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way to {recipientEmail}."
        }
      },
      "continueShopping": "Continue shopping",
      "giftFutureDescription": "Your {amount} eGift Card will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "giftInstantDescription": "Your {amount} eGift is on its way to {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Back to group gift"
      },
      "groupOrder": {
        "confirmationMessageEnd": " to the gift card. A confirmation email will be sent to {emailAddress}",
        "confirmationMessageStart": "You’ve added ",
        "groupGiftStarted": "Group gift started!",
        "viewGroupGift": "View group gift"
      },
      "orderComplete": "Order Complete",
      "orderFutureDescription": "Your {amount} voucher will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "orderInstantDescription": "Your {amount} voucher is on its way to {recipientName} ({recipientContact}).",
      "orderUpdated": "Order Updated",
      "reloadComplete": "Reload Complete",
      "sendAnother": "Send Another eGift Card",
      "sendAnotherGift": "Send another gift"
    },
    "date": {
      "formatPlaceholder": "MM/DD/YYYY",
      "nextMonth": "Next Month",
      "previousMonth": "Previous Month"
    },
    "discount": {
      "codeInvalid": "This discount code is invalid.",
      "discountCode": "Discount Code",
      "discountPricing": "Discount Pricing: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filter by"
      },
      "footer": {
        "aProjectBy": "A project by"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Local businesses need your support. You can give your favourite Square merchants a boost by buying digital gift cards.",
          "voucher": "Local businesses need your support. Give your favourite Square merchants a boost by buying vouchers to send as gifts or use for yourself."
        },
        "giftCardAsteriskAltText": "Gift card with colourful asterisk design"
      },
      "hero": {
        "subtitle": "Buy an eGift Card now, use it later and help a local business you love.",
        "title": "Support your favourite businesses"
      },
      "locationSelector": {
        "currentLocation": "Current location",
        "label": "Find Square sellers near you",
        "placeholder": "Enter address or city",
        "separator": "or",
        "useCurrentLocation": "Use my current location"
      },
      "merchantCard": {
        "buyButton": "Buy gift card"
      },
      "results": {
        "noResults": "No results"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Thanks for your support! Help us spread the word."
        },
        "notFromDirectory": {
          "description": "Check out Give & Get Local to find businesses offering eGift Cards, pick-up, delivery and more.",
          "title": "Support your favourite businesses"
        },
        "shareOnFacebook": "Share on Facebook",
        "shareOnTwitter": "Share on Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Gift Card Theme Selector",
      "carouselRole": "carousel",
      "customImageFallback": "Seller’s custom image",
      "nextButtonDescription": "Next Theme",
      "previousButtonDescription": "Previous Theme",
      "slideRole": "slide"
    },
    "errors": {
      "invalidEmail": "This doesn't look like a valid email address.",
      "invalidPhone": "This doesn't look like a valid phone number.",
      "requiredField": "This is a required field."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Give & Get Local Terms",
      "privacyPolicy": "Privacy Policy",
      "termsOfService": "Terms of Service",
      "text": "Powered by <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Square Gift Cards</a>"
    },
    "form": {
      "continue": "Continue",
      "failedLabel": "Failed",
      "optionalLabel": "Optional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Add Personal Message",
      "amount": "Amount",
      "contributionAmount": "Contribution amount",
      "contributorEmailAddresses": "Contributors’ email addresses ({max} people max, separated by commas)",
      "custom": "Custom",
      "customAmount": "Custom amount",
      "customAmountBoundsValidation": "eGift amount must be between {min} and {max}.",
      "customAmountPlaceholder": "{min} to {max}",
      "customAmountRequiredValidation": "Please enter a custom eGift amount or select an option.",
      "delivery": "Delivery",
      "deliveryDate": "Delivery date",
      "deliveryDateValidation": "Please enter a date within the next 60 days.",
      "deliveryDetailsHeader": "Delivery details",
      "deliveryTime": "Delivery time",
      "eGiftCardAmount": "eGift card amount",
      "emailAddress": "Email address",
      "emailAddresses": "Email addresses (separated by commas)",
      "emailAddressesRequiredValidation": "Email addresses are required",
      "giftTypeHeader": "What kind of gift is it?",
      "groupDesc": "Pool money from multiple contributors for one recipient",
      "groupHeader": "Group gift card",
      "individualDesc": "Send a gift card to one recipient",
      "individualDescWithBulk": "Send a gift card to one recipient or make a bulk purchase",
      "individualHeader": "For one individual",
      "invalidEmailAddressValidation": "Invalid email {email}",
      "invitationMessage": "Invitation message",
      "invitationMessageTooLong": "The maximum length for invitation message is 400 characters.",
      "inviteContributorsHeader": "Invite contributors (optional)",
      "inviteContributorsSubtitle": "Send an email inviting others to contribute to this gift. You’ll also get a share link after checkout.",
      "maxEmailAddressesValidation": "Cannot have more than {max} emails in a single order",
      "maxQuantityValidation": "Quantity cannot be greater than {max}.",
      "minEmailAddressesValidation": "Must enter at least {min} emails",
      "multipleDesc": "Send gift cards to multiple people all at once",
      "multipleHeader": "For multiple people",
      "name": "Name",
      "personalMessage": "Personal Message",
      "personalMessageOptional": "Personal message (optional)",
      "personalMessageTooLong": "The maximum length for personal message is 200 characters.",
      "quantity": "Quantity",
      "quantityValidation": "Quantity must be between {min} and {max}",
      "recipientEmail": "Recipient email address",
      "recipientName": "Recipient name",
      "recipientNamePlaceholder": "Recipient name",
      "recipientQuantityPlaceholder": "1 per recipient",
      "sendAsAGift": "Send this voucher as a gift",
      "sendInstantly": "Send Instantly",
      "sendOnAFutureDate": "Send On A Future Date",
      "sendToMyself": "Send this card to myself",
      "sendToSelf": "I want to send this to myself",
      "suggestedContributionAmount": "Suggested amount to contribute",
      "timeZone": "Time zone",
      "yourDetailsHeader": "Your details",
      "yourEmail": "Your Email",
      "yourEmailAddress": "Your email address",
      "yourName": "Your name",
      "yourNamePlaceholder": "Your name"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Send a gift instantly in any amount you choose",
        "voucher": "Get a voucher for yourself or gift one to a friend"
      },
      "heroText": "Give the Perfect Gift"
    },
    "giftMessage": {
      "custom": "“{customMessage}”",
      "from": "From",
      "messageFrom": "Message from {senderEmail}",
      "nameNotAvailable": "name not available",
      "to": "To"
    },
    "giftSummarySidebar": {
      "customMessage": "“{customMessage}”",
      "deliveryFailed": "Delivery failed: {recipient}",
      "discountsApplied": "Discount applied",
      "emailAddressUnavailable": "Email address unavailable",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} more",
      "newBalance": "Your new balance will be {newBalance}",
      "orderDetails": "Order Details",
      "payAmount": "Amount you pay",
      "promotionApplied": "Promotion applied",
      "valueDoesNotExpire": "Value does not expire.",
      "viewAllRecipients": "View all recipients"
    },
    "group": {
      "giftDetailsHeader": "Gift details",
      "loadFunds": "Load funds",
      "organizedBy": "Organized by",
      "suggestedContributionDesc": "The organizer suggested to contribute {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Your name, email and message will be shared with the recipient.",
      "header": "Contributors",
      "viewAll": "View all"
    },
    "header": {
      "checkBalanceLink": "Need to check your gift card balance?",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Order an eGift Card"
    },
    "headerV2": {
      "checkBalanceLink": "Check balance",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Buy gift card",
      "reloadPageLink": "Reload card"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Clear",
        "deduct": "Funds deducted",
        "load": "Funds loaded",
        "redeem": "Purchase",
        "refund": "Refund",
        "transferBalanceFrom": "Transfer to {giftCardDisplay}",
        "transferBalanceTo": "Transfer from {giftCardDisplay}",
        "unknown": "Unknown"
      }
    },
    "link": {
      "heroSecondary": "Set up your card with a phone number.",
      "heroText": "Leave your gift card at home.",
      "noThanks": "No thanks, I'll just use the 16 digit code.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "An error occurred while updating your phone number."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Shop online"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Save",
        "header": "Add email address",
        "subheader": "Leave your gift card at home and tell the cashier your email address."
      },
      "addEditPhoneNumber": {
        "buttonText": "Save",
        "header": "Add phone number",
        "subheader": "Leave your gift card at home and tell the cashier your phone number."
      },
      "recipientEmails": {
        "eGiftRecipients": "eGift Card Recipients"
      }
    },
    "pageNotFound": "Sorry, this eGift Card page does not exist or has been disabled.",
    "pageTitle": {
      "checkBalance": "Check Balance | {merchantName} Gift Cards",
      "default": "{merchantName} eGift Cards",
      "discover": "Support Your Local Businesses With Square eGift Cards Today!",
      "group": "Group | {merchantName} eGift Cards",
      "linkPhone": "Link Phone Number | {merchantName} eGift Cards",
      "order": "Order | {merchantName} eGift Cards",
      "print": "Print | {merchantName} eGift Cards",
      "reload": "Reload | {merchantName} eGift Cards",
      "resend": "Resend | {merchantName} eGift Cards"
    },
    "payment": {
      "confirmDetails": "Confirm your order details",
      "confirmDetailsWithPayment": "Confirm your order details and enter your billing information",
      "error": {
        "compliance": {
          "dailyLimit": "Order exceeds daily gift card compliance limit."
        },
        "paymentDeclined": "The payment was declined. Please try a different credit card."
      },
      "heroText": "Checkout"
    },
    "paymentForm": {
      "buyerBeware": "By completing this purchase, I acknowledge that I am liable for any loss associated with the gift card purchase in the event that the seller is unable to fulfil the goods or services.",
      "disclaimer": {
        "default": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>.",
        "giveAndGetLocal": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedTermsUrl}\" target=\"_blank\">Give & Get Local Terms</a>, <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>."
      },
      "errors": {
        "unauthorized": "Your payment card could not be authorized"
      },
      "payWithCard": "Pay with Card"
    },
    "paymentInfoForm": {
      "placeOrder": "Place Order"
    },
    "printEgift": {
      "themeAltText": "eGift Card Theme"
    },
    "promotion": {
      "applyPromotionCode": "Apply",
      "codeInvalid": "This promotion code is invalid.",
      "customHelpText": "* The {percentage}% discount applies to values {minimumLoad} and above.",
      "discountCodeApplied": "The discount code ‘{code}’ has been applied.",
      "discountCodeIgnored": "Your current discount is better than ‘{ignoredCode}’.",
      "fixedDiscount": "{discount} off",
      "fixedDiscountExpires": "{discount} off through {expirationDate}",
      "fixedDiscountMinLoad": "{discount} off items {minimumLoad} and above",
      "fixedDiscountMinLoadExpires": "{discount} off items {minimumLoad} and above through {expirationDate}",
      "payDiscountedPrice": "Pay {price}",
      "percentageDiscount": "{percentage}% off",
      "percentageDiscountExpires": "{percentage}% off through {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage}% off (maximum discount {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage}% off through {expirationDate} (maximum discount {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage}% off items {minimumLoad} and above",
      "percentageDiscountMinLoadExpires": "{percentage}% off items {minimumLoad} and above through {expirationDate}",
      "promotionCode": "Promotion Code",
      "promotionCodePlaceholder": "MYDISCOUNTCODE",
      "promotionalPricing": "Promotional Pricing: {description}",
      "youPayPrice": "You pay {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Add email address",
      "addPhoneNumber": "Add phone number",
      "editPhoneNumber": "Edit"
    },
    "reload": {
      "balance": "Card Balance: {balance}",
      "checkBalance": "Check Balance",
      "description": "Add money to your gift card.",
      "discountInput": "Add Discount Code",
      "promoInput": "Add Promo Code",
      "subheader": "Amount to add to card",
      "submit": "Continue"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Reload Details"
    },
    "resend": {
      "heroSecondary": "Your gift failed to be delivered. Please update the recipient's email address.",
      "heroText": "Update Email Address"
    },
    "routeError": {
      "giftCardNotFound": "Gift card is invalid or has not yet been activated."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " to the gift card. A confirmation text will be sent to {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{The email address you entered is invalid} other{One or more email addresses entered is invalid}}. Please enter a different email address.",
        "inTotal": {
          "perDay": "You cannot send more than 4 gift cards per day by text. Please send by email instead.",
          "perMonth": "You cannot send more than 20 gift cards per month by text. Please send by email instead."
        },
        "minAmount": "eGift cards sent by text must be $5 or more",
        "samePhoneNumber": {
          "perDay": "You cannot send more than 2 gift cards per day to the same phone number. Please send by email instead.",
          "perMonth": "You cannot send more than 4 gift cards per month to the same phone number. Please send by email instead."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Contributors’ phone numbers ({max} people max, separated by commas)",
        "invalidPhoneNumberValidation": "Invalid phone number {phoneNumber}",
        "maxPhoneNumbersValidation": "Cannot have more than {max} phone numbers in a single order",
        "minPhoneNumbersValidation": "Must enter at least {min} phone numbers",
        "phoneNumber": "Phone number",
        "phoneNumbers": "Phone numbers (separated by commas)",
        "phoneNumbersRequiredValidation": "Phone numbers are required",
        "recipientPhoneNumber": "Recipient phone number",
        "yourPhoneNumber": "Your phone number"
      },
      "giftMessage": {
        "messageFrom": "Message from {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Phone number unavailable"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Your name, phone number and message will be shared with the recipient."
      },
      "legalDisclaimer": "By selecting Checkout, you confirm that we may send a one-time text message to the mobile phone number entered above in order to deliver your eGift card. Please contact {sellerName} directly for details about their privacy practices. Messages and data rates apply.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Add phone number",
          "subheader": "Leave your gift card at home and tell the cashier your phone number."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "eGift Card Recipients"
        }
      },
      "resend": {
        "buttonText": "Resend eGift card",
        "heroSecondary": "Your gift failed to be delivered. Please update the recipient’s contact information.",
        "heroText": "Update delivery details"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Send by phone number"
        }
      },
      "todayAt": "Today at {time}",
      "todayInstantly": "Today (instantly)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Recipient’s phone number",
        "confirmationValidation": "Phone numbers do not match.",
        "originalContactInfo": "Original contact info",
        "originalPhoneNumber": "Original phone number",
        "phoneNumberUnavailable": "Phone number unavailable",
        "recipientPhoneNumber": "Recipient’s phone number"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Provide your phone number at the time of checkout.",
        "phone": "Phone number"
      }
    },
    "shareGroupGift": {
      "shareLink": "Share link",
      "shareWithOthers": "Share the link with others so they can join in on the group gift."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Send by email",
        "sendByText": "Send by text"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Send to Multiple Recipients",
      "sendToOne": "Send to One Recipient"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Recipient’s email address",
      "confirmationValidation": "Emails do not match.",
      "emailAddressUnavailable": "email address unavailable",
      "originalEmail": "Original Email",
      "recipientEmailAddress": "Recipient’s email address",
      "updateOrder": "Update Order"
    },
    "view": {
      "addMoreFunds": "Add more funds",
      "transactionHistory": "Transaction History",
      "waysToRedeem": "Ways To Redeem"
    },
    "viewPageHeader": {
      "currentBalance": "Current balance",
      "greeting": "Hi {recipientName}, {senderName} sent you an eGift card!",
      "groupGreeting": "A gift for {recipientName}!",
      "groupViewGreeting": "Hi {recipientName}, your friends sent you an eGift card!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Add to your Square profile",
      "barCode": "Barcode",
      "code": "Code",
      "codeInstructions": "Show this code to the cashier.",
      "email": "Email",
      "giftCardCode": "Gift card code",
      "instructions": "Scan or enter the code to redeem the gift card.",
      "methodChoiceSeparator": "or",
      "otherHeader": "Other ways to redeem in-store",
      "otherInstructions": "Provide your phone number or email address at the time of checkout.",
      "otherInstructionsOnlyEmail": "Provide your email address at the time of checkout.",
      "phone": "Phone Number",
      "phoneActionText": "Change Card's Phone Number",
      "qrCode": "QR code",
      "redeemOnline": "Redeem online",
      "redeemOnlineInstructions": "Enter the gift card code during checkout.",
      "squareProfileDescription": "Square profile helps you view and manage all your gift cards from different Square businesses"
    }
  }], ["en-gb", {
    "addressInput": {
      "loading": "Loading...",
      "noResults": "No results."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Beauty & personal care",
      "charitiesEducationAndMembership": "Charities, education & membership",
      "foodAndDrink": "Food & drink",
      "healthCareAndFitness": "Healthcare & fitness",
      "homeAndRepair": "Home & repair",
      "leisureAndEntertainment": "Leisure & entertainment",
      "other": "Other",
      "professionalServices": "Professional services",
      "retail": "Retail",
      "transportation": "Transportation"
    },
    "businessCategoryFilters": {
      "hideFilters": "Hide filters",
      "showFilters": "Show filters"
    },
    "checkBalance": {
      "checkboxLabel": "My gift card does not have a PIN",
      "description": "Enter your gift card number to check your balance.",
      "errors": {
        "notFound": "That gift card could not be found.",
        "rateLimit": "Too many attempts. Please try again in a moment."
      },
      "label": "Gift Card Number",
      "pinLabel": "PIN",
      "submit": "Check Balance",
      "tooltip": "The PIN is located on the back of the gift card, next to the gift card number.",
      "tooltipPinRequired": "The PIN is located on the back of the gift card, next to the gift card number. If your gift card does not have a PIN, please contact the merchant to check your balance."
    },
    "clipboard": {
      "copyLink": "Copy link",
      "linkCopied": "Link copied!"
    },
    "common": {
      "edit": "Edit",
      "next": "Next",
      "unknownError": "An unknown error occurred. Please try again later."
    },
    "confirmLink": {
      "heroSecondary": "And tell the cashier your phone number.",
      "heroText": "Just show up at checkout.",
      "seeBalanceButton": "See Your Gift Card Balance"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way."
        },
        "singleRecipient": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent to {recipientEmail} on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way to {recipientEmail}."
        }
      },
      "continueShopping": "Continue shopping",
      "giftFutureDescription": "Your {amount} eGift Card will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "giftInstantDescription": "Your {amount} eGift is on its way to {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Back to group gift"
      },
      "groupOrder": {
        "confirmationMessageEnd": " to the gift card. A confirmation email will be sent to {emailAddress}",
        "confirmationMessageStart": "You’ve added ",
        "groupGiftStarted": "Group gift started!",
        "viewGroupGift": "View group gift"
      },
      "orderComplete": "Order Complete",
      "orderFutureDescription": "Your {amount} voucher will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "orderInstantDescription": "Your {amount} voucher is on its way to {recipientName} ({recipientContact}).",
      "orderUpdated": "Order Updated",
      "reloadComplete": "Reload Complete",
      "sendAnother": "Send Another eGift Card",
      "sendAnotherGift": "Send another gift"
    },
    "date": {
      "formatPlaceholder": "DD/MM/YYYY",
      "nextMonth": "Next Month",
      "previousMonth": "Previous Month"
    },
    "discount": {
      "codeInvalid": "This discount code is invalid.",
      "discountCode": "Discount Code",
      "discountPricing": "Discount Pricing: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filter by"
      },
      "footer": {
        "aProjectBy": "A project by"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Local businesses need your support. You can give your favourite Square merchants a boost by buying digital gift cards.",
          "voucher": "Local businesses need your support. Give your favourite Square merchants a boost by buying vouchers to send as gifts or use for yourself."
        },
        "giftCardAsteriskAltText": "Gift card with colourful asterisk design"
      },
      "hero": {
        "subtitle": "Buy an eGift Card now, use it later and help a local business you love.",
        "title": "Support your favourite businesses"
      },
      "locationSelector": {
        "currentLocation": "Current location",
        "label": "Find Square sellers near you",
        "placeholder": "Enter address or city",
        "separator": "or",
        "useCurrentLocation": "Use my current location"
      },
      "merchantCard": {
        "buyButton": "Buy gift card"
      },
      "results": {
        "noResults": "No results"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Thanks for your support! Help us spread the word."
        },
        "notFromDirectory": {
          "description": "Check out Give & Get Local to find businesses offering eGift Cards, pickup, delivery and more.",
          "title": "Support your favourite businesses"
        },
        "shareOnFacebook": "Share on Facebook",
        "shareOnTwitter": "Share on Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Gift Card Theme Selector",
      "carouselRole": "carousel",
      "customImageFallback": "Seller’s custom image",
      "nextButtonDescription": "Next Theme",
      "previousButtonDescription": "Previous Theme",
      "slideRole": "slide"
    },
    "errors": {
      "invalidEmail": "This doesn't look like a valid email address.",
      "invalidPhone": "This doesn't look like a valid phone number.",
      "requiredField": "This is a required field."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Give & Get Local Terms",
      "privacyPolicy": "Privacy Policy",
      "termsOfService": "Terms of Service",
      "text": "Powered by <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Square Gift Cards</a>"
    },
    "form": {
      "continue": "Continue",
      "failedLabel": "Failed",
      "optionalLabel": "Optional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Add Personal Message",
      "amount": "Amount",
      "contributionAmount": "Contribution amount",
      "contributorEmailAddresses": "Contributors email addresses ({max} people max., separated by commas)",
      "custom": "Custom",
      "customAmount": "Custom amount",
      "customAmountBoundsValidation": "eGift amount must be between {min} and {max}.",
      "customAmountPlaceholder": "{min} to {max}",
      "customAmountRequiredValidation": "Please enter a custom eGift amount or select an option.",
      "delivery": "Delivery",
      "deliveryDate": "Delivery date",
      "deliveryDateValidation": "Please enter a date within the next 60 days.",
      "deliveryDetailsHeader": "Delivery details",
      "deliveryTime": "Delivery time",
      "eGiftCardAmount": "eGift card amount",
      "emailAddress": "Email address",
      "emailAddresses": "Email addresses (separated by commas)",
      "emailAddressesRequiredValidation": "Email addresses are required",
      "giftTypeHeader": "What kind of gift is it?",
      "groupDesc": "Pool money from multiple contributors for one recipient",
      "groupHeader": "Group gift card",
      "individualDesc": "Send a gift card to one recipient",
      "individualDescWithBulk": "Send a gift card to one recipient or make a bulk purchase",
      "individualHeader": "For one individual",
      "invalidEmailAddressValidation": "Invalid email {email}",
      "invitationMessage": "Invitation message",
      "invitationMessageTooLong": "The maximum length for invitation message is 400 characters.",
      "inviteContributorsHeader": "Invite contributors (optional)",
      "inviteContributorsSubtitle": "Send an email inviting others to contribute to this gift. You’ll also get a share link after checkout.",
      "maxEmailAddressesValidation": "Can’t have more than {max} emails in a single order",
      "maxQuantityValidation": "Quantity can’t be greater than {max}.",
      "minEmailAddressesValidation": "Must enter at least {min} emails",
      "multipleDesc": "Send gift cards to multiple people all at once",
      "multipleHeader": "For multiple people",
      "name": "Name",
      "personalMessage": "Personal Message",
      "personalMessageOptional": "Personal message (optional)",
      "personalMessageTooLong": "The maximum length for personal message is 200 characters.",
      "quantity": "Quantity",
      "quantityValidation": "Quantity must be between {min} and {max}",
      "recipientEmail": "Recipient email address",
      "recipientName": "Recipient name",
      "recipientNamePlaceholder": "Recipient name",
      "recipientQuantityPlaceholder": "1 per recipient",
      "sendAsAGift": "Send this voucher as a gift",
      "sendInstantly": "Send Instantly",
      "sendOnAFutureDate": "Send On A Future Date",
      "sendToMyself": "Send this card to myself",
      "sendToSelf": "I want to send this to myself",
      "suggestedContributionAmount": "Suggested amount to contribute",
      "timeZone": "Time zone",
      "yourDetailsHeader": "Your details",
      "yourEmail": "Your Email",
      "yourEmailAddress": "Your email address",
      "yourName": "Your name",
      "yourNamePlaceholder": "Your name"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Send a gift instantly in any amount you choose",
        "voucher": "Get a voucher for yourself or gift one to a friend"
      },
      "heroText": "Give the Perfect Gift"
    },
    "giftMessage": {
      "custom": "“{customMessage}”",
      "from": "From",
      "messageFrom": "Message from {senderEmail}",
      "nameNotAvailable": "name not available",
      "to": "To"
    },
    "giftSummarySidebar": {
      "customMessage": "“{customMessage}”",
      "deliveryFailed": "Delivery failed: {recipient}",
      "discountsApplied": "Discount applied",
      "emailAddressUnavailable": "Email address unavailable",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} more",
      "newBalance": "Your new balance will be {newBalance}",
      "orderDetails": "Order Details",
      "payAmount": "Amount you pay",
      "promotionApplied": "Promotion applied",
      "valueDoesNotExpire": "Value does not expire.",
      "viewAllRecipients": "View all recipients"
    },
    "group": {
      "giftDetailsHeader": "Gift details",
      "loadFunds": "Load funds",
      "organizedBy": "Organised by",
      "suggestedContributionDesc": "The organiser suggested to contribute {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Your name, email and message will be shared with the recipient.",
      "header": "Contributors",
      "viewAll": "View all"
    },
    "header": {
      "checkBalanceLink": "Need to check your gift card balance?",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Order an eGift Card"
    },
    "headerV2": {
      "checkBalanceLink": "Check balance",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Buy gift card",
      "reloadPageLink": "Reload card"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Clear",
        "deduct": "Funds deducted",
        "load": "Funds loaded",
        "redeem": "Purchase",
        "refund": "Refund",
        "transferBalanceFrom": "Transfer to {giftCardDisplay}",
        "transferBalanceTo": "Transfer from {giftCardDisplay}",
        "unknown": "Unknown"
      }
    },
    "link": {
      "heroSecondary": "Set up your card with a phone number.",
      "heroText": "Leave your gift card at home.",
      "noThanks": "No thanks, I'll just use the 16 digit code.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "An error occurred while updating your phone number."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Shop online"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Save",
        "header": "Add email address",
        "subheader": "Leave your gift card at home and tell the cashier your email address."
      },
      "addEditPhoneNumber": {
        "buttonText": "Save",
        "header": "Add phone number",
        "subheader": "Leave your gift card at home and tell the cashier your phone number."
      },
      "recipientEmails": {
        "eGiftRecipients": "eGift Card Recipients"
      }
    },
    "pageNotFound": "Sorry, this eGift Card page does not exist or has been disabled.",
    "pageTitle": {
      "checkBalance": "Check Balance | {merchantName} Gift Cards",
      "default": "{merchantName} eGift Cards",
      "discover": "Support Your Local Businesses With Square eGift Cards Today!",
      "group": "Group | {merchantName} eGift Cards",
      "linkPhone": "Link Phone Number | {merchantName} eGift Cards",
      "order": "Order | {merchantName} eGift Cards",
      "print": "Print | {merchantName} eGift Cards",
      "reload": "Reload | {merchantName} eGift Cards",
      "resend": "Resend | {merchantName} eGift Cards"
    },
    "payment": {
      "confirmDetails": "Confirm your order details",
      "confirmDetailsWithPayment": "Confirm your order details and enter your billing information",
      "error": {
        "compliance": {
          "dailyLimit": "Order exceeds daily gift card compliance limit."
        },
        "paymentDeclined": "The payment was declined. Please try a different credit card."
      },
      "heroText": "Checkout"
    },
    "paymentForm": {
      "buyerBeware": "By completing this purchase, I acknowledge that I am liable for any loss associated with the gift card purchase in the event that the seller is unable to fulfil the goods or services.",
      "disclaimer": {
        "default": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>.",
        "giveAndGetLocal": "By purchasing an eGift Card you agree to Square’s <a href=\"{localizedTermsUrl}\" target=\"_blank\">Give & Get Local Terms</a>, <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a>, and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>."
      },
      "errors": {
        "unauthorized": "Your payment card could not be authorised"
      },
      "payWithCard": "Pay with Card"
    },
    "paymentInfoForm": {
      "placeOrder": "Place Order"
    },
    "printEgift": {
      "themeAltText": "eGift Card Theme"
    },
    "promotion": {
      "applyPromotionCode": "Apply",
      "codeInvalid": "This promotion code is invalid.",
      "customHelpText": "* The {percentage}% discount applies to values {minimumLoad} and above.",
      "discountCodeApplied": "The discount code ‘{code}’ has been applied.",
      "discountCodeIgnored": "Your current discount is better than ‘{ignoredCode}’.",
      "fixedDiscount": "{discount} off",
      "fixedDiscountExpires": "{discount} off until {expirationDate}",
      "fixedDiscountMinLoad": "{discount} off items {minimumLoad} and above",
      "fixedDiscountMinLoadExpires": "{discount} off items {minimumLoad} and above until {expirationDate}",
      "payDiscountedPrice": "Pay {price}",
      "percentageDiscount": "{percentage}% off",
      "percentageDiscountExpires": "{percentage}% off until {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage}% off (maximum discount {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage}% off through {expirationDate} (maximum discount {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage}% off items {minimumLoad} and above",
      "percentageDiscountMinLoadExpires": "{percentage}% off items {minimumLoad} and above until {expirationDate}",
      "promotionCode": "Promotion Code",
      "promotionCodePlaceholder": "MYDISCOUNTCODE",
      "promotionalPricing": "Promotional Pricing: {description}",
      "youPayPrice": "You pay {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Add email address",
      "addPhoneNumber": "Add phone number",
      "editPhoneNumber": "Edit"
    },
    "reload": {
      "balance": "Card Balance: {balance}",
      "checkBalance": "Check Balance",
      "description": "Add money to your gift card.",
      "discountInput": "Add Discount Code",
      "promoInput": "Add Promo Code",
      "subheader": "Amount to add to card",
      "submit": "Continue"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Reload Details"
    },
    "resend": {
      "heroSecondary": "Your gift failed to be delivered. Please update the recipient's email address.",
      "heroText": "Update Email Address"
    },
    "routeError": {
      "giftCardNotFound": "Gift card is invalid or has not yet been activated."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " to the gift card. A confirmation text will be sent to {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{The email address you entered is invalid} other{One or more email addresses entered is invalid}}. Please enter a different email address.",
        "inTotal": {
          "perDay": "You cannot send more than four gift cards per day by text. Please send by email instead.",
          "perMonth": "You cannot send more than 20 gift cards per month by text. Please send by email instead."
        },
        "minAmount": "eGift cards sent by text must be $5 or more",
        "samePhoneNumber": {
          "perDay": "You cannot send more than two gift cards per day to the same phone number. Please send by email instead.",
          "perMonth": "You cannot send more than four gift cards per month to the same phone number. Please send by email instead."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Contributor’s phone number ({max} people max, separated by commas)",
        "invalidPhoneNumberValidation": "Invalid phone number {phoneNumber}",
        "maxPhoneNumbersValidation": "Cannot have more than {max} phone numbers in a single order",
        "minPhoneNumbersValidation": "Must enter at least {min} phone numbers",
        "phoneNumber": "Phone number",
        "phoneNumbers": "Phone numbers (separated by commas)",
        "phoneNumbersRequiredValidation": "Phone numbers are required",
        "recipientPhoneNumber": "Recipient phone number",
        "yourPhoneNumber": "Your phone number"
      },
      "giftMessage": {
        "messageFrom": "Message from {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Phone number unavailable"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Your name, phone number and message will be shared with the recipient."
      },
      "legalDisclaimer": "By selecting Checkout, you confirm that we may send a one-time text message to the mobile phone number entered above in order to deliver your eGift card. Please contact {sellerName} directly for details about their privacy practices. Messages and data rates apply.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Add phone number",
          "subheader": "Leave your gift card at home and tell the cashier your phone number."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "eGift Card Recipients"
        }
      },
      "resend": {
        "buttonText": "Resend eGift card",
        "heroSecondary": "Your gift failed to be delivered. Please update the recipient’s contact information.",
        "heroText": "Update delivery details"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Send by phone number"
        }
      },
      "todayAt": "Today at {time}",
      "todayInstantly": "Today (instantly)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Recipient’s phone number",
        "confirmationValidation": "Phone numbers do not match.",
        "originalContactInfo": "Original contact info",
        "originalPhoneNumber": "Original phone number",
        "phoneNumberUnavailable": "Phone number unavailable",
        "recipientPhoneNumber": "Recipient’s phone number"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Provide your phone number at the time of checkout.",
        "phone": "Phone number"
      }
    },
    "shareGroupGift": {
      "shareLink": "Share link",
      "shareWithOthers": "Share the link with others so they can join in on the group gift."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Send by email",
        "sendByText": "Send by text"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Send to Multiple Recipients",
      "sendToOne": "Send to One Recipient"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Recipient’s email address",
      "confirmationValidation": "Emails do not match.",
      "emailAddressUnavailable": "email address unavailable",
      "originalEmail": "Original Email",
      "recipientEmailAddress": "Recipient’s email address",
      "updateOrder": "Update Order"
    },
    "view": {
      "addMoreFunds": "Add more funds",
      "transactionHistory": "Transaction History",
      "waysToRedeem": "Ways To Redeem"
    },
    "viewPageHeader": {
      "currentBalance": "Current balance",
      "greeting": "Hi {recipientName}, {senderName} sent you an eGift card!",
      "groupGreeting": "A gift for {recipientName}!",
      "groupViewGreeting": "Hi {recipientName}, your friends sent you an eGift card!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Add to your Square profile",
      "barCode": "Barcode",
      "code": "Code",
      "codeInstructions": "Show this code to the cashier.",
      "email": "Email",
      "giftCardCode": "Gift card code",
      "instructions": "Scan or enter the code to redeem the gift card.",
      "methodChoiceSeparator": "or",
      "otherHeader": "Other ways to redeem in-store",
      "otherInstructions": "Provide your phone number or email address at the time of checkout.",
      "otherInstructionsOnlyEmail": "Provide your email address at the time of checkout.",
      "phone": "Phone Number",
      "phoneActionText": "Change Card's Phone Number",
      "qrCode": "QR code",
      "redeemOnline": "Redeem online",
      "redeemOnlineInstructions": "Enter the gift card code during checkout.",
      "squareProfileDescription": "Square profile helps you view and manage all your gift cards from different Square businesses"
    }
  }], ["en-ie", {
    "addressInput": {
      "loading": "Loading...",
      "noResults": "No results."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Beauty & personal care",
      "charitiesEducationAndMembership": "Charities, education & membership",
      "foodAndDrink": "Food & drink",
      "healthCareAndFitness": "Healthcare & fitness",
      "homeAndRepair": "Home & repair",
      "leisureAndEntertainment": "Leisure & entertainment",
      "other": "Other",
      "professionalServices": "Professional services",
      "retail": "Retail",
      "transportation": "Transportation"
    },
    "businessCategoryFilters": {
      "hideFilters": "Hide filters",
      "showFilters": "Show filters"
    },
    "checkBalance": {
      "checkboxLabel": "My gift card does not have a PIN",
      "description": "Enter your gift card number to check your balance.",
      "errors": {
        "notFound": "That gift card could not be found.",
        "rateLimit": "Too many attempts. Please try again in a moment."
      },
      "label": "Gift Card Number",
      "pinLabel": "PIN",
      "submit": "Check Balance",
      "tooltip": "The PIN is located on the back of the gift card, next to the gift card number.",
      "tooltipPinRequired": "The PIN is located on the back of the gift card, next to the gift card number. If your gift card does not have a PIN, please contact the merchant to check your balance."
    },
    "clipboard": {
      "copyLink": "Copy link",
      "linkCopied": "Link copied!"
    },
    "common": {
      "edit": "Edit",
      "next": "Next",
      "unknownError": "An unknown error occurred. Please try again later."
    },
    "confirmLink": {
      "heroSecondary": "And tell the cashier your phone number.",
      "heroText": "Just show up at checkout.",
      "seeBalanceButton": "See Your Gift Card Balance"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way."
        },
        "singleRecipient": {
          "futureDescription": "Your order for {quantity} x {amount} eGift Cards will be sent to {recipientEmail} on {formattedDate}.",
          "instantDescription": "Your order for {quantity} x {amount} eGift Cards is on its way to {recipientEmail}."
        }
      },
      "continueShopping": "Continue shopping",
      "giftFutureDescription": "Your {amount} eGift Card will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "giftInstantDescription": "Your {amount} eGift is on its way to {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Back to group gift"
      },
      "groupOrder": {
        "confirmationMessageEnd": " to the gift card. An email confirmation will be sent to {emailAddress}",
        "confirmationMessageStart": "You’ve added ",
        "groupGiftStarted": "Group gift started!",
        "viewGroupGift": "View group gift"
      },
      "orderComplete": "Order Complete",
      "orderFutureDescription": "Your {amount} voucher will be sent to {recipientName} ({recipientContact}) on {formattedDate}.",
      "orderInstantDescription": "Your {amount} voucher is on its way to {recipientName} ({recipientContact}).",
      "orderUpdated": "Order Updated",
      "reloadComplete": "Reload Complete",
      "sendAnother": "Send Another eGift Card",
      "sendAnotherGift": "Send another gift"
    },
    "date": {
      "formatPlaceholder": "DD/MM/YYYY",
      "nextMonth": "Next Month",
      "previousMonth": "Previous Month"
    },
    "discount": {
      "codeInvalid": "This discount code is invalid.",
      "discountCode": "Discount Code",
      "discountPricing": "Discount Pricing: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filter by"
      },
      "footer": {
        "aProjectBy": "A project by"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Local businesses need your support. You can give your favourite Square merchants a boost by buying digital gift cards.",
          "voucher": "Local businesses need your support. Give your favourite Square merchants a boost by buying vouchers to send as gifts or use for yourself."
        },
        "giftCardAsteriskAltText": "Gift card with colourful asterisk design"
      },
      "hero": {
        "subtitle": "Buy an eGift Card now, use it later, and help a local business you love.",
        "title": "Support your favourite businesses"
      },
      "locationSelector": {
        "currentLocation": "Current location",
        "label": "Find Square sellers near you",
        "placeholder": "Enter address or city",
        "separator": "or",
        "useCurrentLocation": "Use my current location"
      },
      "merchantCard": {
        "buyButton": "Buy gift card"
      },
      "results": {
        "noResults": "No results"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Thanks for your support! Help us spread the word."
        },
        "notFromDirectory": {
          "description": "Check out Give & Get Local to find businesses offering eGift Cards, pickup, delivery and more.",
          "title": "Support your favourite businesses"
        },
        "shareOnFacebook": "Share on Facebook",
        "shareOnTwitter": "Share on Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Gift Card Theme Selector",
      "carouselRole": "carousel",
      "customImageFallback": "Seller's custom image",
      "nextButtonDescription": "Next Theme",
      "previousButtonDescription": "Previous Theme",
      "slideRole": "slide"
    },
    "errors": {
      "invalidEmail": "This doesn’t look like a valid email address.",
      "invalidPhone": "This doesn’t look like a valid phone number.",
      "requiredField": "This is a required field."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Give & Get Local Terms",
      "privacyPolicy": "Privacy Policy",
      "termsOfService": "Terms of Service",
      "text": "Powered by <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Square Gift Cards</a>"
    },
    "form": {
      "continue": "Continue",
      "failedLabel": "Failed",
      "optionalLabel": "Optional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Add Personal Message",
      "amount": "Amount",
      "contributionAmount": "Contribution amount",
      "contributorEmailAddresses": "Contributors email addresses ({max} people max, separated by commas)",
      "custom": "Custom",
      "customAmount": "Custom amount",
      "customAmountBoundsValidation": "eGift amount must be between {min} and {max}.",
      "customAmountPlaceholder": "{min} to {max}",
      "customAmountRequiredValidation": "Please enter a custom eGift amount or select an option.",
      "delivery": "Delivery",
      "deliveryDate": "Delivery date",
      "deliveryDateValidation": "Please enter a date within the next 60 days.",
      "deliveryDetailsHeader": "Delivery details",
      "deliveryTime": "Delivery time",
      "eGiftCardAmount": "eGift card amount",
      "emailAddress": "Email address",
      "emailAddresses": "Email addresses (separated by commas)",
      "emailAddressesRequiredValidation": "Email addresses are required",
      "giftTypeHeader": "What kind of gift is it?",
      "groupDesc": "Pool money from multiple contributors for one recipient",
      "groupHeader": "Group gift card",
      "individualDesc": "Send a gift card to one recipient",
      "individualDescWithBulk": "Send a gift card to one recipient or make a bulk purchase",
      "individualHeader": "For one individual",
      "invalidEmailAddressValidation": "Invalid email {email}",
      "invitationMessage": "Invitation message",
      "invitationMessageTooLong": "The maximum length for invitation message is 400 characters.",
      "inviteContributorsHeader": "Invite contributors (optional)",
      "inviteContributorsSubtitle": "Send an email inviting others to contribute to this gift. You’ll also get a share link after checkout.",
      "maxEmailAddressesValidation": "Cannot have more than {max} emails in a single order",
      "maxQuantityValidation": "Quantity cannot be greater than {max}.",
      "minEmailAddressesValidation": "Must enter at least {min} email addresses",
      "multipleDesc": "Send gift cards to multiple people all at once",
      "multipleHeader": "For multiple people",
      "name": "Name",
      "personalMessage": "Personal Message",
      "personalMessageOptional": "Personal message (optional)",
      "personalMessageTooLong": "The maximum length for personal message is 200 characters.",
      "quantity": "Quantity",
      "quantityValidation": "Quantity must be between {min} and {max}",
      "recipientEmail": "Recipient email address",
      "recipientName": "Recipient name",
      "recipientNamePlaceholder": "Recipient name",
      "recipientQuantityPlaceholder": "1 per recipient",
      "sendAsAGift": "Send this voucher as a gift",
      "sendInstantly": "Send Instantly",
      "sendOnAFutureDate": "Send On A Future Date",
      "sendToMyself": "Send this card to myself",
      "sendToSelf": "I want to send this to myself",
      "suggestedContributionAmount": "Suggested amount to contribute",
      "timeZone": "Time zone",
      "yourDetailsHeader": "Your details",
      "yourEmail": "Your Email",
      "yourEmailAddress": "Your email address",
      "yourName": "Your name",
      "yourNamePlaceholder": "Your name"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Send a gift instantly in any amount you choose",
        "voucher": "Get a voucher for yourself or gift one to a friend"
      },
      "heroText": "Give the Perfect Gift"
    },
    "giftMessage": {
      "custom": "&ldquo;{customMessage}&rdquo;",
      "from": "From",
      "messageFrom": "Message from {senderEmail}",
      "nameNotAvailable": "name not available",
      "to": "To"
    },
    "giftSummarySidebar": {
      "customMessage": "&ldquo;{customMessage}&rdquo;",
      "deliveryFailed": "Delivery failed: {recipient}",
      "discountsApplied": "Discount applied",
      "emailAddressUnavailable": "Email address unavailable",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} more",
      "newBalance": "Your new balance will be {newBalance}",
      "orderDetails": "Order Details",
      "payAmount": "Amount you pay",
      "promotionApplied": "Promotion applied",
      "valueDoesNotExpire": "Value does not expire.",
      "viewAllRecipients": "View all recipients"
    },
    "group": {
      "giftDetailsHeader": "Gift details",
      "loadFunds": "Load funds",
      "organizedBy": "Organised by",
      "suggestedContributionDesc": "The organiser suggested contributing {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Your name, email, and message will be shared with the recipient.",
      "header": "Contributors",
      "viewAll": "View all"
    },
    "header": {
      "checkBalanceLink": "Need to check your gift card balance?",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Order an eGift Card"
    },
    "headerV2": {
      "checkBalanceLink": "Check balance",
      "logoAltText": "{merchantName} Logo",
      "orderPageLink": "Buy gift card",
      "reloadPageLink": "Reload card"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Clear",
        "deduct": "Funds deducted",
        "load": "Funds loaded",
        "redeem": "Purchase",
        "refund": "Refund",
        "transferBalanceFrom": "Transfer to {giftCardDisplay}",
        "transferBalanceTo": "Transfer from {giftCardDisplay}",
        "unknown": "Unknown"
      }
    },
    "link": {
      "heroSecondary": "Set up your card with a phone number.",
      "heroText": "Leave your gift card at home.",
      "noThanks": "No thanks. I’ll just use the 16 digit code.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "An error occurred while updating your phone number."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Shop online"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Save",
        "header": "Add email address",
        "subheader": "Leave your gift card at home and tell the cashier your email address."
      },
      "addEditPhoneNumber": {
        "buttonText": "Save",
        "header": "Add phone number",
        "subheader": "Leave your gift card at home and tell the cashier your phone number."
      },
      "recipientEmails": {
        "eGiftRecipients": "eGift Card Recipients"
      }
    },
    "pageNotFound": "Sorry, this eGift Card page does not exist or has been disabled.",
    "pageTitle": {
      "checkBalance": "Check Balance | {merchantName} Gift Cards",
      "default": "{merchantName} eGift Cards",
      "discover": "Support Your Local Businesses With Square eGift Cards Today!",
      "group": "Group | {merchantName} eGift Cards",
      "linkPhone": "Link Phone Number | {merchantName} eGift Cards",
      "order": "Order | {merchantName} eGift Cards",
      "print": "Print | {merchantName} eGift Cards",
      "reload": "Reload | {merchantName} eGift Cards",
      "resend": "Resend | {merchantName} eGift Cards"
    },
    "payment": {
      "confirmDetails": "Confirm your order details",
      "confirmDetailsWithPayment": "Confirm your order details and enter your billing information",
      "error": {
        "compliance": {
          "dailyLimit": "Order exceeds daily gift card compliance limit."
        },
        "paymentDeclined": "The payment was declined. Please try a different credit card."
      },
      "heroText": "Checkout"
    },
    "paymentForm": {
      "buyerBeware": "By completing this purchase, I acknowledge that I am liable for any loss associated with the gift card purchase in the event that the seller is unable to fulfill the goods or services.",
      "disclaimer": {
        "default": "By purchasing an eGift Card, you agree to Square’s <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a> and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>.",
        "giveAndGetLocal": "By purchasing an eGift Card, you agree to Square’s <a href=\"{localizedTermsUrl}\" target=\"_blank\">Give & Get Local Terms</a>, <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Privacy Policy</a> and <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">E-Sign Consent</a>."
      },
      "errors": {
        "unauthorized": "Your payment card could not be authorised"
      },
      "payWithCard": "Pay with Card"
    },
    "paymentInfoForm": {
      "placeOrder": "Place Order"
    },
    "printEgift": {
      "themeAltText": "eGift Card Theme"
    },
    "promotion": {
      "applyPromotionCode": "Apply",
      "codeInvalid": "This promotion code is invalid.",
      "customHelpText": "* The {percentage}% discount applies to values {minimumLoad} and above.",
      "discountCodeApplied": "The discount code  ‘{code}’ has been applied.",
      "discountCodeIgnored": "Your current discount is better than ‘{ignoredCode}’.",
      "fixedDiscount": "{discount} off",
      "fixedDiscountExpires": "{discount} off until {expirationDate}",
      "fixedDiscountMinLoad": "{discount} off items {minimumLoad} and above",
      "fixedDiscountMinLoadExpires": "{discount} off items {minimumLoad} and above until {expirationDate}",
      "payDiscountedPrice": "Pay {price}",
      "percentageDiscount": "{percentage}% off",
      "percentageDiscountExpires": "{percentage}% off until {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage}% off (maximum discount {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage}% off until {expirationDate} (maximum discount {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage}% off items {minimumLoad} and above",
      "percentageDiscountMinLoadExpires": "{percentage}% off items {minimumLoad} and above until {expirationDate}",
      "promotionCode": "Promotion Code",
      "promotionCodePlaceholder": "MYDISCOUNTCODE",
      "promotionalPricing": "Promotional Pricing: {description}",
      "youPayPrice": "You pay {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Add email address",
      "addPhoneNumber": "Add phone number",
      "editPhoneNumber": "Edit"
    },
    "reload": {
      "balance": "Card Balance: {balance}",
      "checkBalance": "Check Balance",
      "description": "Add money to your gift card.",
      "discountInput": "Add Discount Code",
      "promoInput": "Add Promo Code",
      "subheader": "Amount to add to card",
      "submit": "Continue"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Reload Details"
    },
    "resend": {
      "heroSecondary": "Your gift failed to be delivered. Please update the recipient’s email address.",
      "heroText": "Update Email Address"
    },
    "routeError": {
      "giftCardNotFound": "Gift card is invalid or has not yet been activated."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " to the gift card. A confirmation text will be sent to {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{The email address you entered is invalid} other{One or more email addresses entered is invalid}}. Please enter a different email address.",
        "inTotal": {
          "perDay": "You can’t send more than 4 gift cards per day by text. Please send by email instead.",
          "perMonth": "You can’t send more than 20 gift cards per month by text. Please send by email instead."
        },
        "minAmount": "EGift cards sent by text must be $5 or more",
        "samePhoneNumber": {
          "perDay": "You can’t send more than 2 gift cards per day to the same phone number. Please send by email instead.",
          "perMonth": "You can’t send more than 4 gift cards per month to the same phone number. Please send by email instead."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Contributors phone number ({max} people max, separated by commas)",
        "invalidPhoneNumberValidation": "Invalid phone number {phoneNumber}",
        "maxPhoneNumbersValidation": "Can’t have more than {max} phone numbers in a single order",
        "minPhoneNumbersValidation": "Must enter at least {min} phone numbers",
        "phoneNumber": "Phone number",
        "phoneNumbers": "Phone numbers (separated by commas)",
        "phoneNumbersRequiredValidation": "Phone numbers are required",
        "recipientPhoneNumber": "Recipient phone number",
        "yourPhoneNumber": "Your phone number"
      },
      "giftMessage": {
        "messageFrom": "Message from {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Phone number unavailable"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Your name, phone number and message will be shared with the recipient."
      },
      "legalDisclaimer": "By selecting Checkout, you confirm that we may send a one-time text message to the mobile phone number entered above in order to deliver your eGift card. Please contact {sellerName} directly for details about their privacy practices. Messages and data rates apply.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Add phone number",
          "subheader": "Leave your gift card at home and tell the cashier your phone number."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "eGift Card Recipients"
        }
      },
      "resend": {
        "buttonText": "Resend eGift card",
        "heroSecondary": "Your gift failed to be delivered. Please update the recipient’s contact information.",
        "heroText": "Update delivery details"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Send by phone number"
        }
      },
      "todayAt": "Today at {time}",
      "todayInstantly": "Today (instantly)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Recipient’s phone number",
        "confirmationValidation": "Phone numbers don’t match.",
        "originalContactInfo": "Original contact info",
        "originalPhoneNumber": "Original phone number",
        "phoneNumberUnavailable": "Phone number unavailable",
        "recipientPhoneNumber": "Recipient’s phone number"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Provide your phone number when checking out.",
        "phone": "Phone number"
      }
    },
    "shareGroupGift": {
      "shareLink": "Share link",
      "shareWithOthers": "Share the link with others so they can join in on the group gift."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Send by email",
        "sendByText": "Send by text"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Send to Multiple Recipients",
      "sendToOne": "Send to One Recipient"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Recipient’s email address",
      "confirmationValidation": "Emails do not match.",
      "emailAddressUnavailable": "email address unavailable",
      "originalEmail": "Original Email",
      "recipientEmailAddress": "Recipient’s email address",
      "updateOrder": "Update Order"
    },
    "view": {
      "addMoreFunds": "Add more funds",
      "transactionHistory": "Transaction History",
      "waysToRedeem": "Ways To Redeem"
    },
    "viewPageHeader": {
      "currentBalance": "Current balance",
      "greeting": "Hi {recipientName}, {senderName} sent you an eGift card!",
      "groupGreeting": "A gift for {recipientName}!",
      "groupViewGreeting": "Hi {recipientName}, your friends sent you an eGift card!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Add to your Square profile",
      "barCode": "Barcode",
      "code": "Code",
      "codeInstructions": "Show this code to the cashier.",
      "email": "Email",
      "giftCardCode": "Gift card code",
      "instructions": "Scan or enter the code to redeem the gift card.",
      "methodChoiceSeparator": "or",
      "otherHeader": "Other ways to redeem in-store",
      "otherInstructions": "Provide your phone number or email address at the time of checkout.",
      "otherInstructionsOnlyEmail": "Provide your email address at the time of checkout.",
      "phone": "Phone Number",
      "phoneActionText": "Change Card’s Phone Number",
      "qrCode": "QR code",
      "redeemOnline": "Redeem online",
      "redeemOnlineInstructions": "Enter the gift card code during checkout.",
      "squareProfileDescription": "Square profile helps you view and manage all your gift cards from different Square businesses"
    }
  }], ["es", {
    "addressInput": {
      "loading": "Cargando...",
      "noResults": "Sin resultados."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Belleza y cuidado personal",
      "charitiesEducationAndMembership": "Organizaciones benéficas, educativas o asociaciones",
      "foodAndDrink": "Alimentos y bebidas",
      "healthCareAndFitness": "Salud y club deportivo",
      "homeAndRepair": "Servicios de reparación del hogar",
      "leisureAndEntertainment": "Recreación y entretenimiento",
      "other": "Otro",
      "professionalServices": "Servicios profesionales",
      "retail": "Minorista",
      "transportation": "Transporte"
    },
    "businessCategoryFilters": {
      "hideFilters": "Ocultar filtros",
      "showFilters": "Mostrar filtros"
    },
    "checkBalance": {
      "checkboxLabel": "Mi tarjeta de regalo no tiene un PIN",
      "description": "Ingresa el número de tu tarjeta de regalo para verificar tu saldo.",
      "errors": {
        "notFound": "No se pudo encontrar la tarjeta de regalo.",
        "rateLimit": "Demasiados intentos. Intenta de nuevo en un momento."
      },
      "label": "Número de tarjeta de regalo",
      "pinLabel": "Número de identificación personal (PIN)",
      "submit": "Comprobar saldo",
      "tooltip": "El número de identificación personal (PIN) se ubica en el dorso de la tarjeta de regalo, junto al número de dicha tarjeta.",
      "tooltipPinRequired": "El número de identificación personal (PIN) se ubica en el dorso de la tarjeta de regalo, junto al número de dicha tarjeta. Si tu tarjeta de regalo no tiene un PIN, comunícate con el comercio para verificar tu saldo."
    },
    "clipboard": {
      "copyLink": "Copiar enlace",
      "linkCopied": "¡Enlace copiado!"
    },
    "common": {
      "edit": "Editar",
      "next": "Siguiente",
      "unknownError": "Ocurrió un error desconocido. Intenta de nuevo más tarde."
    },
    "confirmLink": {
      "heroSecondary": "Y dile tu número de teléfono al cajero.",
      "heroText": "Solo pasa por la caja.",
      "seeBalanceButton": "Ver saldo de la tarjeta de regalo"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Se envió tu pedido de {quantity} tarjetas de regalo electrónicas x {amount} el {formattedDate}.",
          "instantDescription": "Se envió tu pedido de {quantity} tarjetas de regalo electrónicas x {amount}."
        },
        "singleRecipient": {
          "futureDescription": "Se envió tu pedido de {quantity} tarjetas de regalo electrónicas x {amount} a {recipientEmail} el {formattedDate}.",
          "instantDescription": "Se envió tu pedido de {quantity} tarjetas de regalo electrónicas x {amount} a {recipientEmail}."
        }
      },
      "continueShopping": "Continuar a comprar",
      "giftFutureDescription": "La tarjeta de regalo electrónica de {amount} se enviará a {recipientName} ({recipientContact}) el {formattedDate}.",
      "giftInstantDescription": "Se envió la tarjeta de regalo electrónica de {amount} a {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Regresar a la tarjeta de grupo"
      },
      "groupOrder": {
        "confirmationMessageEnd": "a la tarjeta de regalo. Se enviará una confirmación por correo electrónico a {emailAddress}",
        "confirmationMessageStart": "Has agregado",
        "groupGiftStarted": "Tarjeta de grupo iniciada.",
        "viewGroupGift": "Ver tarjeta de grupo"
      },
      "orderComplete": "Pedido completo",
      "orderFutureDescription": "El vale de {amount} se enviará a {recipientName} ({recipientContact}) el {formattedDate}.",
      "orderInstantDescription": "Se envió el vale de {amount} a {recipientName} ({recipientContact}).",
      "orderUpdated": "Pedido actualizado",
      "reloadComplete": "Recarga completa",
      "sendAnother": "Enviar otra tarjeta de regalo electrónica",
      "sendAnotherGift": "Enviar otro regalo"
    },
    "date": {
      "formatPlaceholder": "MM/DD/AAAA",
      "nextMonth": "Mes entrante",
      "previousMonth": "Mes anterior"
    },
    "discount": {
      "codeInvalid": "Este código de descuento no es válido.",
      "discountCode": "Código de descuento",
      "discountPricing": "Precio de descuento: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filtrar por"
      },
      "footer": {
        "aProjectBy": "Un proyecto de"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Local de entregas y recepciones"
        },
        "description": {
          "giftCards": "Los negocios locales necesitan tu ayuda. Puedes ayudar a tus comercios preferidos de Square comprando tarjetas de regalo electrónicas.",
          "voucher": "Los negocios locales necesitan tu ayuda. Ayuda a tus comercios preferidos de Square comprando vales para enviar como regalo o para usar tú mismo."
        },
        "giftCardAsteriskAltText": "Tarjeta de regalo con diseño de asteriscos de colores"
      },
      "hero": {
        "subtitle": "Compra una tarjeta de regalo electrónica ahora, úsala luego, y ayuda a un comercio local de tu preferencia.",
        "title": "Apoya a tu negocio preferido"
      },
      "locationSelector": {
        "currentLocation": "Ubicación actual",
        "label": "Encuentra vendedores de Square cerca de ti",
        "placeholder": "Ingresa la dirección o la ciudad",
        "separator": "o",
        "useCurrentLocation": "Usar mi ubicación actual"
      },
      "merchantCard": {
        "buyButton": "Comprar tarjeta de regalo"
      },
      "results": {
        "noResults": "Sin resultados"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Gracias por tu apoyo. Ayúdanos a compartir la información."
        },
        "notFromDirectory": {
          "description": "Revisa Give and Get Local para encontrar negocios que ofrecen tarjetas de regalo electrónicas, retiros, entregas y más.",
          "title": "Apoya a tu negocio preferido"
        },
        "shareOnFacebook": "Compartir en Facebook",
        "shareOnTwitter": "Compartir en Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Selector de tema de tarjeta de regalo",
      "carouselRole": "carrusel",
      "customImageFallback": "Imagen personalizada del vendedor",
      "nextButtonDescription": "Próximo tema",
      "previousButtonDescription": "Tema anterior",
      "slideRole": "deslizar"
    },
    "errors": {
      "invalidEmail": "No parece una dirección de correo electrónico válida.",
      "invalidPhone": "No parece un número de teléfono válido.",
      "requiredField": "Este es un campo requerido."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Condiciones locales de entregas y recepciones",
      "privacyPolicy": "Política de Privacidad",
      "termsOfService": "Condiciones de servicio",
      "text": "Impulsado por <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Tarjetas de regalo Square</a>"
    },
    "form": {
      "continue": "Continuar",
      "failedLabel": "Error",
      "optionalLabel": "Opcional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Agregar mensaje personal",
      "amount": "Importe",
      "contributionAmount": "Importe de contribución",
      "contributorEmailAddresses": "Direcciones de correo electrónico de los colaboradores ({max} personas como máximo, separadas por comas)",
      "custom": "Personalizado",
      "customAmount": "Importe personalizado",
      "customAmountBoundsValidation": "El monto del regalo electrónico debe ser entre {min} y {max}.",
      "customAmountPlaceholder": "{min} a {max}",
      "customAmountRequiredValidation": "Ingresa un monto de regalo electrónico personalizado o selecciona una opción.",
      "delivery": "Entrega",
      "deliveryDate": "Fecha de entrega",
      "deliveryDateValidation": "Ingresa una fecha dentro de los próximos 60 días.",
      "deliveryDetailsHeader": "Detalles de la entrega",
      "deliveryTime": "Hora de entrega",
      "eGiftCardAmount": "Monto de la tarjeta de regalo electrónica",
      "emailAddress": "Dirección de correo electrónico",
      "emailAddresses": "Direcciones de correo electrónico (separadas por comas)",
      "emailAddressesRequiredValidation": "Se requieren las direcciones de correo electrónico",
      "giftTypeHeader": "¿Qué tipo de regalo es?",
      "groupDesc": "Reunir el dinero de más de un contribuyente para un solo destinatario",
      "groupHeader": "Tarjeta de regalo de grupo",
      "individualDesc": "Enviar una tarjeta de regalo a un destinatario",
      "individualDescWithBulk": "Enviar una tarjeta de regalo a un destinatario o hacer una compra en grupo",
      "individualHeader": "Para un individuo",
      "invalidEmailAddressValidation": "Correo electrónico no válido {email}",
      "invitationMessage": "Mensaje de invitación",
      "invitationMessageTooLong": "Los mensajes de invitación tienen un límite máximo de 400 caracteres.",
      "inviteContributorsHeader": "Invitar contribuyentes (opcional)",
      "inviteContributorsSubtitle": "Envía un correo electrónico para invitar a otras personas a contribuir a este regalo. También recibirás un enlace para compartir después del proceso de pago.",
      "maxEmailAddressesValidation": "No puede haber más de {max} correos electrónicos en un solo pedido",
      "maxQuantityValidation": "La cantidad no puede ser superior a {max}.",
      "minEmailAddressesValidation": "Debes ingresar al menos {min} correos electrónicos",
      "multipleDesc": "Envía tarjetas de regalo a más de una persona a la vez",
      "multipleHeader": "Para más de una persona",
      "name": "Nombre",
      "personalMessage": "Mensaje personal",
      "personalMessageOptional": "Mensaje personal (opcional)",
      "personalMessageTooLong": "Los mensajes personales tienen una extensión máxima de 200 caracteres.",
      "quantity": "Cantidad",
      "quantityValidation": "La cantidad debe ser entre {min} y {max}",
      "recipientEmail": "Correo electrónico del destinatario",
      "recipientName": "Nombre del destinatario",
      "recipientNamePlaceholder": "Nombre del destinatario",
      "recipientQuantityPlaceholder": "1 por destinatario",
      "sendAsAGift": "Enviar este vale como regalo",
      "sendInstantly": "Enviar ahora",
      "sendOnAFutureDate": "Enviar más adelante",
      "sendToMyself": "Enviarme esta tarjeta",
      "sendToSelf": "Quiero enviarme esto a mí mismo",
      "suggestedContributionAmount": "Importe sugerido de contribución",
      "timeZone": "Zona horaria",
      "yourDetailsHeader": "Tu información",
      "yourEmail": "Tu correo electrónico",
      "yourEmailAddress": "Tu dirección de correo electrónico",
      "yourName": "Tu nombre",
      "yourNamePlaceholder": "Tu nombre"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Envía un regalo al instante por el monto que elijas",
        "voucher": "Obtén un vale para ti o regala uno a otra persona"
      },
      "heroText": "Haz el regalo perfecto"
    },
    "giftMessage": {
      "custom": "&ldquo;{customMessage}&rdquo;",
      "from": "De",
      "messageFrom": "Mensaje de {senderEmail}",
      "nameNotAvailable": "nombre no disponible",
      "to": "Para"
    },
    "giftSummarySidebar": {
      "customMessage": "&ldquo;{customMessage}&rdquo;",
      "deliveryFailed": "Entrega fallida: {recipient}",
      "discountsApplied": "Descuento aplicado",
      "emailAddressUnavailable": "Dirección de correo electrónico no disponible",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} más",
      "newBalance": "Tu nuevo saldo será {newBalance}",
      "orderDetails": "Información de pedido",
      "payAmount": "Importe que pagas",
      "promotionApplied": "Promoción aplicada",
      "valueDoesNotExpire": "El valor no expira.",
      "viewAllRecipients": "Ver todos los destinatarios"
    },
    "group": {
      "giftDetailsHeader": "Información de la tarjeta",
      "loadFunds": "Cargar fondos",
      "organizedBy": "Organizado por",
      "suggestedContributionDesc": "El organizador sugirió contribuir con {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Tu nombre, correo electrónico y mensaje se compartirán con el destinatario.",
      "header": "Contribuyentes",
      "viewAll": "Ver todos"
    },
    "header": {
      "checkBalanceLink": "¿Necesitas comprobar el saldo de tu tarjeta de regalo?",
      "logoAltText": "Logotipo de {merchantName} ",
      "orderPageLink": "Pide una tarjeta de regalo electrónica"
    },
    "headerV2": {
      "checkBalanceLink": "Comprobar saldo",
      "logoAltText": "Logotipo de {merchantName} ",
      "orderPageLink": "Comprar tarjeta de regalo",
      "reloadPageLink": "Recargar tarjeta"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Limpiar",
        "deduct": "Fondos deducidos",
        "load": "Fondos cargados",
        "redeem": "Comprar",
        "refund": "Reembolso",
        "transferBalanceFrom": "Transferir a {giftCardDisplay}",
        "transferBalanceTo": "Transferir de {giftCardDisplay}",
        "unknown": "Desconocido"
      }
    },
    "link": {
      "heroSecondary": "Configura la tarjeta con un número de teléfono.",
      "heroText": "Deja la tarjeta de regalo en casa.",
      "noThanks": "No, gracias. Usaré el código de 16 dígitos.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "Ocurrió un error al actualizar el número de teléfono."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Comprar en línea"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Guardar",
        "header": "Agrega tu dirección de correo electrónico",
        "subheader": "Deja tu tarjeta de regalo en casa y dile tu dirección de correo electrónico al cajero."
      },
      "addEditPhoneNumber": {
        "buttonText": "Guardar",
        "header": "Agregar número de teléfono",
        "subheader": "Deja tu tarjeta de regalo en casa y dile tu número de teléfono al cajero."
      },
      "recipientEmails": {
        "eGiftRecipients": "Destinatarios de tarjetas de regalo electrónicas"
      }
    },
    "pageNotFound": "Lamentablemente, esta página de tarjetas de regalo electrónicas no existe o se encuentra desactivada.",
    "pageTitle": {
      "checkBalance": "Comprobar saldo | Tarjetas de regalo de {merchantName}",
      "default": "Tarjetas de regalo electrónicas de {merchantName}",
      "discover": "Apoya a los negocios locales con tarjetas de regalo electrónicas hoy.",
      "group": "Grupo | Tarjetas de regalo electrónicas de {merchantName}",
      "linkPhone": "Vincular número de teléfono | Tarjetas de regalo electrónicas de {merchantName}",
      "order": "Pedido | Tarjetas de regalo electrónicas de {merchantName}",
      "print": "Imprimir | Tarjetas de regalo electrónicas de {merchantName}",
      "reload": "Recarga | Tarjetas de regalo electrónicas de {merchantName} ",
      "resend": "Reenviar | Tarjetas de regalo electrónicas de {merchantName}"
    },
    "payment": {
      "confirmDetails": "Confirmar la información del pedido",
      "confirmDetailsWithPayment": "Confirmar la información del pedido e ingresar la información de facturación",
      "error": {
        "compliance": {
          "dailyLimit": "El pedido excede el límite de cumplimiento diario de las tarjetas de regalo."
        },
        "paymentDeclined": "El pago fue rechazado. Ingresa una tarjeta de crédito diferente."
      },
      "heroText": "Proceso de pago"
    },
    "paymentForm": {
      "buyerBeware": "Al completar esta compra, confirmo que me hago responsable por toda pérdida relacionada con la compra de tarjetas de regalo en caso de que el vendedor no pueda cumplir con la entrega de bienes o servicios.",
      "disclaimer": {
        "default": "Si compras una tarjeta de regalo electrónica, aceptas la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Política de privacidad</a> y el <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Consentimiento de firma electrónica</a> de Square.",
        "giveAndGetLocal": "Si compras una tarjeta de regalo electrónica, aceptas las <a href=\"{localizedTermsUrl}\" target=\"_blank\">Condiciones locales de entregas y recepciones</a>, la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Política de privacidad</a> y el <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Consentimiento de firma electrónica</a> de Square."
      },
      "errors": {
        "unauthorized": "No se autorizó el pago con tarjeta"
      },
      "payWithCard": "Paga con tarjeta "
    },
    "paymentInfoForm": {
      "placeOrder": "Realizar pedido"
    },
    "printEgift": {
      "themeAltText": "Tema de la tarjeta de regalo electrónica "
    },
    "promotion": {
      "applyPromotionCode": "Aplicar",
      "codeInvalid": "El código de promoción no es válido.",
      "customHelpText": "* El {percentage}% de descuento se aplica a valores de {minimumLoad} o superiores.",
      "discountCodeApplied": "Se aplicó el código de descuento \"{code}\".",
      "discountCodeIgnored": "El descuento actual es mejor que \"{ignoredCode}\".",
      "fixedDiscount": "{discount} de descuento",
      "fixedDiscountExpires": "{discount} de descuento hasta el {expirationDate}",
      "fixedDiscountMinLoad": "{discount} de descuento en artículos de {minimumLoad} o superiores",
      "fixedDiscountMinLoadExpires": "{discount} de descuento en artículos de {minimumLoad} o superiores hasta el {expirationDate}",
      "payDiscountedPrice": "Pagar {price}",
      "percentageDiscount": "{percentage}% de descuento",
      "percentageDiscountExpires": "{percentage}% de descuento hasta el {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage}% de descuento (descuento máximo de {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage}% de descuento hasta el {expirationDate} (descuento máximo de {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage}% de descuento en artículos de {minimumLoad} o superiores",
      "percentageDiscountMinLoadExpires": "{percentage}% de descuento en artículos de {minimumLoad} o superiores hasta el {expirationDate}",
      "promotionCode": "Código de promoción",
      "promotionCodePlaceholder": "MYDISCOUNTCODE",
      "promotionalPricing": "Precios de promoción: {description}",
      "youPayPrice": "Tú pagas {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Agrega tu dirección de correo electrónico",
      "addPhoneNumber": "Agregar número de teléfono",
      "editPhoneNumber": "Editar"
    },
    "reload": {
      "balance": "Saldo de la tarjeta: {balance}",
      "checkBalance": "Comprobar saldo",
      "description": "Agrega dinero a tu tarjeta de regalo.",
      "discountInput": "Agregar código de descuento",
      "promoInput": "Agregar código de promoción",
      "subheader": "Importe a agregar a la tarjeta",
      "submit": "Continuar"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Detalles de la recarga"
    },
    "resend": {
      "heroSecondary": "No se pudo entregar tu regalo. Actualiza la dirección de correo electrónico del destinatario.",
      "heroText": "Actualizar dirección de correo electrónico"
    },
    "routeError": {
      "giftCardNotFound": "La tarjeta de regalo no es válida o aún no se ha activado."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": "para la tarjeta de regalo. Se enviará un texto de confirmación al {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{La dirección de correo electrónico indicada no es válida} other{Una o varias de las direcciones de correo electrónico indicadas no son válidas}}. Introduce otra.",
        "inTotal": {
          "perDay": "No puedes enviar más de 4 tarjetas de regalo diarias por mensaje de texto. En su lugar, envíalas por correo electrónico.",
          "perMonth": "No puedes enviar más de 20 tarjetas de regalo mensuales por mensaje de texto. En su lugar, envíalas por correo electrónico."
        },
        "minAmount": "las tarjetas de regalo electrónicas enviadas por mensaje de texto deben ser de $5 o más",
        "samePhoneNumber": {
          "perDay": "No puedes enviar más de 2 tarjetas de regalo diarias al mismo número de teléfono. En su lugar, envíalas por correo electrónico.",
          "perMonth": "No puedes enviar más de 4 tarjetas de regalo mensuales al mismo número de teléfono. En su lugar, envíalas por correo electrónico."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Número de teléfono de los colaboradores ({max} personas como máximo, separadas por comas)",
        "invalidPhoneNumberValidation": "{phoneNumber} es un número de teléfono inválido",
        "maxPhoneNumbersValidation": "No se pueden tener más de {max} números de teléfono en un solo pedido",
        "minPhoneNumbersValidation": "Debes ingresar al menos {min} números de teléfono",
        "phoneNumber": "Número de teléfono",
        "phoneNumbers": "Números de teléfono (separados por comas)",
        "phoneNumbersRequiredValidation": "Los números de teléfono son obligatorios",
        "recipientPhoneNumber": "Número de teléfono del destinatario",
        "yourPhoneNumber": "Tu número de teléfono"
      },
      "giftMessage": {
        "messageFrom": "Mensaje de {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Número de teléfono no disponible"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Se compartirá tu nombre, número de teléfono y mensaje con el destinatario."
      },
      "legalDisclaimer": "Al seleccionar Proceso de pago, confirmas que podemos enviar un mensaje de texto único con la tarjeta de regalo electrónica al número que has indicado arriba. Comunícate directamente con {sellerName} para obtener detalles sobre sus prácticas de privacidad. Aplican tarifas de mensajes y datos.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Agregar número de teléfono",
          "subheader": "Deja tu tarjeta de regalo en casa y dile tu número de teléfono al cajero."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "Destinatarios de tarjetas de regalo electrónicas"
        }
      },
      "resend": {
        "buttonText": "Reenviar tarjeta de regalo electrónica",
        "heroSecondary": "No se pudo entregar tu regalo. Actualiza la información de contacto del destinatario.",
        "heroText": "Actualiza la información del envío"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Enviar por número de teléfono"
        }
      },
      "todayAt": "Hoy a las {time}",
      "todayInstantly": "Hoy (al instante)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Número de teléfono del destinatario",
        "confirmationValidation": "Los números de teléfono no coinciden.",
        "originalContactInfo": "Información de contacto original",
        "originalPhoneNumber": "Número de teléfono original",
        "phoneNumberUnavailable": "Número de teléfono no disponible",
        "recipientPhoneNumber": "Número de teléfono del destinatario"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Proporciona tu número de teléfono en el proceso de pago.",
        "phone": "Número de teléfono"
      }
    },
    "shareGroupGift": {
      "shareLink": "Compartir enlace",
      "shareWithOthers": "Comparte el enlace con otras personas para que puedan unirse al regalo de grupo."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Enviar por correo electrónico",
        "sendByText": "Enviar por mensaje de texto"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Enviar a más de un destinatario",
      "sendToOne": "Enviar a un destinatario"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Dirección de correo electrónico del destinatario",
      "confirmationValidation": "Los correos electrónicos no coinciden.",
      "emailAddressUnavailable": "dirección de correo electrónico no disponible",
      "originalEmail": "Correo electrónico original",
      "recipientEmailAddress": "Dirección de correo electrónico del destinatario",
      "updateOrder": "Actualizar pedido"
    },
    "view": {
      "addMoreFunds": "Agregar más fondos",
      "transactionHistory": "Historial de transacciones",
      "waysToRedeem": "Métodos de canje"
    },
    "viewPageHeader": {
      "currentBalance": "Saldo actual",
      "greeting": "Hola, {recipientName}, {senderName} te envió una tarjeta de regalo electrónica.",
      "groupGreeting": "Un regalo para {recipientName}.",
      "groupViewGreeting": "Hola, {recipientName}, tus amigos te enviaron una tarjeta de regalo electrónica."
    },
    "waysToRedeem": {
      "addToSquareProfile": "Agregar a tu perfil de Square",
      "barCode": "Código de barras",
      "code": "Código",
      "codeInstructions": "Muestre este código al cajero.",
      "email": "Correo electrónico",
      "giftCardCode": "Código de tarjeta de regalo",
      "instructions": "Escanea o ingresa el código para canjear esta tarjeta de regalo.",
      "methodChoiceSeparator": "o",
      "otherHeader": "Otras formas de canjear en la tienda",
      "otherInstructions": "Proporciona tu número de teléfono o dirección de correo electrónico durante el proceso de pago.",
      "otherInstructionsOnlyEmail": "Proporciona tu dirección de correo electrónico durante el proceso de pago.",
      "phone": "Número de teléfono",
      "phoneActionText": "Cambiar número de teléfono de la tarjeta",
      "qrCode": "Código QR",
      "redeemOnline": "Canjear en línea",
      "redeemOnlineInstructions": "Ingresa el código de la tarjeta de regalo durante el proceso de pago.",
      "squareProfileDescription": "El perfil de Square te ayuda a ver y gestionar todas tus tarjetas de regalo de diferentes negocios de Square"
    }
  }], ["es-es", {
    "addressInput": {
      "loading": "Cargando...",
      "noResults": "No hay resultados."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Belleza y cuidado personal",
      "charitiesEducationAndMembership": "Organizaciones benéficas, educativas o asociaciones",
      "foodAndDrink": "Alimentación y bebidas",
      "healthCareAndFitness": "Salud y deporte",
      "homeAndRepair": "Hogar y servicios de reparación",
      "leisureAndEntertainment": "Ocio y entretenimiento",
      "other": "Otro",
      "professionalServices": "Servicios profesionales",
      "retail": "Comercios",
      "transportation": "Transporte"
    },
    "businessCategoryFilters": {
      "hideFilters": "Ocultar filtros",
      "showFilters": "Mostrar filtros"
    },
    "checkBalance": {
      "checkboxLabel": "Mi tarjeta regalo no tiene PIN",
      "description": "Introduce el número de tu tarjeta regalo para comprobar tu saldo.",
      "errors": {
        "notFound": "No se ha podido encontrar la tarjeta regalo.",
        "rateLimit": "Demasiados intentos. Vuelve a intentarlo más tarde."
      },
      "label": "Número de la tarjeta regalo",
      "pinLabel": "PIN",
      "submit": "Comprobar saldo",
      "tooltip": "El PIN se encuentra en la parte posterior de la tarjeta regalo, junto al número de la tarjeta regalo.",
      "tooltipPinRequired": "El PIN se encuentra en la parte posterior de la tarjeta regalo, junto al número de la tarjeta regalo. Si tu tarjeta regalo no tiene PIN, ponte en contacto con el comercio para comprobar tu saldo."
    },
    "clipboard": {
      "copyLink": "Copiar enlace",
      "linkCopied": "¡Enlace copiado!"
    },
    "common": {
      "edit": "Editar",
      "next": "Siguiente",
      "unknownError": "Se ha producido un error desconocido. Vuelve a intentarlo más tarde."
    },
    "confirmLink": {
      "heroSecondary": "Y comunica tu número de teléfono al cajero/a.",
      "heroText": "Simplemente pasa por la caja.",
      "seeBalanceButton": "Consulta el saldo de tu tarjeta regalo"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "El pedido de {quantity} tarjetas regalo electrónicas x un valor de {amount} se enviará el dia {formattedDate}.",
          "instantDescription": "El pedido de {quantity} tarjetas regalo electrónicas x un valor de {amount} ya está de camino."
        },
        "singleRecipient": {
          "futureDescription": "El pedido de {quantity} tarjetas regalo electrónicas x un valor de {amount} se enviará a {recipientEmail} el dia {formattedDate}.",
          "instantDescription": "El pedido de {quantity} tarjetas regalo electrónicas por un valor de {amount} ya está de camino a {recipientEmail}."
        }
      },
      "continueShopping": "Seguir comprando",
      "giftFutureDescription": "La tarjeta regalo electrónica de {amount} se enviará a {recipientName} ({recipientContact}) el {formattedDate}.",
      "giftInstantDescription": "Se ha enviado la tarjeta regalo electrónica de {amount} a {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Volver al regalo grupal"
      },
      "groupOrder": {
        "confirmationMessageEnd": "a la tarjeta regalo. Enviaremos un correo electrónico de confirmación a {emailAddress}",
        "confirmationMessageStart": "Has añadido",
        "groupGiftStarted": "¡Ha empezado el regalo grupal!",
        "viewGroupGift": "Consultar el regalo grupal"
      },
      "orderComplete": "Pedido completo",
      "orderFutureDescription": "Tu vale de {amount} se enviará a {recipientName} ({recipientContact}) el {formattedDate}.",
      "orderInstantDescription": "Se ha enviado tu vale de {amount} a {recipientName} ({recipientContact}).",
      "orderUpdated": "Pedido actualizado",
      "reloadComplete": "Recarga completa",
      "sendAnother": "Enviar otra tarjeta regalo electrónica",
      "sendAnotherGift": "Enviar otro regalo"
    },
    "date": {
      "formatPlaceholder": "DD/MM/AAAA",
      "nextMonth": "Próximo mes",
      "previousMonth": "Mes anterior"
    },
    "discount": {
      "codeInvalid": "Este código de descuento no es válido.",
      "discountCode": "Código de descuento",
      "discountPricing": "Precio con el descuento: {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filtrar por"
      },
      "footer": {
        "aProjectBy": "Un proyecto de"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Los negocios de proximidad necesitan tu ayuda. Puedes dar un empujón a tus comercios Square favoritos comprando tarjetas regalo digitales.",
          "voucher": "Los negocios de proximidad necesitan tu ayuda. Puedes dar un empujón a tus comercios Square favoritos comprando tarjetas regalo digitales."
        },
        "giftCardAsteriskAltText": "Tarjeta regalo con diseño de asteriscos de colores"
      },
      "hero": {
        "subtitle": "Compra hoy una tarjeta regalo electrónica, úsala más adelante, y ayuda a los negocios de proximidad de tu elección.",
        "title": "Apoya a tus negocios favoritos"
      },
      "locationSelector": {
        "currentLocation": "Punto de venta actual",
        "label": "Encuentra usuarios de Square cerca de ti",
        "placeholder": "Introduce la dirección o la ciudad",
        "separator": "o",
        "useCurrentLocation": "Utilizar mi ubicación actual"
      },
      "merchantCard": {
        "buyButton": "Comprar tarjeta regalo"
      },
      "results": {
        "noResults": "No hay resultados"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "¡Gracias por tu apoyo! Ayúdanos a correr la voz."
        },
        "notFromDirectory": {
          "description": "Echa un vistazo a Give & Get Local para encontrar negocios que ofrezcan tarjetas regalo electrónicas, recogidas, entregas y mucho más.",
          "title": "Apoya a tus negocios favoritos"
        },
        "shareOnFacebook": "Compartir en Facebook",
        "shareOnTwitter": "Compartir en Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Selector de temas de tarjetas regalo",
      "carouselRole": "carrusel",
      "customImageFallback": "Imagen personalizada del negocio",
      "nextButtonDescription": "Tema siguiente",
      "previousButtonDescription": "Tema anterior",
      "slideRole": "diapositiva"
    },
    "errors": {
      "invalidEmail": "Parece que esta dirección de correo electrónico no es válida.",
      "invalidPhone": "Parece que este número de teléfono no es válido.",
      "requiredField": "Este campo es obligatorio."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Condiciones de Give & Get Local",
      "privacyPolicy": "Política de privacidad",
      "termsOfService": "Condiciones del servicio",
      "text": "Con la tecnología de <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">tarjetas regalo Square</a>"
    },
    "form": {
      "continue": "Continuar",
      "failedLabel": "Error",
      "optionalLabel": "Opcional"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Añadir un mensaje personal",
      "amount": "Importe",
      "contributionAmount": "Importe de la aportación",
      "contributorEmailAddresses": "Direcciones de correo electrónico de los colaboradores ({max} personas como máximo, separadas por comas)",
      "custom": "Personalizar",
      "customAmount": "Otro importe",
      "customAmountBoundsValidation": "El importe de la tarjeta regalo electrónica debe estar entre {min} y {max}.",
      "customAmountPlaceholder": "De {min} a {max}",
      "customAmountRequiredValidation": "Introduce un importe personalizado para la tarjeta regalo electrónica o selecciona una opción.",
      "delivery": "Entrega a domicilio",
      "deliveryDate": "Fecha de entrega",
      "deliveryDateValidation": "Introduce una fecha en los próximos 60 días.",
      "deliveryDetailsHeader": "Detalles de la entrega",
      "deliveryTime": "Hora de entrega",
      "eGiftCardAmount": "Importe de la tarjeta regalo electrónica",
      "emailAddress": "Dirección de correo electrónico",
      "emailAddresses": "Direcciones de correo electrónico (separadas por comas)",
      "emailAddressesRequiredValidation": "Se requieren direcciones de correo electrónico",
      "giftTypeHeader": "¿Qué tipo de regalo es?",
      "groupDesc": "Reúne dinero de varias personas para enviárselo a un mismo destinatario",
      "groupHeader": "Tarjeta regalo grupal",
      "individualDesc": "Envía una tarjeta regalo a un destinatario",
      "individualDescWithBulk": "Envía una tarjeta regalo a un destinatario o haz una compra en bloque.",
      "individualHeader": "Para una sola persona",
      "invalidEmailAddressValidation": "Correo electrónico {email} no válido",
      "invitationMessage": "Mensaje de invitación",
      "invitationMessageTooLong": "El tamaño máximo de un mensaje de invitación es de 400 caracteres.",
      "inviteContributorsHeader": "Invita a colaboradores (opcional)",
      "inviteContributorsSubtitle": "Envía un correo electrónico invitando a otros a colaborar con este regalo. También obtendrás un enlace para compartirlo después del proceso de pago.",
      "maxEmailAddressesValidation": "No puede haber más de {max} correos electrónicos en un solo pedido",
      "maxQuantityValidation": "La cantidad no puede ser mayor que {max}.",
      "minEmailAddressesValidation": "Debe introducir al menos {min} correos electrónicos",
      "multipleDesc": "Envía tarjetas regalo a varias personas a la vez",
      "multipleHeader": "Para varias personas",
      "name": "Nombre",
      "personalMessage": "Mensaje personal",
      "personalMessageOptional": "Mensaje personal (opcional)",
      "personalMessageTooLong": "El tamaño máximo del mensaje personal es de 200 caracteres.",
      "quantity": "Cantidad",
      "quantityValidation": "La cantidad debe ser de entre {min} y {max}",
      "recipientEmail": "Correo electrónico del destinatario",
      "recipientName": "Nombre del destinatario",
      "recipientNamePlaceholder": "Nombre del destinatario",
      "recipientQuantityPlaceholder": "1 por destinatario",
      "sendAsAGift": "Enviar este vale como regalo",
      "sendInstantly": "Enviar ahora",
      "sendOnAFutureDate": "Enviar más adelante",
      "sendToMyself": "Enviarme esta tarjeta ",
      "sendToSelf": "Quiero enviármelo a mi mismo/a",
      "suggestedContributionAmount": "Importe sugerido a aportar",
      "timeZone": "Zona horaria",
      "yourDetailsHeader": "Tu información",
      "yourEmail": "Tu correo electrónico",
      "yourEmailAddress": "Tu dirección de correo electrónico",
      "yourName": "Tu nombre",
      "yourNamePlaceholder": "Tu nombre"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Elige un importe y envía tu regalo al instante",
        "voucher": "Recibe un vale para ti o regala uno a un amigo"
      },
      "heroText": "Haz el regalo perfecto"
    },
    "giftMessage": {
      "custom": "&ldquo;{customMessage}&rdquo;",
      "from": "De",
      "messageFrom": "{senderEmail} te ha mandado un mensaje",
      "nameNotAvailable": "nombre no disponible",
      "to": "A"
    },
    "giftSummarySidebar": {
      "customMessage": "&ldquo;{customMessage}&rdquo;",
      "deliveryFailed": "Envío fallido: {recipient}",
      "discountsApplied": "Descuento aplicado",
      "emailAddressUnavailable": "Dirección de correo electrónico no disponible",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} más",
      "newBalance": "Tu nuevo saldo será de {newBalance}",
      "orderDetails": "Información del pedido",
      "payAmount": "Importe que pagas",
      "promotionApplied": "Promoción aplicada",
      "valueDoesNotExpire": "El valor no caduca.",
      "viewAllRecipients": "Ver todos los destinatarios"
    },
    "group": {
      "giftDetailsHeader": "Detalles del regalo",
      "loadFunds": "Cargar fondos",
      "organizedBy": "Organizado por",
      "suggestedContributionDesc": "El organizador ha sugerido aportar {suggestedContributionAmount}."
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Tu nombre, tu correo electrónico y el mensaje se compartirán con el destinatario.",
      "header": "Colaboradores",
      "viewAll": "Ver todo"
    },
    "header": {
      "checkBalanceLink": "¿Necesitas comprobar el saldo de tu tarjeta regalo?",
      "logoAltText": "Logotipo de {merchantName} ",
      "orderPageLink": "Solicita una tarjeta regalo electrónica"
    },
    "headerV2": {
      "checkBalanceLink": "Comprobar saldo",
      "logoAltText": "Logotipo de {merchantName}",
      "orderPageLink": "Comprar tarjeta regalo",
      "reloadPageLink": "Recargar tarjeta"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Eliminar",
        "deduct": "Fondos deducidos",
        "load": "Fondos cargados",
        "redeem": "Compra",
        "refund": "Reembolso",
        "transferBalanceFrom": "Transferir a {giftCardDisplay}",
        "transferBalanceTo": "Transferir de {giftCardDisplay}",
        "unknown": "Desconocido"
      }
    },
    "link": {
      "heroSecondary": "Configura tu tarjeta con un número de teléfono.",
      "heroText": "Deja tu tarjeta regalo en casa.",
      "noThanks": "No, gracias. Usaré el código de 16 dígitos.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "Se ha producido un error al actualizar tu número de teléfono."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Ver en línea"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Guardar",
        "header": "Añadir dirección de correo electrónico",
        "subheader": "No necesitas la tarjeta regalo: indica tu dirección de correo electrónico en caja."
      },
      "addEditPhoneNumber": {
        "buttonText": "Guardar",
        "header": "Añadir número de teléfono",
        "subheader": "Deja tu tarjeta regalo en casa y comunica tu número de teléfono al cajero o a la cajera que te atienda."
      },
      "recipientEmails": {
        "eGiftRecipients": "Destinatarios de la tarjeta regalo electrónica"
      }
    },
    "pageNotFound": "Lo sentimos, esta página de tarjeta regalo electrónica no existe o ha sido desactivada.",
    "pageTitle": {
      "checkBalance": "Comprobar saldo | Tarjetas regalo de {merchantName}",
      "default": "Tarjetas regalo electrónicas de {merchantName}",
      "discover": "¡Apoya a los negocios de proximidad con las tarjetas regalo electrónicas de Square!",
      "group": "Grupo | Tarjetas regalo electrónicas de {merchantName}",
      "linkPhone": "Vincular número de teléfono | Tarjetas regalo electrónicas de {merchantName}",
      "order": "Pedido | Tarjetas regalo electrónicas de {merchantName}",
      "print": "Imprimir | Tarjetas regalo electrónicas de {merchantName}",
      "reload": "Recargar | Tarjetas regalo electrónicas de {merchantName}",
      "resend": "Reenviar | Tarjetas regalo electrónicas de {merchantName}"
    },
    "payment": {
      "confirmDetails": "Confirma la información de tu pedido",
      "confirmDetailsWithPayment": "Confirma la información de tu pedido e introduce tu información de facturación",
      "error": {
        "compliance": {
          "dailyLimit": "El pedido supera el límite diario de cumplimiento de la tarjeta regalo."
        },
        "paymentDeclined": "Pago rechazado. Introduce otra tarjeta de crédito."
      },
      "heroText": "Proceso de pago"
    },
    "paymentForm": {
      "buyerBeware": "Al completar esta compra, confirmo que me hago responsable de cualquier pérdida relacionada con la compra de la tarjeta regalo en caso de que el vendedor no pueda tramitar la entrega de bienes o servicios.",
      "disclaimer": {
        "default": "Al comprar una tarjeta regalo electrónica, aceptas la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Política de privacidad</a> y el <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Consentimiento de firma electrónica</a> de Square.",
        "giveAndGetLocal": "Al comprar una tarjeta regalo electrónica, aceptas las<a href=\"{localizedTermsUrl}\" target=\"_blank\">Condiciones de Dar y Recibir Terminos locales</a>, la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Política de privacidad</a>, y el <a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Consentimiento electrónico</a> de Square."
      },
      "errors": {
        "unauthorized": "No se ha podido autorizar tu tarjeta de pago"
      },
      "payWithCard": "Pagar con tarjeta"
    },
    "paymentInfoForm": {
      "placeOrder": "Realizar pedido"
    },
    "printEgift": {
      "themeAltText": "Tema de la tarjeta regalo electrónica "
    },
    "promotion": {
      "applyPromotionCode": "Aplicar",
      "codeInvalid": "Este código promocional no es válido.",
      "customHelpText": "* El {percentage} % de descuento se aplica a valores de {minimumLoad} o superiores.",
      "discountCodeApplied": "Se ha aplicado el código de descuento «{code}».",
      "discountCodeIgnored": "El descuento actual es mejor que «{ignoredCode}».",
      "fixedDiscount": "{discount} de descuento",
      "fixedDiscountExpires": "Descuento de {discount} hasta el {expirationDate}",
      "fixedDiscountMinLoad": "{discount} de descuento en artículos de {minimumLoad} o superiores",
      "fixedDiscountMinLoadExpires": "{discount} de descuento en artículos de {minimumLoad} o superiores hasta el {expirationDate}",
      "payDiscountedPrice": "Pagar {price}",
      "percentageDiscount": "Descuento del {percentage} %",
      "percentageDiscountExpires": "{percentage} % de descuento hasta el {expirationDate}",
      "percentageDiscountMaxDiscount": "{percentage} % de descuento (descuento máximo de {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "{percentage} % de descuento hasta el {expirationDate} (descuento máximo de {maximumDiscount})",
      "percentageDiscountMinLoad": "{percentage} % de descuento en artículos de {minimumLoad} o superiores",
      "percentageDiscountMinLoadExpires": "{percentage} % de descuento en artículos de {minimumLoad} o superiores hasta el {expirationDate}",
      "promotionCode": "Código promocional",
      "promotionCodePlaceholder": "MICÓDIGODEDESCUENTO",
      "promotionalPricing": "Precios promocionales: {description}",
      "youPayPrice": "Pagas {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Añadir dirección de correo electrónico",
      "addPhoneNumber": "Añadir número de teléfono",
      "editPhoneNumber": "Editar"
    },
    "reload": {
      "balance": "Saldo de la tarjeta: {balance}",
      "checkBalance": "Comprobar saldo",
      "description": "Añade dinero a tu tarjeta regalo.",
      "discountInput": "Añadir código de descuento",
      "promoInput": "Añadir código promocional",
      "subheader": "Importe que quieres añadir a la tarjeta",
      "submit": "Continuar"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Detalles de la recarga"
    },
    "resend": {
      "heroSecondary": "No se ha podido entregar tu regalo. Actualiza la dirección de correo electrónico del destinatario.",
      "heroText": "Actualizar dirección de correo electrónico"
    },
    "routeError": {
      "giftCardNotFound": "La tarjeta regalo no es válida o aún no se ha activado."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": "para la tarjeta regalo. Se enviará un SMS de confirmación al {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{La dirección de correo electrónico indicada no es válida} other{Una o varias de las direcciones de correo electrónico indicadas no son válidas}}. Introduce otra.",
        "inTotal": {
          "perDay": "No puedes enviar más de 4 tarjetas regalo diarias por SMS. En su lugar, envíalas por correo electrónico.",
          "perMonth": "No puedes enviar más de 20 tarjetas regalo mensuales por SMS. En su lugar, envíalas por correo electrónico."
        },
        "minAmount": "El importe mínimo de las tarjetas regalo electrónicas por SMS es de 5 $",
        "samePhoneNumber": {
          "perDay": "No puedes enviar más de 2 tarjetas regalo diarias al mismo número de teléfono. En su lugar, envíalas por correo electrónico.",
          "perMonth": "No puedes enviar más de 4 tarjetas regalo mensuales al mismo número de teléfono. En su lugar, envíalas por correo electrónico."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Número de teléfono de los colaboradores ({max} personas como máximo, separadas por comas)",
        "invalidPhoneNumberValidation": "Número de teléfono no válido: {phoneNumber}",
        "maxPhoneNumbersValidation": "No se pueden incluir más de {max} números de teléfono en un solo pedido",
        "minPhoneNumbersValidation": "Debes incluir al menos {min} números de teléfono",
        "phoneNumber": "Número de teléfono",
        "phoneNumbers": "Números de teléfono (separados por comas)",
        "phoneNumbersRequiredValidation": "Los números de teléfono son obligatorios",
        "recipientPhoneNumber": "Número de teléfono del destinatario",
        "yourPhoneNumber": "Tu número de teléfono"
      },
      "giftMessage": {
        "messageFrom": "Mensaje del {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Número de teléfono no disponible"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Se compartirá tu nombre, número de teléfono y mensaje con el destinatario."
      },
      "legalDisclaimer": "Al seleccionar Pagar, aceptas que podemos enviar un SMS con la tarjeta regalo electrónica al número que has indicado arriba. Contacta directamente con {sellerName} para consultar más información sobre su política de privacidad. Se aplican las tarifas correspondientes a los mensajes y los datos.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Añadir número de teléfono",
          "subheader": "Deja tu tarjeta regalo en casa y comunica tu número de teléfono al cajero o a la cajera que te atienda."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "Destinatarios de la tarjeta regalo electrónica"
        }
      },
      "resend": {
        "buttonText": "Reenviar la tarjeta regalo electrónica",
        "heroSecondary": "No se ha podido entregar tu regalo. Actualiza la información de contacto del destinatario.",
        "heroText": "Actualizar datos del destinatario"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Enviar por número de teléfono"
        }
      },
      "todayAt": "Hoy a las {time}",
      "todayInstantly": "Hoy (al instante)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Número de teléfono del destinatario",
        "confirmationValidation": "Los números de teléfono no coinciden.",
        "originalContactInfo": "Información de contacto original",
        "originalPhoneNumber": "Número de teléfono original",
        "phoneNumberUnavailable": "Número de teléfono no disponible",
        "recipientPhoneNumber": "Número de teléfono del destinatario"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Indica tu número de teléfono en el proceso de pago.",
        "phone": "Número de teléfono"
      }
    },
    "shareGroupGift": {
      "shareLink": "Compartir enlace",
      "shareWithOthers": "Comparte el enlace con más personas para que puedan unirse al regalo de grupo."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Enviar por correo electrónico",
        "sendByText": "Enviar por SMS"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Enviar a varios destinatarios",
      "sendToOne": "Enviar a un destinatario"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Dirección de correo electrónico del destinatario",
      "confirmationValidation": "Los correos electrónicos no coinciden.",
      "emailAddressUnavailable": "dirección de correo electrónico no disponible",
      "originalEmail": "Correo electrónico original",
      "recipientEmailAddress": "Dirección de correo electrónico del destinatario",
      "updateOrder": "Actualizar pedido"
    },
    "view": {
      "addMoreFunds": "Añadir más fondos",
      "transactionHistory": "Historial de transacciones",
      "waysToRedeem": "Formas de canjear"
    },
    "viewPageHeader": {
      "currentBalance": "Saldo actual",
      "greeting": "Hola, {recipientName}. {senderName} te ha mandado una tarjeta regalo electrónica.",
      "groupGreeting": "¡Un regalo para {recipientName}!",
      "groupViewGreeting": "Hola, {recipientName}: ¡tus amigos te han enviado una tarjeta regalo electrónica!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Añadir a tu perfil Square",
      "barCode": "Código de barras",
      "code": "Código",
      "codeInstructions": "Enseña este código al cajero/a.",
      "email": "Correo electrónico",
      "giftCardCode": "Código de la tarjeta regalo",
      "instructions": "Escanea o introduce un código para canjear la tarjeta regalo.",
      "methodChoiceSeparator": "o",
      "otherHeader": "Otras formas de canjearla en tienda",
      "otherInstructions": "Proporciona tu número de teléfono o la dirección de correo electrónico durante el proceso de pago.",
      "otherInstructionsOnlyEmail": "Da tu dirección de correo electrónico cuando vayas a pagar.",
      "phone": "Número de teléfono",
      "phoneActionText": "Cambiar el número de teléfono de la tarjeta",
      "qrCode": "Código QR",
      "redeemOnline": "Canjear en línea",
      "redeemOnlineInstructions": "Introduce el código de la tarjeta regalo durante el proceso de pago.",
      "squareProfileDescription": "El perfil Square te ayuda a visualizar y gestionar todas tus tarjetas regalo de los diferentes negocios Square"
    }
  }], ["fr", {
    "addressInput": {
      "loading": "Chargement en cours…",
      "noResults": "Aucun résultat."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Beauté et soins",
      "charitiesEducationAndMembership": "Organisme caritatif, éducatif ou associatif",
      "foodAndDrink": "Alimentation et boissons",
      "healthCareAndFitness": "Santé et fitness",
      "homeAndRepair": "Maison et rénovation",
      "leisureAndEntertainment": "Loisirs et divertissement",
      "other": "Autre",
      "professionalServices": "Services professionnels",
      "retail": "Commerce de détail",
      "transportation": "Transports"
    },
    "businessCategoryFilters": {
      "hideFilters": "Masquer les filtres",
      "showFilters": "Afficher les filtres"
    },
    "checkBalance": {
      "checkboxLabel": "Ma carte cadeau n’a pas de code PIN.",
      "description": "Saisissez le numéro de votre carte cadeau pour vérifier votre solde.",
      "errors": {
        "notFound": "Cette carte cadeau est introuvable.",
        "rateLimit": "Vous avez effectué trop de tentatives. Réessayez dans quelques instants."
      },
      "label": "Numéro de carte cadeau",
      "pinLabel": "code PIN",
      "submit": "Vérifier le solde",
      "tooltip": "Le code PIN se situe au verso de la carte-cadeau, à côté du numéro de carte.",
      "tooltipPinRequired": "Le code PIN se situe au verso de la carte-cadeau, à côté du numéro de carte. Si votre carte n’a pas de code PIN, contactez le commerçant pour connaître votre solde."
    },
    "clipboard": {
      "copyLink": "Copier le lien",
      "linkCopied": "Lien copié"
    },
    "common": {
      "edit": "Modifier",
      "next": "Suivant",
      "unknownError": "Une erreur inconnue s’est produite. Veuillez réessayer ultérieurement."
    },
    "confirmLink": {
      "heroSecondary": "Indiquez au caissier votre numéro de téléphone.",
      "heroText": "Il vous suffit de passer en caisse.",
      "seeBalanceButton": "Affichez le solde de votre carte cadeau"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Votre commande de {quantity} cartes cadeaux électroniques de {amount} sera envoyée le {formattedDate}.",
          "instantDescription": "Votre commande de {quantity} cartes cadeaux électroniques de {amount} est en route."
        },
        "singleRecipient": {
          "futureDescription": "Votre commande de {quantity} cartes cadeaux électroniques de {amount} sera envoyée à {recipientEmail} le {formattedDate}.",
          "instantDescription": "Votre commande de {quantity} cartes cadeaux électroniques de {amount} sera bientôt transmise à {recipientEmail}."
        }
      },
      "continueShopping": "Poursuivre mes achats",
      "giftFutureDescription": "Votre carte cadeau électronique de {amount} sera envoyée à {recipientName} ({recipientContact}) le {formattedDate}.",
      "giftInstantDescription": "Votre carte cadeau électronique de {amount} a été envoyée à {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Retour au cadeau commun"
      },
      "groupOrder": {
        "confirmationMessageEnd": "à la carte cadeau. Un e-mail de confirmation sera envoyé à cette adresse : {emailAddress}.",
        "confirmationMessageStart": "Vous avez ajouté",
        "groupGiftStarted": "Le cadeau commun est en préparation !",
        "viewGroupGift": "Voir le cadeau commun"
      },
      "orderComplete": "Commande terminée",
      "orderFutureDescription": "Votre bon de {amount} sera envoyé à {recipientName} ({recipientContact}) le {formattedDate}.",
      "orderInstantDescription": "Votre bon de {amount} a été envoyé à {recipientName} ({recipientContact}).",
      "orderUpdated": "Commande mise à jour",
      "reloadComplete": "Rechargement terminé",
      "sendAnother": "Envoyer une autre carte cadeau électronique",
      "sendAnotherGift": "Envoyer un autre cadeau"
    },
    "date": {
      "formatPlaceholder": "JJ/MM/AAAA",
      "nextMonth": "Mois suivant",
      "previousMonth": "Mois précédent"
    },
    "discount": {
      "codeInvalid": "Ce code de réduction n’est pas valide.",
      "discountCode": "Code de réduction",
      "discountPricing": "Prix réduit : {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filtrer par"
      },
      "footer": {
        "aProjectBy": "Un projet de"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "Give & Get Local"
        },
        "description": {
          "giftCards": "Les entreprises locales ont besoin de votre soutien. Encouragez vos commerçants Square préférés avec l’achat de cartes cadeaux électroniques.",
          "voucher": "Les entreprises locales ont besoin de votre soutien. Encouragez vos commerçants Square préférés en achetant des bons à offrir en cadeau ou à conserver pour votre utilisation personnelle."
        },
        "giftCardAsteriskAltText": "Carte cadeau avec modèle d’astérisque en couleurs"
      },
      "hero": {
        "subtitle": "Achetez tout de suite une carte cadeau électronique que vous utiliserez plus tard pour soutenir un de vos commerces locaux préférés.",
        "title": "Soutenez vos commerçants préférés"
      },
      "locationSelector": {
        "currentLocation": "Position actuelle",
        "label": "Trouvez les commerçants Square près de vous",
        "placeholder": "Saisir l’adresse ou la ville",
        "separator": "ou",
        "useCurrentLocation": "Utiliser ma position actuelle"
      },
      "merchantCard": {
        "buyButton": "Acheter une carte cadeau"
      },
      "results": {
        "noResults": "Aucun résultat"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Merci de votre soutien ! Aidez-nous à faire passer le message."
        },
        "notFromDirectory": {
          "description": "Avec Give & Get Local, découvrez les commerçants qui vendent des cartes cadeaux électroniques et proposent le retrait, la livraison, et bien plus encore.",
          "title": "Soutenez vos commerçants préférés"
        },
        "shareOnFacebook": "Partager sur Facebook",
        "shareOnTwitter": "Partager sur Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Sélecteur de thème de carte cadeau",
      "carouselRole": "carrousel",
      "customImageFallback": "Image personnalisée du vendeur",
      "nextButtonDescription": "Thème suivant",
      "previousButtonDescription": "Thème précédent",
      "slideRole": "diapositive"
    },
    "errors": {
      "invalidEmail": "Cette adresse e-mail ne semble pas être valide.",
      "invalidPhone": "Ce numéro de téléphone ne semble pas correct.",
      "requiredField": "Il s’agit d’un champ obligatoire."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Conditions de Give & Get Local",
      "privacyPolicy": "Politique de confidentialité",
      "termsOfService": "Conditions d’utilisation",
      "text": "Optimisé par <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Cartes cadeaux Square</a>"
    },
    "form": {
      "continue": "Continuer",
      "failedLabel": "Échec",
      "optionalLabel": "Facultatif"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Ajouter un message personnalisé",
      "amount": "Montant",
      "contributionAmount": "Montant de la contribution",
      "contributorEmailAddresses": "Adresses e-mail des contributeurs ({max} personnes maximum, séparées par une virgule)",
      "custom": "Au choix",
      "customAmount": "Montant personnalisé",
      "customAmountBoundsValidation": "Le montant de la carte cadeau électronique doit être compris entre {min} et {max}.",
      "customAmountPlaceholder": "{min} à {max}",
      "customAmountRequiredValidation": "Saisissez le montant de votre choix pour la carte cadeau électronique ou sélectionnez une option.",
      "delivery": "Livraison",
      "deliveryDate": "Date de livraison",
      "deliveryDateValidation": "Saisissez une date dans les 60 prochains jours.",
      "deliveryDetailsHeader": "Détails relatifs à la livraison",
      "deliveryTime": "Heure de livraison",
      "eGiftCardAmount": "Montant de la carte cadeau électronique",
      "emailAddress": "Adresse e-mail",
      "emailAddresses": "Adresses e-mail (séparées par des virgules)",
      "emailAddressesRequiredValidation": "Adresses e-mail obligatoires",
      "giftTypeHeader": "De quel type de cadeau s’agit-il ?",
      "groupDesc": "Regrouper l’argent de plusieurs contributeurs pour un seul destinataire",
      "groupHeader": "Carte cadeau groupée",
      "individualDesc": "Envoyez une carte cadeau à un seul destinataire",
      "individualDescWithBulk": "Envoyer une carte cadeau à un destinataire ou effectuer un achat en gros",
      "individualHeader": "Pour un bénéficiaire unique",
      "invalidEmailAddressValidation": "Adresse e-mail incorrecte {email}",
      "invitationMessage": "Message d‘invitation",
      "invitationMessageTooLong": "La longueur maximale d’un message d’invitation est de 400 caractères.",
      "inviteContributorsHeader": "Inviter des contributeurs (facultatif)",
      "inviteContributorsSubtitle": "Envoyez un e-mail pour inviter les autres à participer à ce cadeau. Vous recevrez également un lien à partager après le paiement.",
      "maxEmailAddressesValidation": "Maximum {max} adresses e-mail par commande",
      "maxQuantityValidation": "La quantité ne peut pas dépasser {max}.",
      "minEmailAddressesValidation": "Vous devez saisir au moins {min} adresses e-mail",
      "multipleDesc": "Envoyer des cartes cadeaux à plusieurs personnes en même temps",
      "multipleHeader": "Pour plusieurs personnes",
      "name": "Nom",
      "personalMessage": "Message personnel",
      "personalMessageOptional": "Message personnel (facultatif)",
      "personalMessageTooLong": "Le message personnel est limité à 200 caractères.",
      "quantity": "Quantité",
      "quantityValidation": "La quantité doit être comprise entre {min} et {max}",
      "recipientEmail": "Adresse e-mail du destinataire",
      "recipientName": "Nom du destinataire",
      "recipientNamePlaceholder": "Nom du destinataire",
      "recipientQuantityPlaceholder": "1 par destinataire",
      "sendAsAGift": "Envoyer ce bon en cadeau",
      "sendInstantly": "Envoyer tout de suite",
      "sendOnAFutureDate": "Envoyer plus tard",
      "sendToMyself": "Envoyez cette carte à moi-même",
      "sendToSelf": "Je souhaite m’envoyer ceci à moi-même.",
      "suggestedContributionAmount": "Montant de participation suggéré",
      "timeZone": "Fuseau horaire",
      "yourDetailsHeader": "Vos détails",
      "yourEmail": "Votre adresse e-mail",
      "yourEmailAddress": "Votre adresse e-mail",
      "yourName": "Votre nom",
      "yourNamePlaceholder": "Votre nom"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Envoyez instantanément un cadeau de la valeur que vous voulez.",
        "voucher": "Achetez un bon pour vous ou un ami."
      },
      "heroText": "Offrez le cadeau parfait"
    },
    "giftMessage": {
      "custom": "&ldquo;{customMessage}&rdquo;",
      "from": "De",
      "messageFrom": "Message de {senderEmail}",
      "nameNotAvailable": "nom introuvable",
      "to": "À"
    },
    "giftSummarySidebar": {
      "customMessage": "&ldquo;{customMessage}&rdquo;",
      "deliveryFailed": "Échec de la livraison : {recipient}",
      "discountsApplied": "Réduction appliquée",
      "emailAddressUnavailable": "Adresse e-mail non disponible",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} de plus",
      "newBalance": "Le nouveau solde sera de {newBalance}",
      "orderDetails": "Détails de la commande",
      "payAmount": "Montant à régler",
      "promotionApplied": "Promotion appliquée",
      "valueDoesNotExpire": "Sans date d’expiration.",
      "viewAllRecipients": "Voir tous les destinataires"
    },
    "group": {
      "giftDetailsHeader": "Détails relatifs au cadeau",
      "loadFunds": "Charger les fonds",
      "organizedBy": "Organisé par",
      "suggestedContributionDesc": "L’organisateur a suggéré de contribuer {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Votre nom, votre adresse e-mail et votre message seront communiqués au destinataire.",
      "header": "Collaborateurs",
      "viewAll": "Tout afficher"
    },
    "header": {
      "checkBalanceLink": "Besoin de vérifier le solde de votre carte cadeau ?",
      "logoAltText": "Logo {merchantName}",
      "orderPageLink": "Commander une carte cadeau électronique"
    },
    "headerV2": {
      "checkBalanceLink": "Vérifier le solde",
      "logoAltText": "Logo {merchantName}",
      "orderPageLink": "Acheter une carte cadeau",
      "reloadPageLink": "Recharger la carte"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Effacer",
        "deduct": "Fonds déduits",
        "load": "Fonds chargés",
        "redeem": "Achat",
        "refund": "Rembourser",
        "transferBalanceFrom": "Transférer vers {giftCardDisplay}",
        "transferBalanceTo": "Transférer depuis {giftCardDisplay}",
        "unknown": "Inconnu"
      }
    },
    "link": {
      "heroSecondary": "Définissez un numéro de téléphone pour votre carte.",
      "heroText": "Vous n’avez pas besoin d’approcher votre carte cadeau.",
      "noThanks": "Non merci, j’utiliserai le code à 16 chiffres.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "Une erreur s’est produite lors de la mise à jour du numéro de téléphone."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Acheter en ligne"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Enregistrer",
        "header": "Ajouter une adresse e-mail",
        "subheader": "Laissez votre carte-cadeau à la maison et indiquez votre adresse e-mail au caissier."
      },
      "addEditPhoneNumber": {
        "buttonText": "Enregistrer",
        "header": "Ajouter un numéro de téléphone",
        "subheader": "Laissez votre carte cadeau à la maison et indiquez votre numéro de téléphone au caissier."
      },
      "recipientEmails": {
        "eGiftRecipients": "Destinataires de cartes cadeaux électroniques"
      }
    },
    "pageNotFound": "Nous sommes désolés. Cette page de cartes cadeaux électroniques n’existe plus ou a été désactivée.",
    "pageTitle": {
      "checkBalance": "Vérifier le solde | Cartes cadeaux {merchantName}",
      "default": "Cartes cadeaux électroniques {merchantName}",
      "discover": "Soutenez vos commerces locaux en offrant des cartes cadeaux électroniques Square !",
      "group": "Grouper | Cartes-cadeaux électroniques {merchantName}",
      "linkPhone": "Lier un numéro de téléphone | Cartes cadeaux électroniques {merchantName}",
      "order": "Commander | Cartes cadeaux électroniques {merchantName}",
      "print": "Imprimer | Cartes cadeaux électroniques {merchantName}",
      "reload": "Recharger | Cartes cadeaux électroniques {merchantName}",
      "resend": "Renvoyer | Cartes-cadeaux électroniques {merchantName}"
    },
    "payment": {
      "confirmDetails": "Confirmez les détails de votre commande.",
      "confirmDetailsWithPayment": "Confirmez les détails de votre commande et saisissez vos informations de paiement.",
      "error": {
        "compliance": {
          "dailyLimit": "La commande dépasse le plafond quotidien autorisé pour les cartes cadeaux."
        },
        "paymentDeclined": "Le paiement a été refusé. Veuillez essayer une autre carte bancaire."
      },
      "heroText": "Paiement"
    },
    "paymentForm": {
      "buyerBeware": "En finalisant l’achat de cette carte cadeau, vous acceptez la responsabilité de toute perte associée dans l’éventualité où le vendeur ne serait pas en mesure de fournir les biens ou les services.",
      "disclaimer": {
        "default": "En achetant une carte cadeau électronique, vous acceptez la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Politique de confidentialité</a> et l’<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Accord par consentement électronique</a> de Square.",
        "giveAndGetLocal": "En achetant une carte cadeau électronique, vous acceptez les <a href=\"{localizedTermsUrl}\" target=\"_blank\">Conditions de Give & Get Local</a>, la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Politique de confidentialité</a> et l’<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Accord par consentement électronique</a> de Square."
      },
      "errors": {
        "unauthorized": "Votre carte de paiement n’a pas pu être autorisée."
      },
      "payWithCard": "Payer par carte"
    },
    "paymentInfoForm": {
      "placeOrder": "Passer la commande"
    },
    "printEgift": {
      "themeAltText": "Thème de la carte cadeau électronique"
    },
    "promotion": {
      "applyPromotionCode": "Appliquer",
      "codeInvalid": "Ce code promo n’est pas valide.",
      "customHelpText": "* La remise de {percentage} % s’applique aux achats de {minimumLoad} et plus.",
      "discountCodeApplied": "Le code de réduction « {code} » a été appliqué. ",
      "discountCodeIgnored": "Votre réduction actuelle est plus avantageuse que “{ignoredCode}”.",
      "fixedDiscount": "Réduction de {discount}",
      "fixedDiscountExpires": "Remise de {discount} jusqu’au {expirationDate}",
      "fixedDiscountMinLoad": "Remise de {discount} sur les articles de {minimumLoad} et plus",
      "fixedDiscountMinLoadExpires": "Jusqu’au {expirationDate}, moins {discount} sur les articles à partir de {minimumLoad}.",
      "payDiscountedPrice": "Payer {price}",
      "percentageDiscount": "{percentage}% de réduction",
      "percentageDiscountExpires": "Remise de {percentage} % jusqu’au {expirationDate}",
      "percentageDiscountMaxDiscount": "Remise de {percentage} %  (remise maximale de {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "Moins {percentage} % jusqu’au {expirationDate} (réduction maximum de {maximumDiscount})",
      "percentageDiscountMinLoad": "Moins {percentage} % sur les articles à partir de {minimumLoad}",
      "percentageDiscountMinLoadExpires": "Remise de {percentage} % sur les articles de {minimumLoad} et plus jusqu’au {expirationDate}",
      "promotionCode": "Code promo",
      "promotionCodePlaceholder": "MONCODEDERÉDUCTION",
      "promotionalPricing": "Tarif promotionnel : {description}",
      "youPayPrice": "Vous payez {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Ajoutez une adresse e-mail",
      "addPhoneNumber": "Ajouter un numéro de téléphone",
      "editPhoneNumber": "Modifier"
    },
    "reload": {
      "balance": "Solde de la carte : {balance}",
      "checkBalance": "Vérifier le solde",
      "description": "Ajoutez de l’argent à votre carte cadeau.",
      "discountInput": "Ajouter un code de réduction",
      "promoInput": "Ajouter un code promo",
      "subheader": "Montant à ajouter à la carte",
      "submit": "Continuer"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Recharger les détails"
    },
    "resend": {
      "heroSecondary": "Votre cadeau n’a pas pu être livré. Veuillez corriger l’adresse e-mail du destinataire.",
      "heroText": "Mettez votre adresse e-mail à jour"
    },
    "routeError": {
      "giftCardNotFound": "La carte cadeau n’est pas valide ou n’a pas encore été activée."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " à la carte cadeau. Un SMS de confirmation sera envoyé au {phoneNumber}"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{L’adresse e-mail que vous avez saisie n’est pas valide} other{Une ou plusieurs adresses e-mail saisie(s) ne sont pas valides}}. Veuillez saisir une autre adresse e-mail.",
        "inTotal": {
          "perDay": "Vous ne pouvez pas envoyer plus de 4 cartes cadeaux par jour par SMS. Veuillez plutôt envoyer un e-mail.",
          "perMonth": "Vous ne pouvez pas envoyer plus de 20 cartes cadeaux par mois par SMS. Veuillez plutôt envoyer un e-mail."
        },
        "minAmount": "Le montant des cartes cadeaux électroniques envoyées par SMS doit être supérieur ou égal à 5 €",
        "samePhoneNumber": {
          "perDay": "Vous ne pouvez pas envoyer plus de 2 cartes cadeaux par jour au même numéro de téléphone. Veuillez plutôt envoyer un e-mail.",
          "perMonth": "Vous ne pouvez pas envoyer plus de 4 cartes cadeaux par mois au même numéro de téléphone. Veuillez plutôt envoyer un e-mail."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Numéro de téléphone des contributeurs ({max} personnes maximum, séparées par une virgule)",
        "invalidPhoneNumberValidation": "Numéro de téléphone {phoneNumber} non valide",
        "maxPhoneNumbersValidation": "Chaque commande ne peut pas compter plus de {max} numéros de téléphone",
        "minPhoneNumbersValidation": "Vous devez saisir au moins {min} numéros de téléphone",
        "phoneNumber": "Numéro de téléphone",
        "phoneNumbers": "Numéros de téléphone (séparés par une virgule)",
        "phoneNumbersRequiredValidation": "Les numéros de téléphone sont obligatoires",
        "recipientPhoneNumber": "Numéro de téléphone du destinataire",
        "yourPhoneNumber": "Votre numéro de téléphone"
      },
      "giftMessage": {
        "messageFrom": "Message de {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Numéro de téléphone non disponible"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Votre nom, votre numéro de téléphone et votre message seront communiqués au destinataire."
      },
      "legalDisclaimer": "En sélectionnant «°Passage en caisse°», vous acceptez que nous envoyions un SMS unique au numéro de téléphone portable indiqué ci-dessus afin de livrer votre carte cadeau électronique. Contactez {sellerName} directement pour en savoir plus sur ses pratiques en matière de confidentialité. Des frais peuvent s’appliquer pour les messages et les données.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Ajouter un numéro de téléphone",
          "subheader": "Laissez votre carte cadeau à la maison et indiquez votre numéro de téléphone au caissier."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "Destinataires de cartes cadeaux électroniques"
        }
      },
      "resend": {
        "buttonText": "Renvoyer la carte cadeau électronique",
        "heroSecondary": "Échec de la livraison de votre cadeau. Veuillez mettre à jour les coordonnées du destinataire.",
        "heroText": "Mettre à jour les informations de livraison"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Envoyer par numéro de téléphone"
        }
      },
      "todayAt": "Aujourd’hui à {time}",
      "todayInstantly": "Aujourd’hui (tout de suite)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Numéro de téléphone du destinataire",
        "confirmationValidation": "Les numéros de téléphone ne concordent pas.",
        "originalContactInfo": "Coordonnées d’origine",
        "originalPhoneNumber": "Numéro de téléphone d’origine",
        "phoneNumberUnavailable": "Numéro de téléphone non disponible",
        "recipientPhoneNumber": "Numéro de téléphone du destinataire"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Renseignez votre numéro de téléphone lors du passage en caisse.",
        "phone": "Numéro de téléphone"
      }
    },
    "shareGroupGift": {
      "shareLink": "Partager le lien",
      "shareWithOthers": "Partagez le lien avec les autres pour qu‘ils puissent participer au cadeau commun."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Envoyer par e-mail",
        "sendByText": "Envoyer par SMS"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Envoyer à plusieurs destinataires",
      "sendToOne": "Envoyer à un destinataire"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Adresse e-mail du destinataire",
      "confirmationValidation": "Les adresses e-mail ne correspondent pas.",
      "emailAddressUnavailable": "Adresse e-mail non disponible",
      "originalEmail": "E-mail initial",
      "recipientEmailAddress": "Adresse e-mail du destinataire",
      "updateOrder": "Mise à jour de la commande"
    },
    "view": {
      "addMoreFunds": "Ajouter des fonds",
      "transactionHistory": "Historique des transactions",
      "waysToRedeem": "Échanges"
    },
    "viewPageHeader": {
      "currentBalance": "Solde actuel",
      "greeting": "Bonjour {recipientName}, {senderName} vous a envoyé une carte cadeau électronique !",
      "groupGreeting": "Un cadeau pour {recipientName} !",
      "groupViewGreeting": "Bonjour {recipientName}, vos amis vous ont envoyé une carte cadeau électronique !"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Ajouter à votre profil Square",
      "barCode": "Code-barres",
      "code": "Code",
      "codeInstructions": "Présentez ce code au caissier.",
      "email": "E-mail",
      "giftCardCode": "Code de la carte cadeau",
      "instructions": "Scannez ou saisissez le code pour utiliser la carte cadeau.",
      "methodChoiceSeparator": "ou",
      "otherHeader": "Autres façons d’utiliser en magasin",
      "otherInstructions": "Donnez votre numéro de téléphone ou votre adresse e-mail au moment de payer.",
      "otherInstructionsOnlyEmail": "Indiquez votre adresse e-mail au moment de payer.",
      "phone": "Numéro de téléphone",
      "phoneActionText": "Changer le numéro de téléphone associé à la carte",
      "qrCode": "Code QR",
      "redeemOnline": "Utiliser en ligne",
      "redeemOnlineInstructions": "Saisissez le code de la carte cadeau au moment du paiement. ",
      "squareProfileDescription": "Le profil Square vous aide à afficher et à gérer toutes les cartes cadeaux de vos différentes entreprises Square."
    }
  }], ["fr-ca", {
    "addressInput": {
      "loading": "Chargement en cours...",
      "noResults": "Aucun résultat."
    },
    "businessCategory": {
      "beautyAndPersonalcare": "Beauté et soins",
      "charitiesEducationAndMembership": "Organisme caritatif, éducatif ou associatif",
      "foodAndDrink": "Alimentation et boissons",
      "healthCareAndFitness": "Santé et mise en forme",
      "homeAndRepair": "Réparations à domicile",
      "leisureAndEntertainment": "Loisirs et divertissement",
      "other": "Autre",
      "professionalServices": "Services professionnels",
      "retail": "Commerces de détail",
      "transportation": "Transports"
    },
    "businessCategoryFilters": {
      "hideFilters": "Masquer les filtres",
      "showFilters": "Afficher les filtres"
    },
    "checkBalance": {
      "checkboxLabel": "Ma carte cadeau n’a pas de NIP",
      "description": "Saisissez votre numéro de carte cadeau afin de vérifier votre solde.",
      "errors": {
        "notFound": "Cette carte cadeau est introuvable.",
        "rateLimit": "Trop de tentatives. Veuillez réessayer dans quelques instants."
      },
      "label": "Numéro de carte cadeau",
      "pinLabel": "NIP",
      "submit": "Vérifier le solde",
      "tooltip": "Le NIP se situe au verso de la carte cadeau, à côté du numéro de la carte cadeau.",
      "tooltipPinRequired": "Le NIP se situe au verso de la carte cadeau, à côté du numéro de la carte. Si votre carte cadeau n’a pas de NIP, veuillez contacter le commerçant pour vérifier votre solde."
    },
    "clipboard": {
      "copyLink": "Copier le lien",
      "linkCopied": "Lien copié!"
    },
    "common": {
      "edit": "Modifier",
      "next": "Suivant",
      "unknownError": "Une erreur inconnue s’est produite. Veuillez réessayer plus tard."
    },
    "confirmLink": {
      "heroSecondary": "Indiquez au caissier votre numéro de téléphone.",
      "heroText": "Il suffit de le présenter à la caisse.",
      "seeBalanceButton": "Afficher le solde de la carte cadeau"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "Votre commande de {quantity} cyber cartes cadeaux de {amount} sera envoyée le {formattedDate}.",
          "instantDescription": "Votre commande de {quantity} cyber cartes cadeaux de {amount} est en route."
        },
        "singleRecipient": {
          "futureDescription": "Votre commande de {quantity} cyber cartes cadeaux de {amount} sera envoyée à {recipientEmail} le {formattedDate}.",
          "instantDescription": "Votre commande de {quantity} cyber cartes cadeaux de {amount} est en route vers {recipientEmail}."
        }
      },
      "continueShopping": "Poursuivre les achats",
      "giftFutureDescription": "Votre cyber carte cadeau de {amount} sera envoyée à {recipientName} ({recipientContact}) le {formattedDate}.",
      "giftInstantDescription": "Votre cyber carte cadeau de {amount} a été envoyée à {recipientName} ({recipientContact}).",
      "groupContribution": {
        "back": "Retour au cadeau de groupe"
      },
      "groupOrder": {
        "confirmationMessageEnd": "à la carte cadeau. Une confirmation par courriel sera envoyée à {emailAddress}",
        "confirmationMessageStart": "Vous avez ajouté",
        "groupGiftStarted": "Cadeau de groupe créé!",
        "viewGroupGift": "Afficher le cadeau de groupe"
      },
      "orderComplete": "Commande finalisée",
      "orderFutureDescription": "Votre bon de réduction de {amount} sera envoyé à {recipientName} ({recipientContact}) le {formattedDate}.",
      "orderInstantDescription": "Votre bon de réduction de {amount} a été envoyé à {recipientName} ({recipientContact}).",
      "orderUpdated": "Commande mise à jour",
      "reloadComplete": "Recharge complétée",
      "sendAnother": "Envoyer une autre cyber carte cadeau",
      "sendAnotherGift": "Envoyer un autre cadeau"
    },
    "date": {
      "formatPlaceholder": "AAAA-MM-JJ",
      "nextMonth": "Le mois prochain",
      "previousMonth": "Mois précédent"
    },
    "discount": {
      "codeInvalid": "Ce code de remise n’est pas valide.",
      "discountCode": "Code de remise",
      "discountPricing": "Prix réduit : {description}"
    },
    "discover": {
      "filters": {
        "filterBy": "Filtrer par"
      },
      "footer": {
        "aProjectBy": "Un projet de"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "L’achat local à cœur"
        },
        "description": {
          "giftCards": "Les entreprises locales ont besoin de votre soutien. Vous pouvez encourager vos commerçants Square préférés en achetant des cartes cadeaux électroniques.",
          "voucher": "Les entreprises locales ont besoin de votre soutien. Encouragez vos commerçants Square préférés en achetant des bons d’échange à offrir en cadeaux ou à conserver pour votre utilisation personnelle."
        },
        "giftCardAsteriskAltText": "Carte cadeau avec modèle astérisque coloré"
      },
      "hero": {
        "subtitle": "Achetez une cyber carte-cadeau maintenant, utilisez-la plus tard et aidez une de vos entreprises locales préférées.",
        "title": "Soutenez vos entreprises préférées"
      },
      "locationSelector": {
        "currentLocation": "Point de vente actuel",
        "label": "Trouvez un vendeur Square près de chez vous",
        "placeholder": "Saisir l’adresse ou la ville",
        "separator": "ou",
        "useCurrentLocation": "Utiliser mon point de vente actuel"
      },
      "merchantCard": {
        "buyButton": "Acheter une carte cadeau"
      },
      "results": {
        "noResults": "Aucun résultat"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "Merci de votre soutien! Aidez-nous à faire passer le message."
        },
        "notFromDirectory": {
          "description": "Découvrez L’achat local à cœur afin de trouver les entreprises offrant des cyber cartes-cadeaux, la cueillette, la livraison et plus encore.",
          "title": "Soutenez vos entreprises préférées"
        },
        "shareOnFacebook": "Partager sur Facebook",
        "shareOnTwitter": "Partager sur Twitter"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "Sélecteur de thème de carte cadeau",
      "carouselRole": "carrousel\n",
      "customImageFallback": "Image personnalisée du vendeur",
      "nextButtonDescription": "Prochain thème",
      "previousButtonDescription": "Thème précédent",
      "slideRole": "diapositive"
    },
    "errors": {
      "invalidEmail": "L’adresse courriel ne semble pas valide.",
      "invalidPhone": "Ce numéro de téléphone ne semble pas être un numéro valide.",
      "requiredField": "Il s’agit d’un champ obligatoire."
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "Conditions de L’achat local à cœur",
      "privacyPolicy": "Politique de confidentialité",
      "termsOfService": "Conditions d’utilisation",
      "text": "Optimisé par <a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Cartes cadeaux Square</a>"
    },
    "form": {
      "continue": "Continuer",
      "failedLabel": "Refusée",
      "optionalLabel": "Facultatif"
    },
    "giftInfoForm": {
      "addPersonalMessage": "Ajouter un message personnalisé",
      "amount": "Montant",
      "contributionAmount": "Montant de la contribution",
      "contributorEmailAddresses": "Adresses courriel des contributeurs ({max} personnes maximum, séparées par une virgule)",
      "custom": "Autre",
      "customAmount": "Autre montant",
      "customAmountBoundsValidation": "Le montant de la carte-cadeau électronique doit être compris entre {min} et {max}.",
      "customAmountPlaceholder": "{min} à {max}",
      "customAmountRequiredValidation": "Veuillez enter un montant de cyber carte-cadeau  personnalisé ou sélectionner une option.",
      "delivery": "Livraison",
      "deliveryDate": "Date de livraison",
      "deliveryDateValidation": "Veuillez saisir une date survenant dans les 60 prochains jours.",
      "deliveryDetailsHeader": "Détails sur la livraison",
      "deliveryTime": "Heure de livraison",
      "eGiftCardAmount": "Montant de la cyber carte cadeau ",
      "emailAddress": "Adresse courriel",
      "emailAddresses": "Adresses courriel (séparées par une virgule)",
      "emailAddressesRequiredValidation": "Les adresses courriel sont obligatoires",
      "giftTypeHeader": "De quel type de cadeau s’agit-il?",
      "groupDesc": "Mise en commun de l’argent de différents contributeurs pour un destinataire",
      "groupHeader": "Carte cadeau de groupe",
      "individualDesc": "Envoyer une carte cadeau à un destinataire",
      "individualDescWithBulk": "Envoyer une carte cadeau à un destinataire ou effectuer un achat en vrac",
      "individualHeader": "Pour un particulier",
      "invalidEmailAddressValidation": "Adresse courriel non valide {email}",
      "invitationMessage": "Message d’invitation",
      "invitationMessageTooLong": "Le message d’invitation ne doit pas contenir plus de 400 caractères.",
      "inviteContributorsHeader": "Inviter des contributeurs (facultatif)",
      "inviteContributorsSubtitle": "Envoyez un courriel pour inviter d’autres personnes à contribuer à ce cadeau. Vous recevrez également un lien de partage après le passage en caisse.",
      "maxEmailAddressesValidation": "Maximum de {max} adresses courriel dans une seule commande",
      "maxQuantityValidation": "La quantité ne peut pas dépasser {max}.",
      "minEmailAddressesValidation": "Vous devez saisir au moins {min} adresses courriel",
      "multipleDesc": "Envoyer des cartes cadeaux à plusieurs personnes à la fois",
      "multipleHeader": "Pour plusieurs personnes",
      "name": "Nom",
      "personalMessage": "Message personnel",
      "personalMessageOptional": "Message personnel (facultatif)",
      "personalMessageTooLong": "Le message personnel ne doit pas contenir plus de 200 caractères.",
      "quantity": "Quantité",
      "quantityValidation": "La quantité doit se situer entre {min} et {max}",
      "recipientEmail": "Adresse courriel du destinataire",
      "recipientName": "Nom du destinataire",
      "recipientNamePlaceholder": "Nom du destinataire",
      "recipientQuantityPlaceholder": "1 par destinataire",
      "sendAsAGift": "Envoyer ce bon d’échange en cadeau",
      "sendInstantly": "Envoyer tout de suite",
      "sendOnAFutureDate": "Envoyer plus tard",
      "sendToMyself": "M’envoyer cette carte",
      "sendToSelf": "Je veux m’envoyer ceci à moi-même",
      "suggestedContributionAmount": "Montant suggéré pour la contribution",
      "timeZone": "Fuseau horaire",
      "yourDetailsHeader": "Vos détails",
      "yourEmail": "Votre courriel",
      "yourEmailAddress": "Votre adresse courriel",
      "yourName": "Votre nom",
      "yourNamePlaceholder": "Votre nom"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "Envoyer instantanément un cadeau de la valeur que vous voulez",
        "voucher": "Procurez-vous un bon d’échange pour vous-même ou un ami"
      },
      "heroText": "Offrir le cadeau idéal"
    },
    "giftMessage": {
      "custom": "&ldquo;{customMessage}&rdquo;",
      "from": "De",
      "messageFrom": "Message de {senderEmail}",
      "nameNotAvailable": "nom introuvable",
      "to": "À"
    },
    "giftSummarySidebar": {
      "customMessage": "&ldquo;{customMessage}&rdquo;",
      "deliveryFailed": "Échec de la livraison : {recipient}",
      "discountsApplied": "Remise appliquée",
      "emailAddressUnavailable": "Adresse courriel introuvable",
      "multipleRecipients": "{firstRecipientEmail} + {numberAdditionalRecipientEmails} de plus",
      "newBalance": "Le nouveau solde sera : {newBalance}",
      "orderDetails": "Détails de la commande",
      "payAmount": "Montant que vous payez",
      "promotionApplied": "Promotion appliquée",
      "valueDoesNotExpire": "La valeur n’a pas de date d’expiration.",
      "viewAllRecipients": "Voir tous les destinataires"
    },
    "group": {
      "giftDetailsHeader": "Détails du cadeau",
      "loadFunds": "Charger des fonds",
      "organizedBy": "Organisé par",
      "suggestedContributionDesc": "L’organisateur a suggéré une contribution de {suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "Votre nom, votre adresse courriel et votre message seront communiqués au destinataire.",
      "header": "Collaborateurs",
      "viewAll": "Tout afficher"
    },
    "header": {
      "checkBalanceLink": "Besoin de vérifier le solde de votre carte cadeau?",
      "logoAltText": "Logo {merchantName}",
      "orderPageLink": "Commander une cyber carte cadeau"
    },
    "headerV2": {
      "checkBalanceLink": "Vérifier le solde",
      "logoAltText": "Logo {merchantName}",
      "orderPageLink": "Acheter une carte cadeau",
      "reloadPageLink": "Recharger la carte"
    },
    "historyEvent": {
      "eventType": {
        "clear": "Supprimer",
        "deduct": "Fonds déduits",
        "load": "Fonds chargés",
        "redeem": "Achat",
        "refund": "Remboursement",
        "transferBalanceFrom": "Transfert vers {giftCardDisplay}",
        "transferBalanceTo": "Transfert à partir de {giftCardDisplay}",
        "unknown": "Inconnu"
      }
    },
    "link": {
      "heroSecondary": "Configurez un numéro de téléphone pour votre carte.",
      "heroText": "Vous n’avez pas à avoir en main votre carte cadeau.",
      "noThanks": "Non merci. J’emploierai le code à 16 chiffres.",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "Une erreur s’est produite lors de la duplication du contenu de la facture."
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "Acheter en ligne"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "Enregistrer",
        "header": "Ajouter une adresse courriel",
        "subheader": "Laissez votre carte cadeau chez vous et donnez votre adresse courriel au caissier."
      },
      "addEditPhoneNumber": {
        "buttonText": "Enregistrer",
        "header": "Ajouter un numéro de téléphone",
        "subheader": "Laissez votre carte cadeau chez vous et donnez votre numéro de téléphone au caissier."
      },
      "recipientEmails": {
        "eGiftRecipients": "Destinataires de cyber cartes cadeaux"
      }
    },
    "pageNotFound": "Nous sommes désolés. Cette page de cyber cartes-cadeaux n’existe plus ou a été désactivée.",
    "pageTitle": {
      "checkBalance": "Vérifier le solde | Cartes cadeaux {merchantName}",
      "default": "Cyber cartes cadeaux {merchantName}",
      "discover": "Soutenez vos entreprises locales aujourd’hui grâce aux cyber cartes-cadeaux Square!",
      "group": "Groupe | Cyber cartes cadeaux de {merchantName}",
      "linkPhone": "Lier un numéro de téléphone | Cyber cartes cadeaux {merchantName}",
      "order": "Commander | Cyber cartes cadeaux {merchantName}",
      "print": "Imprimer | Cyber cartes cadeaux {merchantName}",
      "reload": "Recharger | Cartes-cadeaux électroniques {merchantName}",
      "resend": "Renvoyer | Cyber cartes cadeaux {merchantName}"
    },
    "payment": {
      "confirmDetails": "Confirmer les détails de votre commande",
      "confirmDetailsWithPayment": "Confirmez les détails de votre commande et entrez les renseignements de facturation.",
      "error": {
        "compliance": {
          "dailyLimit": "La commande excède le plafond quotidien des exigences pour les cartes cadeaux."
        },
        "paymentDeclined": "Le paiement a été refusé. Veuillez saisir un autre numéro de carte de crédit."
      },
      "heroText": "Passer la commande"
    },
    "paymentForm": {
      "buyerBeware": "En finalisant cet achat, je reconnais être responsable de toute perte associée à l’achat de la carte-cadeau dans l’éventualité où le vendeur ne puisse livrer les biens ou les services.",
      "disclaimer": {
        "default": "Lorsque vous achetez une cyber carte cadeau, vous acceptez la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">politique de confidentialité</a> et l’<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">accord par consentement électronique</a> de Square.",
        "giveAndGetLocal": "À l’achat d’une cyber carte cadeau, vous acceptez les <a href=\"{localizedTermsUrl}\" target=\"_blank\">Conditions de L’achat local à cœur</a>, la <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">Politique de confidentialité</a> et l’<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Accord par consentement électronique</a> de Square."
      },
      "errors": {
        "unauthorized": "Votre paiement par carte n’a pu être autorisé"
      },
      "payWithCard": "Payer avec une carte"
    },
    "paymentInfoForm": {
      "placeOrder": "Passer la commande"
    },
    "printEgift": {
      "themeAltText": "Thème de cyber carte cadeau"
    },
    "promotion": {
      "applyPromotionCode": "Appliquer",
      "codeInvalid": "La promotion n’est pas valide.",
      "customHelpText": "* La remise de {percentage} % s’applique aux achats de {minimumLoad} et plus.",
      "discountCodeApplied": "Le code de remise \"{code}\" a été appliqué. ",
      "discountCodeIgnored": "Votre remise actuelle est plus avantageuse que “{ignoredCode}”.",
      "fixedDiscount": "Remise de {discount}",
      "fixedDiscountExpires": "Remise de {discount} jusqu’au {expirationDate}",
      "fixedDiscountMinLoad": "Remise de {discount} sur les articles de {minimumLoad} et plus",
      "fixedDiscountMinLoadExpires": "Jusqu’au {expirationDate}, remise de {discount} sur les articles à partir de {minimumLoad}.",
      "payDiscountedPrice": "Payer {price}",
      "percentageDiscount": "Remise de {percentage}%",
      "percentageDiscountExpires": "Remise de {percentage} % jusqu’au {expirationDate}",
      "percentageDiscountMaxDiscount": "Remise de {percentage} %  (remise maximale de {maximumDiscount})",
      "percentageDiscountMaxDiscountExpires": "Remise de {percentage} % jusqu’au {expirationDate} (réduction maximum de {maximumDiscount})",
      "percentageDiscountMinLoad": "Remise de {percentage} % sur les articles à partir de {minimumLoad}",
      "percentageDiscountMinLoadExpires": "Remise de {percentage} % sur les articles de {minimumLoad} et plus jusqu’au {expirationDate}",
      "promotionCode": "Code de promotion",
      "promotionCodePlaceholder": "MONCODEDEREMISE",
      "promotionalPricing": "Tarif promotionnel : {description}",
      "youPayPrice": "Vous payez {price}."
    },
    "redemptionMethod": {
      "addEmailAddress": "Ajouter une adresse courriel",
      "addPhoneNumber": "Ajouter un numéro de téléphone",
      "editPhoneNumber": "Modifier"
    },
    "reload": {
      "balance": "Solde de la carte : {balance}",
      "checkBalance": "Vérifier le solde",
      "description": "Ajoutez de l’argent à votre carte cadeau.",
      "discountInput": "Ajouter un code de remise",
      "promoInput": "Ajouter un code promotionnel",
      "subheader": "Montant à ajouter à la carte",
      "submit": "Continuer"
    },
    "reloadSummarySidebar": {
      "orderDetails": "Recharger les détails"
    },
    "resend": {
      "heroSecondary": "Votre cadeau n’a pas pu être livré. Veuillez mettre à jour l’adresse courriel du destinataire.",
      "heroText": "Mettre à jour l’adresse courriel"
    },
    "routeError": {
      "giftCardNotFound": "La carte cadeau n’est pas valide ou n’a pas été activée."
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": " pour la carte cadeau. Un texto de confirmation sera envoyé au {phoneNumber}."
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{L’adresse courriel saisie n’est pas valide} other{Une ou plusieurs adresses courriel saisies ne sont pas valides}}. Veuillez saisir une autre adresse courriel.",
        "inTotal": {
          "perDay": "Vous ne pouvez pas envoyer plus de 4 cartes cadeaux par jour par texto. Veuillez plutôt l’envoyer par courriel.",
          "perMonth": "Vous ne pouvez pas envoyer plus de 20 cartes cadeaux par mois par texto. Veuillez plutôt l’envoyer par courriel."
        },
        "minAmount": "Les cyber cartes cadeaux envoyées par texto doivent avoir une valeur de 5 $ ou plus.",
        "samePhoneNumber": {
          "perDay": "Vous ne pouvez pas envoyer plus de 2 cartes cadeaux par jour au même numéro de téléphone. Veuillez plutôt l’envoyer par courriel.",
          "perMonth": "Vous ne pouvez pas envoyer plus de 4 cartes cadeaux par mois au même numéro de téléphone. Veuillez plutôt l’envoyer par courriel."
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "Numéro de téléphone des contributeurs ({max} personnes maximum, séparées par des virgules)",
        "invalidPhoneNumberValidation": "{phoneNumber}, numéro de téléphone non valide",
        "maxPhoneNumbersValidation": "Chaque commande peut contenir jusqu’à {max} numéros de téléphone.",
        "minPhoneNumbersValidation": "Vous devez saisir au moins {min} numéros de téléphone",
        "phoneNumber": "Numéro de téléphone",
        "phoneNumbers": "Numéros de téléphone (séparés par des virgules)",
        "phoneNumbersRequiredValidation": "Les numéros de téléphone sont requis",
        "recipientPhoneNumber": "Numéro de téléphone du destinataire",
        "yourPhoneNumber": "Votre numéro de téléphone"
      },
      "giftMessage": {
        "messageFrom": "Message du {senderPhoneNumber}"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "Numéro de téléphone non disponible"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "Votre nom, votre numéro de téléphone et votre message seront partagés avec le destinataire."
      },
      "legalDisclaimer": "En sélectionnant Passage en caisse, vous acceptez que nous envoyions un texto unique au numéro de téléphone cellulaire indiqué ci-dessus afin de livrer votre cyber carte cadeau. Contactez directement {sellerName} pour en savoir plus sur ses pratiques de confidentialité. Des frais de messagerie et de données s’appliquent.",
      "modal": {
        "addEditPhoneNumber": {
          "header": "Ajouter un numéro de téléphone",
          "subheader": "Laissez votre carte cadeau chez vous et donnez votre numéro de téléphone au caissier."
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "Destinataires de cyber cartes cadeaux"
        }
      },
      "resend": {
        "buttonText": "Renvoyer la cyber carte cadeau",
        "heroSecondary": "La livraison de votre cadeau a échoué. Veuillez modifier les coordonnées du destinataire.",
        "heroText": "Modifier les détails de livraison"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "Envoyer par numéro de téléphone"
        }
      },
      "todayAt": "Aujourd’hui à {time}",
      "todayInstantly": "Aujourd’hui (tout de suite)",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "Numéro de téléphone du destinataire",
        "confirmationValidation": "Les numéros de téléphone ne concordent pas.",
        "originalContactInfo": "Coordonnées initiales",
        "originalPhoneNumber": "Numéro de téléphone initial",
        "phoneNumberUnavailable": "Numéro de téléphone non disponible",
        "recipientPhoneNumber": "Numéro de téléphone du destinataire"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "Saisissez le numéro de téléphone utilisé lors du passage en caisse.",
        "phone": "Numéro de téléphone"
      }
    },
    "shareGroupGift": {
      "shareLink": "Partager le lien",
      "shareWithOthers": "Partagez le lien avec d’autres pour qu’ils puissent prendre part au cadeau de groupe."
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "Envoyer par courriel",
        "sendByText": "Envoyer par texto"
      }
    },
    "tabPanel": {
      "sendToMultiple": "Envoyer à plusieurs destinataires",
      "sendToOne": "Envoyer à un destinataire"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "Adresse courriel du destinataire",
      "confirmationValidation": "Les courriels ne concordent pas.",
      "emailAddressUnavailable": "adresse courriel introuvable",
      "originalEmail": "Adresse courriel initiale",
      "recipientEmailAddress": "Adresse courriel du destinataire",
      "updateOrder": "Mettre à jour votre commande"
    },
    "view": {
      "addMoreFunds": "Ajouter des fonds",
      "transactionHistory": "Historique des transactions",
      "waysToRedeem": "Comment échanger"
    },
    "viewPageHeader": {
      "currentBalance": "Solde actuel",
      "greeting": "Bonjour {recipientName}, {senderName} vous a envoyé une cyber carte cadeau!",
      "groupGreeting": "Un cadeau pour {recipientName}!",
      "groupViewGreeting": "Bonjour {recipientName}, vos amis vous ont envoyé une cyber carte cadeau!"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Ajouter à votre profil Square",
      "barCode": "Code à barres",
      "code": "Code",
      "codeInstructions": "Présentez ce code au caissier.",
      "email": "Courriel",
      "giftCardCode": "Code de la carte-cadeau",
      "instructions": "Lisez ou saisissez le code pour utiliser la carte cadeau.",
      "methodChoiceSeparator": "ou",
      "otherHeader": "Autres façons d’utiliser en magasin",
      "otherInstructions": "Donnez votre numéro de téléphone ou votre adresse courriel au moment de payer.",
      "otherInstructionsOnlyEmail": "Donnez votre adresse courriel au moment de payer.",
      "phone": "Numéro de téléphone",
      "phoneActionText": "Modifier le numéro de téléphone associé à la carte",
      "qrCode": "Code QR",
      "redeemOnline": "Utiliser le code en ligne",
      "redeemOnlineInstructions": "Saisissez le code de votre carte cadeau lors de votre passage en caisse.",
      "squareProfileDescription": "Le profil Square vous permet d’afficher et de gérer toutes vos cartes cadeaux provenant de différentes entreprises Square."
    }
  }], ["ja", {
    "addressInput": {
      "loading": "読み込み中...",
      "noResults": "検索結果がありません。"
    },
    "businessCategory": {
      "beautyAndPersonalcare": "美容関連サービス",
      "charitiesEducationAndMembership": "教育、非営利、会員制団体",
      "foodAndDrink": "飲食",
      "healthCareAndFitness": "医療サービス",
      "homeAndRepair": "住宅、修理サービス",
      "leisureAndEntertainment": "イベント・エンターテイメント",
      "other": "その他",
      "professionalServices": "専門サービス",
      "retail": "小売",
      "transportation": "運輸、タクシー関連サービス"
    },
    "businessCategoryFilters": {
      "hideFilters": "フィルターを非表示",
      "showFilters": "フィルターを表示"
    },
    "checkBalance": {
      "checkboxLabel": "ギフトカードにPINの記載がありません",
      "description": "ギフトカード番号を入力すると残高が確認できます。",
      "errors": {
        "notFound": "該当するギフトカードは見つかりませんでした。",
        "rateLimit": "試行回数が多すぎます。しばらくしてからもう一度お試しください。"
      },
      "label": "ギフトカード番号",
      "pinLabel": "PIN",
      "submit": "残高を確認",
      "tooltip": "PINは、ギフトカードの裏面にあるギフトカード番号の隣に記載されています。",
      "tooltipPinRequired": "PINは、ギフトカードの裏面にあるギフトカード番号の隣に記載されています。ギフトカードにPINの記載がない場合は、加盟店に連絡して残高を確認してください。"
    },
    "clipboard": {
      "copyLink": "リンクをコピー",
      "linkCopied": "リンクをコピーしました！"
    },
    "common": {
      "edit": "編集",
      "next": "次へ",
      "unknownError": "不明なエラーが発生しました。後でやり直してください。"
    },
    "confirmLink": {
      "heroSecondary": "また、レジ担当者に電話番号をお伝えください。",
      "heroText": "お会計時にのみ表示",
      "seeBalanceButton": "ギフトカードの残高を確認する"
    },
    "confirmation": {
      "bulkOrder": {
        "multipleRecipients": {
          "futureDescription": "{quantity} x {amount}分のeギフトカードのご注文を{formattedDate}に送信します。",
          "instantDescription": "{quantity} x {amount}分のeギフトカードのご注文を送信しました。"
        },
        "singleRecipient": {
          "futureDescription": "{quantity} x {amount}分のeギフトカードのご注文を{formattedDate}に{recipientEmail}宛に送信します。",
          "instantDescription": "{quantity} x {amount}分のeギフトカードのご注文を{recipientEmail}宛に送信しました。"
        }
      },
      "continueShopping": "ショッピングを続ける",
      "giftFutureDescription": "{amount}分のeギフトカードを、{formattedDate}に{recipientName}さま（{recipientContact}）に送信します。",
      "giftInstantDescription": "{amount}分のeギフトカードを{recipientName}さま（{recipientContact}）に送信しました。",
      "groupContribution": {
        "back": "グループギフトに戻る"
      },
      "groupOrder": {
        "confirmationMessageEnd": "ギフトカードに追加しました。確認のメールが{emailAddress}宛に送信されます。",
        "confirmationMessageStart": "追加しました",
        "groupGiftStarted": "グループギフトを開始しました！",
        "viewGroupGift": "グループギフトを表示する"
      },
      "orderComplete": "注文完了",
      "orderFutureDescription": "{amount}分の割引券を、{formattedDate}に{recipientName}さま（{recipientContact}）に送信します。",
      "orderInstantDescription": "{amount}分の割引券を{recipientName}さま（{recipientContact}）に送信しました。",
      "orderUpdated": "注文内容が更新されました",
      "reloadComplete": "チャージが完了しました",
      "sendAnother": "別のeギフトカードを送信する",
      "sendAnotherGift": "別のギフトを送信する"
    },
    "date": {
      "formatPlaceholder": "YYYY/MM/DD",
      "nextMonth": "次月",
      "previousMonth": "前月"
    },
    "discount": {
      "codeInvalid": "ディスカウントコードが正しくありません。",
      "discountCode": "ディスカウントコード",
      "discountPricing": "ディスカウント料金：{description}"
    },
    "discover": {
      "filters": {
        "filterBy": "フィルター"
      },
      "footer": {
        "aProjectBy": "運営会社"
      },
      "giveAndGetLocal": {
        "banner": {
          "title": "好きなあのお店の「みらい優待券」を手に入れよう"
        },
        "description": {
          "giftCards": "地元のビジネスはあなたのサポートを必要としています。デジタルギフトカードを購入することで、お気に入りのSquareの加盟店に力を貸すことができます。",
          "voucher": "さまざまなお店が今苦しい状況におかれています。応援する気持ちを込めて、将来使える「みらい優待券」を好きなお店や地元のお店でオンライン上で購入しませんか。"
        },
        "giftCardAsteriskAltText": "カラフルなアスタリスクデザインのギフトカード"
      },
      "hero": {
        "subtitle": "今すぐeギフトカードを購入して、後でお気に入りの地元のお店をサポートしましょう。",
        "title": "お気に入りのビジネスをサポート"
      },
      "locationSelector": {
        "currentLocation": "現在地",
        "label": "応援したいお店を検索",
        "placeholder": "市町村名を入力してください",
        "separator": "または",
        "useCurrentLocation": "現在地から検索"
      },
      "merchantCard": {
        "buyButton": "ギフトカードを購入"
      },
      "results": {
        "noResults": "検索結果がありません"
      },
      "shareWidget": {
        "fromDirectory": {
          "title": "日頃のご愛顧、誠にありがとうございます。口コミで広げるお手伝いをお願いします。"
        },
        "notFromDirectory": {
          "description": "eギフトカード、受け取り、配達などを提供する企業を探すには、ローカルビジネスの提供支援をご覧ください。",
          "title": "お気に入りのビジネスをサポート"
        },
        "shareOnFacebook": "Facebookでシェアする",
        "shareOnTwitter": "Twitterでシェアする"
      }
    },
    "egiftThemeSelector": {
      "carouselDescription": "ギフトカードテーマのセレクター",
      "carouselRole": "カルーセル",
      "customImageFallback": "加盟店のカスタム画像",
      "nextButtonDescription": "次回のテーマ",
      "previousButtonDescription": "前回のテーマ",
      "slideRole": "スライド"
    },
    "errors": {
      "invalidEmail": "有効なメールアドレスではないようです。",
      "invalidPhone": "有効な電話番号ではないようです。",
      "requiredField": "必須項目"
    },
    "footer": {
      "copyright": "&copy; {year} Square, Inc.",
      "giveGetLocalTerms": "利用規約",
      "privacyPolicy": "個人情報保護方針",
      "termsOfService": "利用規約",
      "text": "<a href=\"{localizedGiftcardsLink}\" target=\"_blank\">Squareギフトカード</a>のシステムを使用しています"
    },
    "form": {
      "continue": "続行",
      "failedLabel": "送信失敗",
      "optionalLabel": "オプション"
    },
    "giftInfoForm": {
      "addPersonalMessage": "メッセージを入力",
      "amount": "金額",
      "contributionAmount": "金額",
      "contributorEmailAddresses": "共同購入者のメールアドレス（最大{max}人、カンマで区切る）",
      "custom": "カスタム",
      "customAmount": "任意の金額",
      "customAmountBoundsValidation": "eギフトカードの金額は、{min}～{max}にしてください。",
      "customAmountPlaceholder": "{min}～{max}",
      "customAmountRequiredValidation": "eギフトカードの任意の金額を入力するか、オプションを選択してください。",
      "delivery": "お届け日",
      "deliveryDate": "配送日",
      "deliveryDateValidation": "60日以内の日付を入力してください。",
      "deliveryDetailsHeader": "配送の詳細",
      "deliveryTime": "配送時間",
      "eGiftCardAmount": "eギフトカードの金額",
      "emailAddress": "メールアドレス",
      "emailAddresses": "メールアドレス（コンマ区切り）",
      "emailAddressesRequiredValidation": "メールアドレスが必要です",
      "giftTypeHeader": "ギフトの種類を入力してください。",
      "groupDesc": "ギフトカードを複数人で共同購入してひとつの受取先に送信する",
      "groupHeader": "グループギフトカード",
      "individualDesc": "ギフトカードをひとつの受取先に送信する",
      "individualDescWithBulk": "ギフトカードをひとつの受取先に送信する、または一括購入する",
      "individualHeader": "個人向け",
      "invalidEmailAddressValidation": "メールアドレス{email}は正しくありません",
      "invitationMessage": "招待メールのメッセージ",
      "invitationMessageTooLong": "招待メールのメッセージの文字数は、最大400文字です。",
      "inviteContributorsHeader": "共同購入者を招待する（オプション）",
      "inviteContributorsSubtitle": "このギフトへの参加を依頼するための招待メールを送信します。また、お会計後に共有リンクを取得します。",
      "maxEmailAddressesValidation": "1度の注文で送信できるメールは{max}件以内です",
      "maxQuantityValidation": "数量の上限は{max}です。",
      "minEmailAddressesValidation": "メールアドレスは最低{min}件入力してください",
      "multipleDesc": "複数人にギフトカードをまとめて送信する",
      "multipleHeader": "複数の人向け",
      "name": "名前",
      "personalMessage": "メッセージ欄",
      "personalMessageOptional": "メッセージ（任意）",
      "personalMessageTooLong": "メッセージ欄の文字数は、最大200文字です。",
      "quantity": "数量",
      "quantityValidation": "数量は{min}～{max}で入力してください",
      "recipientEmail": "受取先のメールアドレス",
      "recipientName": "受取先の名前",
      "recipientNamePlaceholder": "受取先の名前",
      "recipientQuantityPlaceholder": "受取人1人につき1つ",
      "sendAsAGift": " このeギフトカードをプレゼントとして送信する",
      "sendInstantly": "今すぐ送信",
      "sendOnAFutureDate": "明日以降の日付で送信",
      "sendToMyself": "自分宛にこのカードを送信する",
      "sendToSelf": "自分宛に送付を希望",
      "suggestedContributionAmount": "1人あたりの金額",
      "timeZone": "タイムゾーン",
      "yourDetailsHeader": "あなたの詳細",
      "yourEmail": "購入者のメールアドレス",
      "yourEmailAddress": "メールアドレス",
      "yourName": "お名前",
      "yourNamePlaceholder": "お名前"
    },
    "giftInfoHeader": {
      "heroSecondary": {
        "gift": "お好きな金額のeギフトカードを今すぐ送信しましょう",
        "voucher": "eギフトカードを自分用に購入したり、お知り合いにプレゼントしよう"
      },
      "heroText": "最高のギフトをプレゼント"
    },
    "giftMessage": {
      "custom": "「{customMessage}」",
      "from": "送信者",
      "messageFrom": "{senderEmail}からのメッセージ",
      "nameNotAvailable": "使用できない名前です",
      "to": "受信者"
    },
    "giftSummarySidebar": {
      "customMessage": "「{customMessage}」",
      "deliveryFailed": "お届けに失敗：{recipient}",
      "discountsApplied": "ディスカウントが適用されました",
      "emailAddressUnavailable": "メールアドレスが無効です",
      "multipleRecipients": "{firstRecipientEmail}とその他{numberAdditionalRecipientEmails}件",
      "newBalance": "最新の残高は{newBalance}です",
      "orderDetails": "注文の詳細",
      "payAmount": "お支払い金額",
      "promotionApplied": "クーポン適用済み",
      "valueDoesNotExpire": "有効期限はありません。",
      "viewAllRecipients": "すべての受取先を表示"
    },
    "group": {
      "giftDetailsHeader": "ギフトの詳細",
      "loadFunds": "金額をチャージする",
      "organizedBy": "主催者",
      "suggestedContributionDesc": "共同購入時の1人あたりの金額：{suggestedContributionAmount}"
    },
    "groupGiftContributors": {
      "disclaimerToContributor": "名前、メールアドレス、メッセージは受信者と共有されます。",
      "header": "共同購入者",
      "viewAll": "すべてを表示"
    },
    "header": {
      "checkBalanceLink": "ギフトカードの残高を確認しますか？",
      "logoAltText": "{merchantName}のロゴ",
      "orderPageLink": "eギフトカードを注文"
    },
    "headerV2": {
      "checkBalanceLink": "残高を確認",
      "logoAltText": "{merchantName}のロゴ",
      "orderPageLink": "ギフトカードを購入",
      "reloadPageLink": "カードにチャージ"
    },
    "historyEvent": {
      "eventType": {
        "clear": "消去",
        "deduct": "代金差し引き済み",
        "load": "金額チャージ済み",
        "redeem": "購入する",
        "refund": "払い戻し",
        "transferBalanceFrom": "{giftCardDisplay}への振込",
        "transferBalanceTo": "{giftCardDisplay}からの振込",
        "unknown": "不明"
      }
    },
    "link": {
      "heroSecondary": "電話番号でカードを設定する",
      "heroText": "ギフトカードを家に忘れてきても安心です。",
      "noThanks": "いいえ、代わりに16桁のコードを入力します。",
      "pageOne": "1",
      "pageTwo": "2",
      "updateEGiftError": "電話番号のアップデート中にエラーが発生しました。"
    },
    "merchantWebsiteLinkButton": {
      "shopOnline": "オンラインでお買い物"
    },
    "modal": {
      "addEditEmailAddress": {
        "buttonText": "保存",
        "header": "メールアドレスを追加",
        "subheader": "ギフトカードはご自宅に置いておき、レジ担当者にメールアドレスをお伝えください。"
      },
      "addEditPhoneNumber": {
        "buttonText": "保存",
        "header": "電話番号を追加",
        "subheader": "ギフトカードはご自宅に置いておき、レジ担当者に電話番号をお伝えください。"
      },
      "recipientEmails": {
        "eGiftRecipients": "eギフトカードの受取先"
      }
    },
    "pageNotFound": "eギフトのページが存在しないか無効になっています。",
    "pageTitle": {
      "checkBalance": "残高を確認 | {merchantName} のギフトカード",
      "default": "{merchantName} のeギフトカード",
      "discover": "今すぐSquare eギフトカードで地元のビジネスをサポートしましょう！",
      "group": "グループ | {merchantName} のeギフトカード",
      "linkPhone": "電話番号をリンク  | {merchantName} のeギフトカード",
      "order": "注文 | {merchantName} のeギフトカード",
      "print": "印刷 | {merchantName} のeギフトカード",
      "reload": "再チャージ | {merchantName} のeギフトカード",
      "resend": "再送信 | {merchantName} のeギフトカード"
    },
    "payment": {
      "confirmDetails": "注文の詳細を確認してください",
      "confirmDetailsWithPayment": "ご注文内容を確認のうえ、お支払い情報を入力してください。",
      "error": {
        "compliance": {
          "dailyLimit": "注文件数が、ギフトカードの日次コンプライアンス制限を超えています。"
        },
        "paymentDeclined": "お支払いが承認されませんでした。別のカードをお使いください。"
      },
      "heroText": "お会計"
    },
    "paymentForm": {
      "buyerBeware": "この購入を完了することにより、加盟店が商品またはサービスを履行できない場合のギフトカード購入に関連する損失について、責任を負うことに同意します。",
      "disclaimer": {
        "default": "eギフトカードを購入することで、Squareの<a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">個人情報保護方針</a>、および<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Eサイン同意書</a>に同意したことになります。",
        "giveAndGetLocal": "eギフトカードを購入することで、Squareの<a href=\"{localizedTermsUrl}\" target=\"_blank\">ローカルビジネス提供支援に関する利用規約</a>、 <a href=\"{localizedPrivacyPolicyUrl}\" target=\"_blank\">個人情報保護方針</a>、および<a href=\"{localizedeSignConsentUrl}\" target=\"_blank\">Eサイン同意書</a>に同意したことになります。"
      },
      "errors": {
        "unauthorized": "お支払いが承認されませんでした"
      },
      "payWithCard": "カードで支払う"
    },
    "paymentInfoForm": {
      "placeOrder": "注文する"
    },
    "printEgift": {
      "themeAltText": "eギフトカードのテーマ"
    },
    "promotion": {
      "applyPromotionCode": "適用",
      "codeInvalid": "クーポンコードが無効です。",
      "customHelpText": "* {percentage}%の割引は{minimumLoad}以上の価格に適用されます。",
      "discountCodeApplied": "クーポンコード「{code}」が適用されました。",
      "discountCodeIgnored": "現在適用されている割引額が、「{ignoredCode}」の割引額より大きいため、このクーポンコードは適用できません。",
      "fixedDiscount": "{discount}割引 ",
      "fixedDiscountExpires": "{expirationDate}まで{discount}割引",
      "fixedDiscountMinLoad": "{minimumLoad}以上の金額の商品を{discount}割引",
      "fixedDiscountMinLoadExpires": "{expirationDate}まで、{minimumLoad}以上の金額の商品を{discount}割引",
      "payDiscountedPrice": "支払金額：{price}",
      "percentageDiscount": "{percentage}%割引",
      "percentageDiscountExpires": "{expirationDate}まで{percentage}%割引",
      "percentageDiscountMaxDiscount": "{percentage}%割引（割引額の上限{maximumDiscount}）",
      "percentageDiscountMaxDiscountExpires": "{expirationDate}まで、{percentage}%割引（割引額の上限{maximumDiscount}）",
      "percentageDiscountMinLoad": "{minimumLoad}以上の金額の商品を{percentage}%割引",
      "percentageDiscountMinLoadExpires": "{expirationDate}まで、{minimumLoad}以上の金額の商品を{percentage}%割引",
      "promotionCode": "クーポンコード",
      "promotionCodePlaceholder": "クーポンコードを入力",
      "promotionalPricing": "プロモーション料金: {description}",
      "youPayPrice": "{price}をお支払いいただきます。"
    },
    "redemptionMethod": {
      "addEmailAddress": "メールアドレスを追加",
      "addPhoneNumber": "電話番号を追加",
      "editPhoneNumber": "編集"
    },
    "reload": {
      "balance": "カード残高: {balance}",
      "checkBalance": "残高を確認",
      "description": "お持ちのギフトカードにチャージ（入金）します。",
      "discountInput": "ディスカウントコードを追加",
      "promoInput": "クーポンコードを追加",
      "subheader": "カードに追加する金額",
      "submit": "続行"
    },
    "reloadSummarySidebar": {
      "orderDetails": "詳細の再読み込み"
    },
    "resend": {
      "heroSecondary": "ギフトカードをお届けできませんでした。受取先のメールアドレスを更新してください。",
      "heroText": "メールアドレスを変更する"
    },
    "routeError": {
      "giftCardNotFound": "ギフトカードが無効であるか、まだ有効化されていません。"
    },
    "sendEGcByText": {
      "confirmation": {
        "groupOrder": {
          "confirmationMessageEnd": "ギフトカードに追加します。確認のSMSが{phoneNumber}に送信されます"
        }
      },
      "errorMessage": {
        "blacklistedEmailSuffix": "{numEmails, plural, =1{入力されたメールアドレスは無効です} other{1件以上のメールアドレスが無効です}}。別のメールアドレスを入力してください。",
        "inTotal": {
          "perDay": "1日に4枚以上のギフトカードをSMSで送信することはできません。代わりにメールで送信してください。",
          "perMonth": "1か月に20枚以上のギフトカードをSMSで送信することはできません。代わりにメールで送信してください。"
        },
        "minAmount": "SMSで送信するeギフトカードは5ドル以上にしてください",
        "samePhoneNumber": {
          "perDay": "1日に2枚以上のギフトカードを同じ電話番号に送信することはできません。代わりにメールで送信してください。",
          "perMonth": "1か月に4枚以上のギフトカードを同じ電話番号に送信することはできません。代わりにメールで送信してください。"
        }
      },
      "giftInfoForm": {
        "contributorPhoneNumbers": "共同購入者の電話番号（最大{max}名、カンマで区切る）",
        "invalidPhoneNumberValidation": "無効な電話番号 {phoneNumber}",
        "maxPhoneNumbersValidation": "1回の注文で入力できる電話番号は最大{max}件です",
        "minPhoneNumbersValidation": "電話番号は最低{min}件入力してください",
        "phoneNumber": "電話番号",
        "phoneNumbers": "電話番号（カンマで区切る）",
        "phoneNumbersRequiredValidation": "電話番号は必須項目",
        "recipientPhoneNumber": "受取先の電話番号",
        "yourPhoneNumber": "お電話番号"
      },
      "giftMessage": {
        "messageFrom": "{senderPhoneNumber}からのSMS"
      },
      "giftSummarySidebar": {
        "phoneNumberUnavailable": "電話番号が利用できません"
      },
      "groupGiftContributors": {
        "disclaimerToContributor": "お名前、お電話番号、SMSは受取先と共有されます。"
      },
      "legalDisclaimer": "eギフトカードをお届けするために、上記に入力された携帯電話番号にSquareが1回限りのSMSを送信することに同意した上で、「お会計」を選択します。個人情報保護の取り組みについて詳しくは、直接{sellerName}までお問い合わせください。ショートメッセージおよびデータ料金が適用されます。",
      "modal": {
        "addEditPhoneNumber": {
          "header": "電話番号を追加",
          "subheader": "ギフトカードはご自宅に置いておき、レジ担当者に電話番号をお伝えください。"
        },
        "recipientPhoneNumbers": {
          "eGiftRecipients": "eギフトカードの受取先"
        }
      },
      "resend": {
        "buttonText": "eギフトカードを再送信",
        "heroSecondary": "ギフトカードをお届けできませんでした。受取先の連絡先情報を更新してください。",
        "heroText": "お届け情報を更新"
      },
      "singleRecipientInfoForm": {
        "tabItem": {
          "sendByPhoneNumber": "電話番号で送信"
        }
      },
      "todayAt": "今日の{time}",
      "todayInstantly": "今日（即時）",
      "updateRecipientForm": {
        "confirmRecipientPhoneNumber": "受取先の電話番号",
        "confirmationValidation": "電話番号が一致しません。",
        "originalContactInfo": "元の連絡先情報",
        "originalPhoneNumber": "元の電話番号",
        "phoneNumberUnavailable": "電話番号が利用できません",
        "recipientPhoneNumber": "受取先の電話番号"
      },
      "waysToRedeem": {
        "otherInstructionsOnlyPhoneNumber": "お会計時にお電話番号を入力してください。",
        "phone": "電話番号"
      }
    },
    "shareGroupGift": {
      "shareLink": "リンクを共有",
      "shareWithOthers": "リンクを他の人と共有して、グループギフトに参加できるようにします。"
    },
    "singleRecipientInfoForm": {
      "tabItem": {
        "sendByEmail": "メールで送信",
        "sendByText": "SMSで送信"
      }
    },
    "tabPanel": {
      "sendToMultiple": "複数の受取先に送信",
      "sendToOne": "ひとつの受取先に送信"
    },
    "updateRecipientForm": {
      "confirmRecipientEmailAddress": "受取先のメールアドレス",
      "confirmationValidation": "メールアドレスが一致しません。",
      "emailAddressUnavailable": "メールアドレスが無効です",
      "originalEmail": "元のメールアドレス",
      "recipientEmailAddress": "受取先のメールアドレス",
      "updateOrder": "注文内容を更新する"
    },
    "view": {
      "addMoreFunds": "残高にさらに金額を追加",
      "transactionHistory": "取引履歴",
      "waysToRedeem": "引き換え方法"
    },
    "viewPageHeader": {
      "currentBalance": "現在の残高",
      "greeting": "{recipientName}様、{senderName}様からeギフトカードが届きました！",
      "groupGreeting": "{recipientName}様への贈り物です！",
      "groupViewGreeting": "{recipientName}様、お知り合いの方からeギフトカードが届きました！"
    },
    "waysToRedeem": {
      "addToSquareProfile": "Squareのプロフィールに追加する",
      "barCode": "バーコード",
      "code": "コード",
      "codeInstructions": "このコードをレジ担当者にお見せください。",
      "email": "メール",
      "giftCardCode": "ギフトカードコード",
      "instructions": "コードをスキャンまたは入力して、ギフトカードを利用します。",
      "methodChoiceSeparator": "または",
      "otherHeader": "店内でのその他のご利用方法",
      "otherInstructions": "お会計時に、電話番号またはメールアドレスを入力してください。",
      "otherInstructionsOnlyEmail": "お会計時にメールアドレスをお伝えください。",
      "phone": "電話番号",
      "phoneActionText": "カードに登録した電話番号を変更",
      "qrCode": "QRコード",
      "redeemOnline": "オンラインで引き換える",
      "redeemOnlineInstructions": "お会計時にギフトカードコードを入力してください。",
      "squareProfileDescription": "Squareのプロフィールは、異なるSquareのビジネスで使用されているすべてのギフトカードを表示し、管理するのに役立ちます"
    }
  }]];
  _exports.default = _default;
});