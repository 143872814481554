define("ember-modifier/-private/functional/modifier", ["exports", "ember-modifier/-private/functional/modifier-manager"], function (_exports, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = modifier;
  /**
   * An API for writing simple modifiers.
   *
   * This function runs the first time when the element the modifier was applied
   * to is inserted into the DOM, and it *autotracks* while running. Any values
   * that it accesses will be tracked, including the arguments it receives, and if
   * any of them changes, the function will run again.
   *
   * The modifier can also optionally return a *destructor*. The destructor
   * function will be run just before the next update, and when the element is
   * being removed entirely. It should generally clean up the changes that the
   * modifier made in the first place.
   *
   * @param fn The function which defines the modifier.
   */
  function modifier(fn) {
    return Ember._setModifierManager(function () {
      return _modifierManager.default;
    }, fn);
  }
});